/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */

import React from 'react';
import File from './File/File';
import Autosuggest from '../../../Autosuggest';
import { DatePickerField, TextareaField } from '../../../FormFields';
import FormWrapper from '../../../FormWrapper';

const formInitialState = {
  field: 1,
  name: null,
  expireDate: null,
  tooltipVisible: false,
  error: false,
};

const fieldOptions = [
  { id: 1, label: 'Nurse' },
  { id: 2, label: 'Allied' },
  { id: 3, label: 'Physician' },
];

class DocumentsContents extends FormWrapper {
  state = formInitialState;

  onAddContent = () => {
    const {
      props: { onAddContent = () => {} },
      state: { name, expireDate },
    } = this;

    if (this.state.name !== null) {
      this.setState({ error: false });
      const splitName = name.split(' - ');
      const acronym = splitName.length === 2 ? splitName[0] : '';
      const contentName = splitName.length === 2 ? splitName[1] : name;
      onAddContent(contentName, expireDate, acronym);
    } else {
      this.setState({ error: true });
    }
  };

  onContentTitleSelect = ({ label }) => {
    this.setState({ name: label });
  };

  onNotesChange = ({ target: { value } }) => {
    const { onNotesChange = () => {} } = this.props;

    onNotesChange(value);
  };

  toggle = () => {
    this.setState({ tooltipVisible: !this.state.tooltipVisible });
  };

  onContentTitleChanged = (value) => {
    this.setState({ name: value });
  };

  render() {
    const {
      onContentTitleChanged,
      onAddContent,
      onNotesChange,
      onInputFieldChange,
      onContentTitleSelect,
      onDatePickerFieldChange,
      props: { content = [], notes, onDeleteContent = () => {}, source = [] },
      state: { tooltipVisible, error },
    } = this;

    return (
      <div>
        <div className="row z3">
          <div className="col-md-12">
            <div className="exp-wrapper">
              <div className="margin-bottom-medium">
                <span className="text-large mr-2">Add Contents</span>
                <img
                  className="ml-5"
                  onClick={() => this.toggle()}
                  src="/popout-icon.gif"
                  alt=""
                />
                <div
                  className={`fade popover bottom${
                    tooltipVisible ? ' in z4' : ' z-1'
                  }`}
                  id="contents-popover"
                >
                  <div className="arrow" />
                  <h3 className="popover-title">Add Contents</h3>
                  <div className="popover-content">
                    Stay organized by entering the contents of your document.
                    You can add multiple items if your document includes copies
                    of multiple items.
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="field">Field:</label>
                <select
                  className="form-control"
                  id="field"
                  name="field"
                  onChange={onInputFieldChange}
                >
                  {fieldOptions.map(({ id, label }) => (
                    <option key={`field-options-${id}`} value={id}>
                      {label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="row">
                <div className="col-xs-12 col-sm-6 col-md-6">
                  <Autosuggest
                    onSelect={onContentTitleSelect}
                    label="Content Title"
                    htmlFor="ContentTitle"
                    source={source.contents[this.state.field]}
                    onChange={onContentTitleChanged}
                    name="content-title"
                    shouldClean={false}
                  />
                  <span> {!error ? '' : 'This field is required'} </span>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-6">
                  <div className="form-group">
                    <label htmlFor="content">Expiration Date:</label>
                    <DatePickerField
                      name="expireDate"
                      onChange={onDatePickerFieldChange}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 text-center">
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={onAddContent}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h4 className="h4-skills">Current Contents</h4>
        <div className="row">
          {content.length > 0 ? (
            content.map((ct) => (
              <File
                key={ct.typeId}
                typeId={ct.typeId}
                name={ct.name}
                expireDate={ct.expireDate}
                onDelete={onDeleteContent}
              />
            ))
          ) : (
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12">
                <div className="row">
                  <h6 className="text-center">There is no content yet.</h6>
                </div>
              </div>
            </div>
          )}
        </div>
        <TextareaField
          id="notes"
          name="notes"
          label="Notes"
          value={notes}
          onChange={onNotesChange}
        />
      </div>
    );
  }
}

export default DocumentsContents;
