import React from 'react';
import Redactor from '../Redactor';

const RichEditorField = ({ id, label, name, content, onChange = () => {} }) => (
  <div className="form-group">
    {label && <label htmlFor={id}>{label}:</label>}
    <Redactor id={id} onChange={onChange} name={name} content={content} />
  </div>
);

export default RichEditorField;
