import React from 'react';
import { Helmet } from 'react-helmet';
import SimpleTable from '../../SimpleTable';
import Pagination from '../../Pagination/Pagination';

const Log = ({ logs, numberOfLogs, url, name, page }) => (
  <div className="panel panel-default">
    <Helmet>
      <title>{`${name} Log`}</title>
    </Helmet>
    <div className="panel-heading">
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12">
          <div className="text-large">Audit Log</div>
        </div>
      </div>
    </div>
    <div className="panel-body">
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12">
          <div className="table-responsive">
            <SimpleTable
              columns={['Note', 'User', 'Date Modified']}
              body={logs}
            />
          </div>
          <Pagination
            numberOfItems={numberOfLogs}
            currentPage={page}
            url={url}
          />
        </div>
      </div>
    </div>
  </div>
);

export default Log;
