import React from 'react';
import { connect } from 'react-redux';
import { history } from '../../../../store';
import EducationForm from '../../../../components/Candidate/Profile/Education/EducationForm';
import { getCandidateData } from '../../../../selectors';
import { update } from '../../../../actions/candidates';
import { LOG_MESSAGES } from '../../../../constants';

class AddEducation extends React.Component {
  onSubmit = (e) => {
    this.props.candidate.addEducation(e);
    this.props.update(LOG_MESSAGES.EDUCATION_INFORMATION_MODIFIED);
    history.push(`/${this.props.candidateUrl}/profile`);
  };

  render() {
    const {
      onSubmit,
      props: { candidateUrl },
    } = this;

    return (
      <EducationFormComponent
        key={candidateUrl}
        title="Add Education"
        candidateUrl={candidateUrl}
        onSubmit={onSubmit}
      />
    );
  }
}

class EducationFormComponent extends EducationForm {
  onSubmit = (e) => {
    const {
      props: { onSubmit = () => {} },
      state,
    } = this;

    e.preventDefault();
    onSubmit(state);
  };
}

const mapStateToProps = (state) => ({
  candidateUrl: state.globalInfo.candidateUrl,
  candidate: getCandidateData(state),
});

export default connect(mapStateToProps, { update })(AddEducation);
