/* eslint-disable react/sort-comp */

import React from 'react';
import $ from 'jquery';

class Redactor extends React.Component {
  initialize = () => {
    const { onChange = () => {}, name } = this.props;

    this.$el = $(this.el);
    this.$el.redactor({
      callbacks: {
        change() {
          onChange(name, this.code.get());
        },
      },
      focus: false,
      pasteImages: false,
      pastePlainText: true,
      pasteLinks: false,
      buttons: ['bold', 'italic', 'ul', 'ol', 'outdent', 'indent', 'format'],
      formatting: [],
      formattingAdd: {
        'align-left': {
          title: 'Align text to the left',
          args: ['p', 'class', 'align-left'],
        },
        'align-center': {
          title: 'Center text',
          args: ['p', 'class', 'align-center'],
        },
        'align-right': {
          title: 'Align text to the right',
          args: ['p', 'class', 'align-right'],
        },
        'align-justify': {
          title: 'Justify text',
          args: ['p', 'class', 'align-justify'],
        },
      },
    });
  };

  componentDidMount() {
    let checkJ = null;
    let waitTime = 0;

    const check = () => {
      waitTime += 200;
      if ($ && this.el) {
        this.initialize();
        clearInterval(checkJ);
      } else if (waitTime > 4000) {
        clearInterval(checkJ);
      }
    };
    checkJ = setInterval(() => check(), 200);
  }

  componentWillUnmount() {
    this.$el.redactor('core.destroy');
  }

  render() {
    const { content } = this.props;
    return (
      <div>
        <textarea
          name={this.props.name}
          defaultValue={content}
          ref={(el) => {
            this.el = el;
          }}
        />
      </div>
    );
  }
}

export default Redactor;
