import React from 'react';
import SkillLevelCheckbox from './SkillLevelCheckbox';

const SkillLevelRow = ({
  id,
  label,
  currentLevelId,
  levels = [],
  onLevelChange = () => {},
}) => (
  <tr>
    <td className="vcenter">{label}</td>
    {levels.map(({ id: levelId }) => (
      <td key={`skill-level-column-${levelId}`} className="vcenter text-center">
        <SkillLevelCheckbox
          id={id}
          onLevelChange={onLevelChange}
          currentLevelId={currentLevelId}
          levelId={levelId}
        />
      </td>
    ))}
  </tr>
);

export default SkillLevelRow;
