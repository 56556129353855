import moment from 'moment';
import { MOMENTS_DATES_FORMATS } from '../constants';

export class Background {
  constructor(bcg = {}) {
    this.signedDate = bcg.signedDate
      ? moment(bcg.signedDate, MOMENTS_DATES_FORMATS).format('MM/DD/YYYY')
      : moment().format('MM/DD/YYYY');
    this.signatureTimestamp = bcg.signatureTimestamp;
  }

  getDate() {
    return moment(this.signedDate, MOMENTS_DATES_FORMATS).format('MM/DD/YYYY');
  }
}

export class CoverLetter {
  constructor(cl = {}) {
    this.id = cl.id || moment().valueOf();
    this.title = cl.title;
    this.clientName = cl.clientName;
    this.clientAddres = cl.clientAddres;
    this.clientCityState = cl.clientCityState;
    this.clientZip = cl.clientZip;
    this.jobId = cl.jobId || '';
    this.jobTitle = cl.jobTitle;
    this.jobShift = cl.jobShift;
    this.jobUnit = cl.jobUnit;
    this.letter = cl.letter;
    this.dateModified = cl.dateModified
      ? moment(cl.dateModified, MOMENTS_DATES_FORMATS).format('MM/DD/YYYY')
      : moment().format('MM/DD/YYYY');
    this.lastEditedBy = cl.lastEditedBy;
  }

  getModifiedDate() {
    return moment(this.dateModified, MOMENTS_DATES_FORMATS).format(
      'MM/DD/YYYY',
    );
  }
}

class File {
  constructor(file = {}) {
    this.fileId = file.fileId;
    const s3keyTitle = file.s3key
      ? file.s3key.substring(file.s3key.lastIndexOf('/') + 1)
      : '';
    this.title = file.title || s3keyTitle;
    this.notes = file.notes;
    this.expiration = file.expiration;
    this.created = file.created;
    this.updated = file.updated;
    this.content = file.content;
    this.s3key = file.s3key || '';
  }

  update = (state) => {
    this.content = state.content;
    this.notes = state.notes;
    return this;
  };

  getExpirationDate() {
    return moment(this.expiration).format('MM/DD/YYYY');
  }

  getCreatedDate() {
    return moment(this.created).format('MM/DD/YYYY');
  }
}

export class ReferenceCheck {
  constructor(check = {}) {
    this.id = check.id || moment().valueOf();
    this.employeeName = check.employeeName || '';
    this.employeeTitle = check.employeeTitle || '';
    this.facilityEmployer = check.facilityEmployer || '';
    this.unitWorked = check.unitWorked || '';
    this.timePeriodCurrentEmployer = check.timePeriodCurrentEmployer || false;
    /* eslint-disable no-nested-ternary */
    this.timePeriodFrom = moment.isMoment(check.timePeriodFrom)
      ? check.timePeriodFrom.valueOf()
      : Number.isInteger(check.timePeriodFrom)
      ? check.timePeriodFrom
      : moment(check.timePeriodFrom, MOMENTS_DATES_FORMATS).valueOf();
    this.timePeriodTo = moment.isMoment(check.timePeriodTo)
      ? check.timePeriodTo.valueOf()
      : Number.isInteger(check.timePeriodTo)
      ? check.timePeriodTo
      : moment(check.timePeriodTo, MOMENTS_DATES_FORMATS).valueOf();
    this.qualityOfWork = parseInt(check.qualityOfWork, 10) || 0;
    this.quantityOfWork = parseInt(check.quantityOfWork, 10) || 0;
    this.interestEnthusiasm = parseInt(check.interestEnthusiasm, 10) || 0;
    this.relateToPatients = parseInt(check.relateToPatients, 10) || 0;
    this.relateToStaff = parseInt(check.relateToStaff, 10) || 0;
    this.adaptabilityToChange = parseInt(check.adaptabilityToChange, 10) || 0;
    this.abilityToHandleStress = parseInt(check.abilityToHandleStress, 10) || 0;
    this.attendance = parseInt(check.attendance, 10) || 0;
    this.punctuality = parseInt(check.punctuality, 10) || 0;
    this.chargeDuty = check.chargeDuty;
    this.resignedOrTerminated = check.resignedOrTerminated;
    this.eligibleForRehire = check.eligibleForRehire;
    this.reasonForLeaving = check.reasonForLeaving;
    this.additionalComments = check.additionalComments || '';
    this.referenceName = check.referenceName;
    this.referenceTitle = check.referenceTitle;
    this.referencePhone = check.referencePhone;
    this.referenceEmail = check.referenceEmail;
    this.referenceCheckedBy = check.referenceCheckedBy;
    this.dateReferenceChecked = moment.isMoment(check.dateReferenceChecked)
      ? check.dateReferenceChecked.valueOf()
      : Number.isInteger(check.dateReferenceChecked)
      ? check.dateReferenceChecked
      : moment(check.dateReferenceChecked, MOMENTS_DATES_FORMATS).valueOf();
    this.dateModified = check.dateModified
      ? moment(check.dateModified, MOMENTS_DATES_FORMATS)
      : moment().format('MM/DD/YYYY');
    /* eslint-enable no-nested-ternary */
    this.lastEditedBy = check.lastEditedBy;
    this.skills = [
      {
        id: 'qualityOfWork',
        label: 'Quality of work',
        levelId: this.qualityOfWork,
      },
      {
        id: 'quantityOfWork',
        label: 'Quantity of work',
        levelId: this.quantityOfWork,
      },
      {
        id: 'interestEnthusiasm',
        label: 'Interest & Enthusiasm',
        levelId: this.interestEnthusiasm,
      },
      {
        id: 'relateToPatients',
        label: 'Relate to patients',
        levelId: this.relateToPatients,
      },
      {
        id: 'relateToStaff',
        label: 'Relate to Staff',
        levelId: this.relateToStaff,
      },
      {
        id: 'adaptabilityToChange',
        label: 'Adaptability to change',
        levelId: this.adaptabilityToChange,
      },
      {
        id: 'abilityToHandleStress',
        label: 'Ability to handle stress',
        levelId: this.abilityToHandleStress,
      },
      { id: 'attendance', label: 'Attendance', levelId: this.attendance },
      { id: 'punctuality', label: 'Punctuality', levelId: this.punctuality },
    ];
  }

  getTitle() {
    return `${this.facilityEmployer} | ${this.getStartDate()} - ${
      this.timePeriodCurrentEmployer ? 'Present' : this.getEndDate()
    } |`;
  }

  getStartDate() {
    return moment(this.timePeriodFrom).format('MM/DD/YYYY');
  }

  getEndDate() {
    return moment(this.timePeriodTo).format('MM/DD/YYYY');
  }

  getDate() {
    return moment(this.dateReferenceChecked).format('MM/DD/YYYY');
  }

  getModifiedDate() {
    return moment(this.dateModified, MOMENTS_DATES_FORMATS).format(
      'MM/DD/YYYY',
    );
  }
}

export class Statements {
  constructor(state = {}) {
    this.signedDate = state.signedDate;
    this.signatureTimestamp = state.signatureTimestamp;
  }

  getDate() {
    return moment(this.signedDate, MOMENTS_DATES_FORMATS).format('MM/DD/YYYY');
  }
}

export default class Documents {
  constructor(data = {}) {
    this.background = data.background
      ? data.background.map((d) => new Background(d))
      : [];
    this.coverLetters = data.coverLetters
      ? data.coverLetters.map((d) => new CoverLetter(d))
      : [];
    this.files = data.files ? data.files.map((d) => new File(d)) : [];
    this.referenceChecks = data.referenceChecks
      ? data.referenceChecks.map((d) => new ReferenceCheck(d))
      : [];
    this.statements = data.statements
      ? data.statements.map((d) => new Statements(d))
      : [];
  }

  deleteStatement = (state) => {
    this.statements = this.statements.filter((b) => b !== state);
  };

  deleteBackground = (state) => {
    this.background = this.background.filter((b) => b !== state);
  };

  addLetter(state) {
    this.coverLetters.push(new CoverLetter(state));
  }

  editLetter(state) {
    this.coverLetters = this.coverLetters.map((letter) =>
      letter.id === state.id ? new CoverLetter(state) : letter,
    );

    return this;
  }

  deleteLetter(id) {
    this.coverLetters = this.coverLetters.filter((item) => item.id !== id);
  }

  addReferenceCheck(state) {
    this.referenceChecks.push(new ReferenceCheck(state));
  }

  editReference(state) {
    this.referenceChecks = this.referenceChecks.map((ref) =>
      ref.id === state.id ? new ReferenceCheck(state) : ref,
    );

    return this;
  }

  deleteReferenceCheck(id) {
    this.referenceChecks = this.referenceChecks.filter(
      (item) => item.id !== id,
    );
  }

  editFile = (state, id) => {
    this.files = this.files.map((f) => (f.fileId === id ? f.update(state) : f));
    return this;
  };

  deleteFile = (fileId) => {
    this.files = this.files.filter((item) => item.fileId !== fileId);
  };
}
