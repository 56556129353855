import React from 'react';

const Loading = () => (
  <div className="spinner__wrapper">
    <div className="spinner">
      <div className="double-bounce1" />
      <div className="double-bounce2" />
    </div>
  </div>
);

export default Loading;
