/* eslint-disable react/no-unused-state */
import React from 'react';
import PropTypes from 'prop-types';

const normalizeBoolean = (value) => {
  if (value === 'true') {
    return true;
  }

  if (value === 'false') {
    return false;
  }

  return value;
};

class FormWrapper extends React.Component {
  onRichEditorChange = (name, content) => {
    this.setState({ [name]: content });
  };

  onDatePickerFieldChange = (name, timestamp) => {
    this.setState({ [name]: timestamp });
  };

  onInputFieldChange = ({ target: { name, type }, target }) => {
    const value = type === 'checkbox' ? target.checked : target.value;

    if (name.includes('.')) {
      const nameArray = name.split('.');
      const newObject = { ...this.state[nameArray[0]] };
      newObject[nameArray[1]] = normalizeBoolean(value);
      this.setState({ [nameArray[0]]: newObject });
    } else {
      this.setState({ [name]: normalizeBoolean(value) });
    }
  };

  handleCityChange = (e) => {
    const props = e.value.split(',');
    const city = props[0] || '';
    let region = '';
    if (props[1].includes(' ')) {
      const tmpData = props[1].trim().split(' ');
      region = tmpData[0] || '';
    } else {
      region = props[1] || '';
    }

    const newObject = { ...this.state.address };
    newObject.city = city;
    newObject.region = region;

    this.setState({ address: newObject });
  };
}

FormWrapper.contextTypes = {
  store: PropTypes.object,
};

export default FormWrapper;
