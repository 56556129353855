import React from 'react';

const SectionTitle = (props) => (
  <div className="row">
    <div className="col-xs-12 col-sm-12 col-md-12">
      <div className="mg-top-lg mg-btm-mid text-large">{props.title}</div>
    </div>
  </div>
);

export default SectionTitle;
