import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import IconTitle from '../../../components/Candidate/Profile/IconTitle';
import EditRow from '../../../components/Candidate/Profile/EditRow';
import Details from '../../../components/Candidate/Profile/Details';
import Skill from '../../../components/Candidate/Profile/Skill';
import AddEditButton from '../../../components/Candidate/Profile/AddEditButton';
import AddEditDropdown from '../../../components/Candidate/Profile/AddEditDropdown';
import Location from '../../../components/Candidate/Profile/Location';
import Section from '../../../components/Candidate/Profile/Section';
import fetchCandidates from '../../../actions/candidates';
import { getCandidateData } from '../../../selectors';
import { unitIdMap, MOMENTS_DATES_FORMATS } from '../../../constants';

const getLinks = (
  supervisor,
  id,
  currentUrl,
  isAgency,
  isSubposition,
  subPositionId,
  numberOfRef,
) => {
  const links = [
    !isSubposition
      ? { label: 'edit position', url: `${currentUrl}/edit-position/${id}` }
      : null,
    isAgency
      ? {
          label: `${isSubposition ? 'edit' : 'add'} assignment`,
          url: `${currentUrl}/${
            isSubposition ? 'edit' : 'add'
          }-position/${id}/assignment/${subPositionId}`,
        }
      : null,
    _.isEmpty(supervisor)
      ? {
          label: 'add supervisor',
          url: `${currentUrl}/edit-position/${id}${
            isSubposition ? `/assignment/${subPositionId}` : ''
          }/add`,
        }
      : null,
    {
      label: `${
        numberOfRef ? `references (${numberOfRef})` : 'add references'
      }`,
      url: `${currentUrl}/edit-position/${id}${
        isSubposition ? `/assignment/${subPositionId}` : ''
      }/add`,
    },
  ];

  return links.filter((n) => n);
};

export const ProfileContainer = ({
  match: { url: currentUrl },
  candidate,
  access,
}) => (
  <div className="contact-container">
    <Helmet>
      <title>{`${candidate.getName()} Career Profile`}</title>
    </Helmet>
    <Section
      iconTitle={<IconTitle icon="folder-close" title="Summary" />}
      editButton={
        <AddEditButton
          label="edit"
          url={`${currentUrl}/edit-summary`}
          access={access}
        />
      }
    >
      <div id="summary">
        <p dangerouslySetInnerHTML={{ __html: candidate.getSummary() }} />
      </div>
    </Section>
    <Section
      iconTitle={<IconTitle icon="zoom-in" title="Specialties" />}
      editButton={
        <AddEditButton
          label="Add specialty"
          url={`${currentUrl}/add-specialty`}
          access={access}
        />
      }
    >
      {candidate.specialties.map((specialty) => (
        <EditRow
          key={`specialties-${specialty.getId()}`}
          editButton={
            <AddEditButton
              label="edit"
              url={`${currentUrl}/edit-specialty/${specialty.getId()}`}
              access={access}
            />
          }
        >
          <Skill {...specialty} />
        </EditRow>
      ))}
    </Section>
    <Section
      iconTitle={
        <IconTitle icon="certificate" title="Licenses & Certifications" />
      }
      editButton={
        <AddEditButton
          label="Add Lic. or Cert"
          url={`${currentUrl}/add-License-or-certification`}
          access={access}
        />
      }
    >
      {candidate.getLicenseCerts().map((cert) => (
        <EditRow
          key={`licensesAndCertifications-${cert.getId()}`}
          editButton={
            <AddEditButton
              label="edit"
              url={`${currentUrl}/edit-license-or-certification/${cert.getId()}`}
              access={access}
            />
          }
        >
          <h3 className="h3-details">
            {cert.licenseCert.acronym ? (
              <span>{cert.licenseCert.acronym} - </span>
            ) : (
              ''
            )}
            {cert.getName()}
            {cert.getRegion() ? <span>, {cert.getRegion()}</span> : ''}
          </h3>
          <div className="lic-info">Body: {cert.getBodyShortName()}</div>
          <div className="lic-info">expires: {cert.getExpiration()}</div>
        </EditRow>
      ))}
    </Section>
    <Section
      iconTitle={<IconTitle icon="list-alt" title="Experience" />}
      editButton={
        <AddEditButton
          label="Add position"
          url={`${currentUrl}/add-position`}
          access={access}
        />
      }
    >
      {candidate
        .getPositions()
        .sort((a, b) => {
          if (a.isCurrent && b.isCurrent) {
            return moment(a.startDate, MOMENTS_DATES_FORMATS).isBefore(
              moment(b.startDate, MOMENTS_DATES_FORMATS),
            );
          }
          if (a.isCurrent && !b.isCurrent) {
            return false;
          }
          if (!a.isCurrent && b.isCurrent) {
            return true;
          }
          return moment(a.endDate, MOMENTS_DATES_FORMATS).isBefore(
            moment(b.endDate, MOMENTS_DATES_FORMATS),
          );
        })
        .map((job, i) => (
          <div key={`experience-${job.positionId}`}>
            <EditRow
              editButton={
                <AddEditDropdown
                  label="edit"
                  links={getLinks(
                    job.supervisor,
                    job.positionId,
                    currentUrl,
                    parseInt(job.employmentType, 10) === 1 ||
                      job.employmentType === 'agency',
                    false,
                    '',
                    job.references.length || '',
                  )}
                  access={access}
                />
              }
              wrapButtons="true"
              extraSpaceBelow={candidate.getPositions().length - 1 !== i}
            >
              <Details
                {...job}
                name={job.title}
                startDate={job.getStartDate()}
                endDate={job.getEndDate()}
                isCurrent={job.isCurrent}
                unit={job.unit}
                subtitles={[
                  <Location {...job.address} label={job.employer} />,
                  unitIdMap[job.facilityUnitId],
                ]}
              />
            </EditRow>
            {job.subpositions &&
              job.subpositions.map((subposition) => (
                <div
                  key={`assignments-${job.positionId}-${subposition.positionId}`}
                  className="agency-position"
                >
                  <EditRow
                    editButton={
                      <AddEditDropdown
                        label="edit"
                        links={getLinks(
                          job.supervisor,
                          job.positionId,
                          currentUrl,
                          true,
                          true,
                          subposition.positionId,
                          subposition.references.length || '',
                        )}
                        access={access}
                      />
                    }
                    wrapButtons="true"
                  >
                    <Details
                      name={subposition.title}
                      startDate={subposition.startDate}
                      endDate={subposition.endDate}
                      description={subposition.description}
                      unit={subposition.unit}
                      isCurrent={subposition.isCurrent}
                      subtitles={[
                        <Location
                          {...subposition.address}
                          label={subposition.employer}
                        />,
                        unitIdMap[subposition.facilityUnitId],
                      ]}
                    />
                  </EditRow>
                </div>
              ))}
          </div>
        ))}
    </Section>
    <Section
      iconTitle={<IconTitle icon="book" title="Education" />}
      editButton={
        <AddEditButton
          label="Add school"
          url={`${currentUrl}/add-education`}
          access={access}
        />
      }
    >
      {candidate.getEducations().map((educationDetails) => (
        <EditRow
          key={`education-${educationDetails.schoolId}`}
          editButton={
            <AddEditButton
              label="edit"
              url={`${currentUrl}/edit-education/${educationDetails.schoolId}`}
              access={access}
            />
          }
        >
          <Details
            name={
              <Location
                {...educationDetails.address}
                label={educationDetails.name}
              />
            }
            description={educationDetails.description}
            startDate={educationDetails.startYear}
            endDate={educationDetails.endYear}
            subtitles={[
              (educationDetails.degree ? `${educationDetails.degree} ` : '') +
                educationDetails.major,
            ]}
            isEdu
          />
        </EditRow>
      ))}
    </Section>
    <Section
      iconTitle={<IconTitle icon="hdd" title="Computer Skills" />}
      editButton={
        <AddEditButton
          label="Add skill"
          url={`${currentUrl}/add-computer-skill`}
          access={access}
        />
      }
    >
      {candidate.getComputerSkills().map((skill) => (
        <EditRow
          key={`skill-${skill.computerSkillId}`}
          editButton={
            <AddEditButton
              label="edit"
              url={`${currentUrl}/edit-computer-skill/${skill.computerSkillId}`}
              access={access}
            />
          }
        >
          <Skill {...skill} />
        </EditRow>
      ))}
    </Section>
  </div>
);

const mapStateToProps = (state) => ({
  candidate: getCandidateData(state),
  access: state.globalInfo.access,
});

export default connect(mapStateToProps, { fetchCandidates })(ProfileContainer);
