import React from 'react';
import Modal from '../../../Modal';
import UploadModalContent from './UploadModalContent';
import ModalProvider from './ModalProvider';
import { ACCESS_LVL } from '../../../../constants';

class DocumentEditModal extends Modal.Manager {
  render() {
    const {
      handleClose,
      handleShow,
      state: { showModal, visible },
      props: { id, content = [], name, notes, onEdit, update, access },
    } = this;

    return (
      <span>
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleShow}
          disabled={access === ACCESS_LVL.EXPIRED}
        >
          Edit
        </button>
        {showModal && (
          <ModalProvider>
            <ModalComponent
              visible={visible}
              handleClose={handleClose}
              id={id}
              content={content}
              name={name}
              notes={notes}
              update={update}
              onEdit={onEdit}
              edit
            />
          </ModalProvider>
        )}
      </span>
    );
  }
}

class ModalComponent extends UploadModalContent {
  constructor(props) {
    super(props);
    const { content, notes } = props;

    this.state = {
      content,
      notes,
    };
  }

  getFileComponent = () => {
    const { name } = this.props;

    return (
      <div className="form-group">
        <label htmlFor="title">File Name:</label>
        <div className="text-muted">{name}</div>
      </div>
    );
  };
}

export default DocumentEditModal;
