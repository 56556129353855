/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import { DOMAIN } from '../../constants';

const Header = ({ name, companyId }) => (
  <span>
    <a href={`${DOMAIN}company/p/${companyId}`}>{name}</a>&nbsp;
    <i className="ti-angle-right" />
    <a href="/">Candidates</a>
  </span>
);

export default Header;
