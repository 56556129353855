import _ from 'lodash';
import {
  FETCH_LOGS,
  FETCH_LOGS_ERROR,
  POST_LOG_SUCCESS,
  FETCH_LOGS_SUCCESS,
} from '../actions';

const initialState = {
  isFetching: false,
};

const logs = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LOGS:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_LOGS_SUCCESS: {
      const logsChunk = _.chunk(_.reverse(action.data), 10);
      return {
        ...state,
        isFetching: false,
        [action.url]: logsChunk,
      };
    }
    case POST_LOG_SUCCESS: {
      return {
        ...initialState,
      };
    }
    case FETCH_LOGS_ERROR:
      return {
        ...state,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default logs;
