/* eslint-env browser */

import React from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-datepicker/dist/react-datepicker.css';
import App from './App';
import * as serviceWorker from './registerServiceWorker';

window.jQuery = $;
require('bootstrap');

ReactDOM.render(<App />, document.getElementById('root'));
serviceWorker.unregister();
