import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import EditSummary from '../../../containers/Candidate/Profile/Summary';
import Profile from '../../../containers/Candidate/Profile';
import {
  AddComputerSkill,
  EditComputerSkill,
} from '../../../containers/Candidate/Profile/ComputerSkills';
import {
  AddSpecialty,
  EditSpecialty,
} from '../../../containers/Candidate/Profile/Specialties';

import {
  AddLicenseOrCertification,
  EditLicenseOrCertification,
} from '../../../containers/Candidate/Profile/LicenseOrCertification';
import {
  AddEducation,
  EditEducation,
} from '../../../containers/Candidate/Profile/Education';

import AddExperience from '../../../containers/Candidate/Profile/Experience/AddExperience';
import EditExperience from '../../../containers/Candidate/Profile/Experience/EditExperience';

const ProfileRoutes = (props) => (
  <Switch>
    <Route exact path={`${props.match.url}/`} component={Profile} />

    <Route
      exact
      path={`${props.match.url}/edit-summary`}
      component={EditSummary}
    />

    <Route path={`${props.match.url}/add-specialty`} component={AddSpecialty} />

    <Route
      path={`${props.match.url}/edit-specialty/:id`}
      component={EditSpecialty}
    />

    <Route
      path={`${props.match.url}/add-computer-skill`}
      component={AddComputerSkill}
    />

    <Route
      path={`${props.match.url}/edit-computer-skill/:id`}
      component={EditComputerSkill}
    />

    <Route
      path={`${props.match.url}/add-license-or-certification`}
      component={AddLicenseOrCertification}
    />

    <Route
      path={`${props.match.url}/edit-license-or-certification/:id`}
      component={EditLicenseOrCertification}
    />

    <Route
      exact
      path={`${props.match.url}/add-position`}
      component={AddExperience}
    />

    <Route
      exact
      path={`${props.match.url}/edit-position/:positionId`}
      component={EditExperience}
    />

    <Route
      exact
      path={`${props.match.url}/edit-position/:positionId/2`}
      render={({ match }) => <EditExperience page={2} match={match} />}
    />

    <Route
      exact
      path={`${props.match.url}/edit-position/:positionId/3`}
      render={({ match }) => <EditExperience page={3} match={match} />}
    />

    <Route
      exact
      path={`${props.match.url}/edit-position/:positionId/add`}
      render={({ match }) => <EditExperience page={3} match={match} />}
    />

    <Route
      exact
      path={`${props.match.url}/add-position/:positionId/assignment`}
      render={({ match }) => (
        <AddExperience page={1} match={match} isSubPosition />
      )}
    />

    <Route
      exact
      path={`${props.match.url}/edit-position/:positionId/assignment/:assignmentId`}
      render={({ match }) => (
        <EditExperience page={1} match={match} isSubPosition />
      )}
    />

    <Route
      exact
      path={`${props.match.url}/edit-position/:positionId/assignment/:assignmentId/2`}
      render={({ match }) => (
        <EditExperience page={2} match={match} isSubPosition />
      )}
    />

    <Route
      exact
      path={`${props.match.url}/edit-position/:positionId/assignment/:assignmentId/3`}
      render={({ match }) => (
        <EditExperience page={3} match={match} isSubPosition />
      )}
    />

    <Route
      exact
      path={`${props.match.url}/edit-position/:positionId/assignment/:assignmentId/add`}
      render={({ match }) => (
        <EditExperience page={3} match={match} isSubPosition />
      )}
    />

    <Route
      exact
      path={`${props.match.url}/add-education`}
      component={AddEducation}
    />

    <Route
      exact
      path={`${props.match.url}/edit-education/:schoolId`}
      component={EditEducation}
    />
    <Redirect strict from={`${props.match.url}/`} to={props.match.url} />
  </Switch>
);

export default ProfileRoutes;
