import React from 'react';
import Header from './ModalHeader';
import Footer from './ModalFooter';
import Body from './ModalBody';
import Manager from './ModalManager';
import Portal from './Portal';

const toggleBodyClass = (state) => {
  document.body.classList.toggle('modal-open', state);
};

const setScrollbarWidth = (padding) => {
  document.body.style.paddingRight = padding > 0 ? `${padding}px` : null;
};

const getScrollbarWidth = () => {
  const scrollDiv = document.createElement('div');
  // .modal-scrollbar-measure styles // https://github.com/twbs/bootstrap/blob/v4.0.0-alpha.4/scss/_modal.scss#L106-L113
  scrollDiv.style.position = 'absolute';
  scrollDiv.style.top = '-9999px';
  scrollDiv.style.width = '50px';
  scrollDiv.style.height = '50px';
  scrollDiv.style.overflow = 'scroll';
  document.body.appendChild(scrollDiv);
  const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
  document.body.removeChild(scrollDiv);
  return scrollbarWidth;
};

const getOriginalBodyPadding = () => {
  const style = window.getComputedStyle(document.body, null);

  return parseInt((style && style.getPropertyValue('padding-right')) || 0, 10);
};

class Modal extends React.Component {
  static defaultProps = {
    hideComponent: () => {},
    wide: false,
  };

  componentDidMount() {
    this.originalBodyPadding = getOriginalBodyPadding();

    toggleBodyClass(true);
    setScrollbarWidth(getScrollbarWidth());
    window.addEventListener('keydown', this.listenKeyboard, true);
  }

  componentWillUnmount() {
    toggleBodyClass(false);
    setScrollbarWidth(this.originalBodyPadding);
    window.removeEventListener('keydown', this.listenKeyboard, true);
  }

  onBackdropClick = (e) => {
    if (e.target.getAttribute('role') === 'dialog') {
      this.props.hideComponent();
    }
  };

  listenKeyboard = (event) => {
    if (event.key === 'Escape' || event.keyCode === 27) {
      this.props.hideComponent();
    }
  };

  render() {
    const {
      onBackdropClick,
      props: { children, visible, wide },
    } = this;
    const visibleClass = visible ? 'in' : '';
    const sizeClass = wide ? ' modal-lg' : '';

    return (
      <Portal>
        <div
          className={`modal fade show ${visibleClass}`}
          tabIndex="-1"
          role="dialog"
          onClickCapture={onBackdropClick}
        >
          <div className={`modal-dialog${sizeClass}`}>
            <div className="modal-content">{children}</div>
          </div>
        </div>
        <div className={`modal-backdrop fade ${visibleClass}`} />
      </Portal>
    );
  }
}

Modal.Body = Body;
Modal.Header = Header;
Modal.Footer = Footer;
Modal.Manager = Manager;

export default Modal;
