/* eslint-disable consistent-return */

import _ from 'lodash';
import axios from 'axios';
import { CONTENTS_LOOKUP_URL } from '../constants';
import {
  FETCH_CONTENTS_OPTIONS,
  FETCH_CONTENTS_OPTIONS_SUCCESS,
  FETCH_CONTENTS_OPTIONS_ERROR,
} from '.';
import { getContents } from '../selectors';

const fetchContentsOptions = () => ({
  type: FETCH_CONTENTS_OPTIONS,
});

const fetchContentsOptionsSuccess = (occupationId, data) => ({
  type: FETCH_CONTENTS_OPTIONS_SUCCESS,
  occupationId,
  data,
});

const fetchContentsOptionsError = () => ({
  type: FETCH_CONTENTS_OPTIONS_ERROR,
});

export default (occupationId) => async (dispatch, getState) => {
  const state = getState();
  const contents = getContents(state);

  if (_.size(contents.contents[occupationId])) {
    return Promise.resolve();
  }

  dispatch(fetchContentsOptions());
  try {
    const response = await axios.get(`${CONTENTS_LOOKUP_URL}${occupationId}`, {
      withCredentials: true,
    });

    dispatch(fetchContentsOptionsSuccess(occupationId, response.data));
  } catch (e) {
    console.error(e);
    dispatch(fetchContentsOptionsError());
  }
};
