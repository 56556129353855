import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Education } from '../../../../../models/Candidate';
import { TextField } from '../../../../FormFields';
import FormWrapper from '../../../../FormWrapper';
import LocationInformation from '../../../../FormFields/LocationInformation';
import RichEditorField from '../../../../FormFields/RichEditorField';

const yearsOptions = () => {
  const currentYear = moment().year();
  const years = [];
  for (let i = 1901; i <= currentYear; i += 1) {
    years.unshift(i);
  }
  return years;
};

class EducationForm extends FormWrapper {
  constructor() {
    super();
    this.state = new Education();
    this.setState({ cityError: false });
    window.scrollTo(0, 0);
  }

  validateCity = () => {
    this.setState({ cityError: false });
  };

  cityChange = (value) => {
    if (value !== '') {
      this.setState({ cityError: true });
    } else {
      this.setState({ cityError: false });
    }
  };

  render() {
    const {
      onInputFieldChange,
      onRichEditorChange,
      handleCityChange,
      onSubmit = () => {},
      onRemove = () => {},
      validateCity,
      cityChange,
      props: { title, edit = false, candidateUrl },
      state: {
        name,
        startYear,
        endYear,
        major,
        phone,
        phone2,
        degree,
        description,
        address,
        cityError,
      },
    } = this;

    return (
      <div className="panel panel-default">
        <div className="panel-heading">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12">
              <div className="text-large">{title}</div>
            </div>
          </div>
        </div>
        <div className="panel-body">
          <form onSubmit={onSubmit}>
            <div className="row">
              <div className="col-xs-12 col-sm-8 col-md-8">
                <TextField
                  label="School"
                  name="name"
                  id="name"
                  value={name}
                  required
                  onChange={onInputFieldChange}
                />
              </div>
            </div>
            <LocationInformation
              onChange={onInputFieldChange}
              handleCityChange={handleCityChange}
              addressLine1={address.addressLine1}
              addressLine2={address.addressLine2}
              region={address.region}
              city={address.city}
              postalCode={address.postalCode}
              country={address.contry}
              validateCity={validateCity}
              onCityChange={(value) => cityChange(value)}
              hasError={cityError}
            />
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-8">
                <TextField
                  label="Main Phone"
                  name="phone"
                  id="phone"
                  value={phone}
                  onChange={onInputFieldChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-8">
                <TextField
                  label="Alt Phone"
                  name="phone2"
                  id="phone2"
                  value={phone2}
                  onChange={onInputFieldChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-8">
                <TextField
                  label="Degree"
                  name="degree"
                  id="degree"
                  value={degree}
                  onChange={onInputFieldChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-8">
                <TextField
                  label="Major"
                  name="major"
                  id="major"
                  value={major}
                  onChange={onInputFieldChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-8 col-md-8">
                <div className="form-group">
                  <label htmlFor="years-experience">Years Attended:</label>
                  <div className="row">
                    <div className="col-xs-12 col-sm-4 col-md-4">
                      <select
                        className="form-control mt-3"
                        id="startYear"
                        required
                        name="startYear"
                        value={startYear}
                        onChange={onInputFieldChange}
                      >
                        <option value="">-</option>
                        {yearsOptions().map((year) => (
                          <option key={`start-option-${year}`} value={year}>
                            {year}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-xs-12 col-sm-1 col-md-1">
                      <p className="mt-5">To</p>
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4">
                      <select
                        className="form-control mt-3"
                        id="endYear"
                        required
                        name="endYear"
                        value={endYear}
                        onChange={onInputFieldChange}
                      >
                        <option value="">-</option>
                        {yearsOptions().map((year) => (
                          <option key={`end-option-${year}`} value={year}>
                            {year}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-8">
                <RichEditorField
                  id="description"
                  name="description"
                  onChange={onRichEditorChange}
                  content={description}
                />
              </div>
            </div>
            <div className="add-edit-btn">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 text-center">
                  <div className="form-group mt-20">
                    <button type="submit" className="btn btn-primary mt-5 mb-3">
                      Save
                    </button>
                    <Link
                      to={`/${candidateUrl}/profile`}
                      className="btn btn-warning mt-5 mb-3"
                    >
                      Cancel
                    </Link>
                    {edit && (
                      <button
                        className="btn btn-danger mt-5 mb-3"
                        onClick={onRemove}
                      >
                        Remove
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default EducationForm;
