import {
  FETCH_CANDIDATES,
  FETCH_CANDIDATES_ERROR,
  FETCH_CANDIDATES_SUCCESS,
  PUT_CANDIDATES_SUCCESS,
} from '../actions';
import Candidate from '../models/Candidate';

const initialState = {
  isFetching: false,
};

const candidates = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CANDIDATES:
      return {
        ...state,
        isFetching: true,
      };
    case PUT_CANDIDATES_SUCCESS:
      return {
        ...state,
        [action.data.url]: new Candidate(action.data),
      };
    case FETCH_CANDIDATES_SUCCESS: {
      const { url } = action.data;
      return {
        ...state,
        isFetching: false,
        [url]: new Candidate(action.data),
      };
    }
    case FETCH_CANDIDATES_ERROR: {
      const { url, statusCode } = action;
      return {
        ...state,
        isFetching: false,
        [url]: statusCode,
      };
    }
    default:
      return state;
  }
};

export default candidates;
