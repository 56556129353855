import React, { Component } from 'react';
import { connect } from 'react-redux';
import { history } from '../../../store';
import { cancelSearch } from '../../../actions/navigationHelpers';

class SearchArea extends Component {
  onClick = () => {
    this.props.cancelSearch();
    history.push('/');
  };

  render() {
    return (
      <div className="contact-container">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12">
            <span className="text-large">Results for: {this.props.query}</span>
            <button
              type="button"
              className="close"
              aria-hidden="true"
              onClick={() => this.onClick()}
            >
              X
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, { cancelSearch })(SearchArea);
