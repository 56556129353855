import React from 'react';
import Modal from '../../../Modal';

const capitalizeFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

class DeleteModal extends Modal.Manager {
  render() {
    const {
      handleClose,
      handleShow,
      state: { showModal, visible },
      props: { id, docType, onDelete, s, update },
    } = this;

    return (
      <span>
        <a href="/" className="dropdown-menu-link" onClick={handleShow}>
          Delete
        </a>
        {showModal && (
          <ModalComponent
            id={id}
            visible={visible}
            handleClose={handleClose}
            docType={docType}
            onDelete={onDelete}
            s={s}
            update={update}
          />
        )}
      </span>
    );
  }
}

class ModalComponent extends Modal.Manager {
  onDelete = () => {
    const { onDelete = () => {}, handleClose, s, update } = this.props;

    onDelete(s);
    if (update) {
      update();
    }
    handleClose();
  };

  render() {
    const {
      onDelete,
      props: { visible, handleClose, docType = 'document' },
    } = this;

    return (
      <Modal visible={visible} hideComponent={handleClose}>
        <Modal.Header
          title={`Delete Selected ${capitalizeFirstLetter(docType)}`}
          hideComponent={handleClose}
        />
        <Modal.Body>
          <p>
            Are you sure you want to delete the selected {docType}? This action
            cannot be reversed.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-success"
            onClick={() => onDelete()}
          >
            Delete
          </button>
          <button
            type="button"
            className="btn btn-default"
            onClick={handleClose}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default DeleteModal;
