import 'jsondiffpatch/dist/formatters-styles/html.css';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { putUpdateForCandidate } from '../../../../actions/updates';
import { ACCESS_LVL } from '../../../../constants';

const clearCommas = (text) => {
  if (!text || !_.isString(text)) {
    return text;
  }
  return text
    .replace(/(,\s){2,}/g, ', ')
    .replace(/^[,\s]+/g, '')
    .replace(/[,\s]+$/g, '');
};

const renderBlock = (data) => {
  if (_.isArray(data)) {
    return (
      <ul>
        {data.map((element) => (
          <li>{renderBlock(element)}</li>
        ))}
      </ul>
    );
  }
  if (_.isObject(data)) {
    return (
      <div>
        {Object.keys(data).map((key) => (
          <div key={key}>
            <label htmlFor="oldParagraph">{key}:</label>
            {renderBlock(data[key])}
          </div>
        ))}
      </div>
    );
  }
  return (
    <p
      dangerouslySetInnerHTML={{
        __html: clearCommas(data),
      }}
    />
  );
};

class UpdateBlock extends Component {
  onUpdate = (timestamp) => {
    this.props.putUpdateForCandidate(timestamp, 'update');
  };

  onCancel = (timestamp) => {
    this.props.putUpdateForCandidate(timestamp, 'cancel');
  };

  render() {
    const {
      onUpdate,
      onCancel,
      props: { timestamp, actionType, title, current, update, access },
    } = this;

    // in case of availability and position for actionType UPDATE
    if (
      (title === 'availability' || title === 'position') &&
      actionType === 'UPDATE'
    ) {
      return (
        <div>
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12">
              <div className="sign-up-h2">
                {actionType}: {title}
              </div>
            </div>
          </div>
          <div className="row" key={`${title}-${timestamp}-updated`}>
            <div className="col-xs-12 col-sm-12 col-md-12">
              <div className="version-container-update">
                <div dangerouslySetInnerHTML={{ __html: update.htmlDiff }} />
              </div>
            </div>
          </div>
          <div>
            <button
              type="button"
              className="btn btn-success"
              onClick={() => onUpdate(timestamp)}
              disabled={access === ACCESS_LVL.EXPIRED}
            >
              <span className="glyphicon glyphicon-ok" /> Accept
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => onCancel(timestamp)}
              disabled={access === ACCESS_LVL.EXPIRED}
            >
              <span className="glyphicon glyphicon-remove" /> Reject
            </button>
          </div>
        </div>
      );
    }

    return (
      <div>
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12">
            <div className="sign-up-h2">
              {actionType}: {title}
            </div>
          </div>
        </div>
        {current && (
          <div className="row" key={`${title}-${timestamp}-current`}>
            <div className="col-xs-12 col-sm-12 col-md-12">
              <div className="version-container-current">
                <p className="details-ico">Current:</p>
                {renderBlock(current)}
              </div>
            </div>
          </div>
        )}
        {update && (
          <div className="row" key={`${title}-${timestamp}-updated`}>
            <div className="col-xs-12 col-sm-12 col-md-12">
              <div className="version-container-update">
                <p className="details-ico">Update:</p>
                {renderBlock(update)}
                <div>
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={() => onUpdate(timestamp)}
                    disabled={access === ACCESS_LVL.EXPIRED}
                  >
                    <span className="glyphicon glyphicon-ok" /> Accept
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => onCancel(timestamp)}
                    disabled={access === ACCESS_LVL.EXPIRED}
                  >
                    <span className="glyphicon glyphicon-remove" /> Reject
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default connect(null, {
  putUpdateForCandidate,
})(UpdateBlock);
