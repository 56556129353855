import React from 'react';
import { connect } from 'react-redux';
import { history } from '../../../../store';
import ExperienceForm from '../../../../components/Candidate/Profile/Experience/ExperienceForm';
import { getCandidateData } from '../../../../selectors';
import { update } from '../../../../actions/candidates';
import NotFound from '../../../../components/NotFound/NotFound';
import Loading from '../../../../components/Loading';
import { LOG_MESSAGES } from '../../../../constants';

class EditExperience extends React.Component {
  onSubmit = (e, addSub = false) => {
    const { candidate, update, isSubPosition, candidateUrl, match } =
      this.props;

    if (isSubPosition) {
      candidate.editSubposition(e, match.params);
    } else {
      candidate.setPosition(e);
    }
    update(LOG_MESSAGES.EXPERIENCE_INFORMATION_MODIFIED);
    if (addSub) {
      history.push(
        `/candidates/profile/add-position/${e.positionId}/assignment`,
      );
    } else {
      history.push(`/${candidateUrl}/profile`);
    }
  };

  onPartialSubmit = (e) => {
    const { candidate, update, isSubPosition, match } = this.props;

    if (isSubPosition) {
      candidate.editSubposition(e, match.params);
    } else {
      candidate.setPosition(e);
    }
    update(LOG_MESSAGES.EXPERIENCE_INFORMATION_MODIFIED);
  };

  onRemove = (e) => {
    if (this.props.isSubPosition) {
      this.props.candidate.removeSubPosition(this.props.match.params);
    } else {
      this.props.candidate.removePosition(e);
    }
    this.props.update(LOG_MESSAGES.EXPERIENCE_INFORMATION_MODIFIED);
    history.push(`/${this.props.candidateUrl}/profile`);
  };

  render() {
    const {
      onSubmit,
      onPartialSubmit,
      onRemove,
      props: {
        page = 1,
        isSubPosition = false,
        candidate,
        candidateUrl,
        isFetching,
        match: {
          params: { positionId, id, assignmentId },
        },
      },
    } = this;

    if (isFetching) {
      return <Loading />;
    }
    if (candidate === undefined) {
      return <NotFound />;
    }

    let position = candidate.positions.find(
      (pos) => pos.positionId === parseInt(positionId, 10),
    );

    if (isSubPosition) {
      position = position.subpositions.find(
        (sub) => sub.positionId === parseInt(assignmentId, 10),
      );
    }

    return (
      <ExperienceFormComponent
        isSubPosition={isSubPosition}
        page={page}
        key={candidateUrl}
        title1="Edit Position"
        title2="Facility Info"
        title3={`${candidate.getName()} - References`}
        title4="Supervisor"
        title5="References"
        dates={candidate.getSubtitleForAgencyAssignment(positionId)}
        onSubmit={onSubmit}
        onRemove={onRemove}
        onPartialSubmit={onPartialSubmit}
        candidateUrl={candidateUrl}
        edit
        data={position}
        id={id}
      />
    );
  }
}

class ExperienceFormComponent extends ExperienceForm {
  constructor(props) {
    super(props);

    const startDate = props.data.startDate.split('-');
    const endDate = props.data.endDate.split('-');

    const dates = {
      yearFrom: startDate[2] || '',
      monthFrom: startDate[0] || '',
      dayFrom: startDate[1] || '',
      yearTo: endDate[2] || '',
      monthTo: endDate[0] || '',
      dayTo: endDate[1] || '',
    };

    this.state = {
      step: props.page,
      supervisor: {},
      references: {},
      ...props.data,
      ...dates,
    };

    this.state = { ...this.state, ...props.data };
  }

  onSubmit = (e) => {
    const {
      props: { onSubmit = () => {} },
      state,
    } = this;

    e.preventDefault();
    onSubmit(state);
  };

  onPartialSubmit = () => {
    const {
      props: { onPartialSubmit = () => {} },
      state,
    } = this;

    onPartialSubmit(state);
  };

  onRemove = (e) => {
    const {
      props: { onRemove = () => {} },
      state,
    } = this;

    e.preventDefault();
    onRemove(state);
  };
}

const mapStateToProps = (state) => ({
  candidateUrl: state.globalInfo.candidateUrl,
  candidate: getCandidateData(state),
});

export default connect(mapStateToProps, { update })(EditExperience);
