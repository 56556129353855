import React from 'react';
import moment from 'moment';
import { MOMENTS_DATES_FORMATS } from '../../../../constants';

const dates = (startDate, endDate, isCurrent, isEdu) => {
  if (isEdu) {
    return `${startDate} to ${endDate}`;
  }
  if (startDate) {
    const string = `${moment(startDate, MOMENTS_DATES_FORMATS).format(
      'MMM, YYYY',
    )}`;
    if (isCurrent) {
      return `${string} to Present`;
    }
    return `${string} ${
      endDate === '0000-00-00'
        ? ''
        : `to ${moment(endDate, MOMENTS_DATES_FORMATS).format('MMM, YYYY')}`
    }`;
  }
  return '';
};

const Details = ({
  name,
  subtitles = [],
  startDate,
  endDate,
  unit = '',
  description,
  isEdu,
  isCurrent = false,
}) => (
  <div>
    <h3 className="h3-details">{name}</h3>
    {subtitles.map((subtitle) => (
      <div key={subtitle}>
        {subtitle !== '-' && (
          <h4 key={subtitle} className="h4-details">
            {subtitle}
          </h4>
        )}
      </div>
    ))}
    {unit && <h4 className="h4-details">{unit}</h4>}
    {startDate && endDate ? (
      <p className="period">{dates(startDate, endDate, isCurrent, isEdu)}</p>
    ) : (
      <p className="period">{dates(startDate, endDate, isCurrent, isEdu)}</p>
    )}
    <div dangerouslySetInnerHTML={{ __html: description }} />
  </div>
);

export default Details;
