import moment from 'moment';

export const API_URL = process.env.REACT_APP_API_URL;

export const DOMAIN = process.env.REACT_APP_DOMAIN;

export const ACCESS_LVL = {
  EXPIRED: 'EXPIRED',
  ACTIVE: 'ACTIVE',
  NO_ACCESS: 'NO_ACCESS',
};

export const AUTH_COOKIE = process.env.REACT_APP_AUTH_COOKIE;

export const SPECIALTIES_LOOKUP_URL =
  process.env.REACT_APP_SPECIALTIES_LOOKUP_URL;

export const LICENSES_LOOKUP_URL = process.env.REACT_APP_LICENSES_LOOKUP_URL;

export const CONTENTS_LOOKUP_URL = process.env.REACT_APP_CONTENTS_LOOKUP_URL;

export const LOCATION_LOOKUP_URL = process.env.REACT_APP_LOCATION_LOOKUP_URL;

export const LOG_MESSAGES = {
  PERSONAL_CONTACT_INFORMATION_MODIFIED:
    'Personal contact information modified.',
  SUMMARY_INFORMATION_MODIFIED: 'Summary information modified.',
  SPECIALTY_INFORMATION_MODIFIED: 'Specialty information modified.',
  LIC_AND_CERTS_INFORMATION_MODIFIED:
    'License and Certification information modified.',
  EXPERIENCE_INFORMATION_MODIFIED: 'Experience information modified.',
  EDUCATION_INFORMATION_MODIFIED: 'Education information modified.',
  COMPUTER_SKILL_INFORMATION_MODIFIED: 'Computer skill information modified.',
  DOCUMENT_UPLOADED: 'Document uploaded.',
  REQUESTED_UPDATE_OF_APPLICATION_SIGNING:
    'Requested update of Application Signing Statements.',
  RECEIVED_UPDATE_OF_APPLICATION_SIGNING:
    'Received updated Application Signing Statements.',
  REQUESTED_UPDATE_OF_BACKGROUND: 'Requested update of Background Questions.',
  RECEIVED_UPDATE_OF_BACKGROUND: 'Received updated Background Questions.',
  REQUESTED_UPDATE_OF_SKILLS: 'Requested update of skills checklist.',
  RECEIVED_UPDATE_OF_SKILLS: 'Received updated skills checklist.',
  NEW_COVER_LETTER_ADDED: 'New cover letter added.',
  EXISTING_COVER_LETTER_EDITED: 'Existing cover letter edited.',
  NEW_REFERENCE_ADDED: 'New reference added.',
  EXISTING_REFERENCE_EDITED: 'Existing reference edited.',
  PROFILE_UPDATE_ACCEPTED: 'Profile update accepted.',
  PROFILE_UPDATE_DECLINED: 'Profile update declined.',
};

export const MOMENTS_DATES_FORMATS = [
  moment.ISO_8601,
  'MM-DD-YYYY',
  'MM/DD/YYYY',
];

export const unitIdMap = {
  0: '-',
  1: 'Admin',
  2: 'Ambulatory Care',
  3: 'Ambulatory PACU',
  4: 'Ambulatory Pre-Post Op',
  5: 'Bone Marrow',
  6: 'Burn',
  7: 'Burn ICU',
  8: 'Cardiac',
  9: 'Cardiac Cath Lab',
  10: 'Cardiac SDU',
  11: 'Case Manager',
  12: 'Cath Lab',
  13: 'CCU',
  14: 'Clinic',
  15: 'Corrections',
  16: 'CSU',
  17: 'CVICU',
  18: 'CVOR',
  19: 'Dermatology',
  20: 'Dialysis',
  21: 'Director',
  22: 'DOU',
  23: 'Ear Nose &amp; Throat',
  24: 'ED',
  25: 'Employee Health',
  26: 'ENDO/GI LAB',
  27: 'Endocrinology',
  28: 'Endoscopy',
  29: 'EP/Electrophysiology',
  30: 'ER',
  31: 'Family Care',
  32: 'Family Practice',
  33: 'FCC',
  34: 'Gastroenterology',
  35: 'General Practice',
  36: 'Geriatrics',
  37: 'GI Lab',
  38: 'Home Health',
  39: 'Home Health Hospice',
  40: 'Home Health Oncology',
  41: 'Home Health PEDS',
  42: 'Home Health Psych',
  43: 'Hospice',
  44: 'Hospitalist',
  45: 'House Supervisor',
  46: 'ICU',
  47: 'Infection Control',
  48: 'Internal Medicine',
  49: 'Interventional Radiology',
  50: 'L&amp;D',
  51: 'Level 1 Nursery',
  52: 'Long Term Acute Care',
  53: 'LPN',
  54: 'LTC',
  55: 'LVN',
  56: 'Mental Health',
  57: 'MICU',
  58: 'Midwife',
  59: 'Monitor Tech',
  60: 'Mother Baby',
  103: 'N/A',
  64: 'Neonatal ICU',
  61: 'Nephrology',
  62: 'Neuro',
  63: 'Neuro ICU',
  65: 'Nurse Department Manager',
  66: 'Nurse Educator',
  67: 'Nursing Assistant',
  68: 'Nursing Supervisor',
  69: 'OB/GYN',
  70: 'Occ Health',
  71: 'Oncology',
  72: 'Ophthalmology',
  73: 'OR',
  74: 'Ortho',
  75: 'Outpatient Clinic',
  76: 'Outpatient Oncology',
  77: 'PACU',
  78: 'Palliative Care',
  79: 'Patient Care Coordinator',
  81: 'PCICU',
  80: 'PCU',
  82: 'Pediatrics',
  83: 'PEDS',
  84: 'Phlebotomist',
  85: 'PICC',
  86: 'PICU',
  87: 'Postpartum',
  88: 'Psych',
  89: 'Rehab',
  90: 'RNFA',
  91: 'SDU',
  92: 'SICU',
  93: 'Sitter',
  94: 'TCU',
  95: 'TELE',
  96: 'Telephone Triage',
  97: 'Transplant',
  98: 'Trauma Manager',
  99: 'Triage',
  100: 'Urology',
  101: 'Utilization Review',
  102: 'Wound Care',
};
