import React from 'react';

const AdvancedSearch = ({ onSubmit }) => (
  <form onSubmit={onSubmit}>
    <div className="form-group">
      <label htmlFor="name">
        <span className="bp-label">Name:</span>
      </label>
      <input type="text" className="form-control" name="name" id="name" />
    </div>
    <div className="form-group">
      <label htmlFor="availability">
        <span className="bp-label">Availability:</span>
      </label>
      <input
        type="text"
        name="availability"
        id="availability"
        className="form-control input-sm"
      />
    </div>
    <div className="form-group">
      <label htmlFor="specialties">
        <span className="bp-label">Specialties:</span>
      </label>
      <input
        type="text"
        name="specialties"
        id="specialties"
        className="form-control input-sm"
      />
    </div>
    <div className="form-group">
      <label htmlFor="licenses">
        <span className="bp-label">Licenses & Certifications:</span>
      </label>
      <input
        type="text"
        name="licenses"
        id="licenses"
        className="form-control input-sm"
      />
    </div>
    <div className="form-group">
      <label htmlFor="work">
        <span className="bp-label">Work history:</span>
      </label>
      <input
        type="text"
        name="history"
        id="history"
        className="form-control input-sm"
      />
    </div>
    <div className="form-group">
      <label htmlFor="education">
        <span className="bp-label">Education:</span>
      </label>
      <input
        type="text"
        name="education"
        id="education"
        className="form-control input-sm"
      />
    </div>
    <div className="form-group">
      <label htmlFor="checklists">
        <span className="bp-label">Checklists:</span>
      </label>
      <input
        type="text"
        name="checklists"
        id="checklists"
        className="form-control input-sm"
      />
    </div>
    <div className="text-center">
      <button className="btn btn-block btn-primary" type="submit">
        Find Candidates
      </button>
    </div>
  </form>
);

export default AdvancedSearch;
