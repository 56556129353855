import React from 'react';
import Modal from '../../../Modal';

class RequestUpdateModal extends Modal.Manager {
  render() {
    const {
      handleClose,
      handleShow,
      state: { showModal, visible },
      props: { id, docType, onSubmit, requestUpdate, message, connected, name },
    } = this;

    return (
      <span>
        <a href="/" className="dropdown-menu-link" onClick={handleShow}>
          Request Update
        </a>
        {showModal && (
          <ModalComponent
            id={id}
            visible={visible}
            handleClose={handleClose}
            connected={connected}
            name={name}
            docType={docType}
            onSubmit={onSubmit}
            requestUpdate={requestUpdate}
            message={message}
          />
        )}
      </span>
    );
  }
}

class ModalComponent extends Modal.Manager {
  onSubmit = () => {
    const { handleClose, requestUpdate, message } = this.props;

    requestUpdate(message);
    handleClose();
  };

  render() {
    const {
      onSubmit,
      props: { visible, handleClose, connected, name },
    } = this;

    return (
      <Modal visible={visible} hideComponent={handleClose}>
        <Modal.Header
          title={`Request Document Update from ${name}`}
          hideComponent={handleClose}
        />
        <Modal.Body>
          {connected ? (
            <p>Do you want to send {name} a request to update this document?</p>
          ) : (
            <p>
              This candidate disconnected their profile from your company.
              Please reach to them to request they reconnect if you’d like.
            </p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-success" onClick={onSubmit}>
            Send Request
          </button>
          <button
            type="button"
            className="btn btn-default"
            onClick={handleClose}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default RequestUpdateModal;
