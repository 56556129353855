/* eslint-disable react/no-unused-state */

import React from 'react';
import { CoverLetter, ReferenceCheck } from '../../models/Documents';

/**
 * Abstract Class for all modals
 */
class ModalManager extends React.Component {
  state = {
    showModal: false,
    visible: false,
    modalData: {
      coverLetterData: new CoverLetter(),
      referenceCheck: new ReferenceCheck(),
      note: { typeOfData: 'note', textarea: '' },
    },
  };

  /**
   * Initialize fadeOut animation and then unmount modal
   */
  handleClose = (modalData, typeOfData) => {
    switch (typeOfData) {
      case 'cover-letter':
        if (modalData) {
          this.setState({
            modalData: { coverLetterData: modalData },
          });
        } else {
          this.setState({
            modalData: { coverLetterData: new CoverLetter() },
          });
        }
        break;
      case 'referencer':
        if (modalData) {
          this.setState({ modalData: { referenceCheck: modalData } });
        } else {
          this.setState({
            modalData: { referenceCheck: new ReferenceCheck() },
          });
        }
        break;
      case 'note':
        if (modalData) {
          this.setState({
            modalData: { note: modalData },
          });
        } else {
          this.setState({
            modalData: { note: { typeOfData: 'note', textarea: '' } },
          });
        }
        break;
      default:
        break;
    }

    this.setState({ visible: false });

    setTimeout(() => {
      this.setState({ showModal: false });
    }, 150);
  };

  /**
   * Mount modal and then initialize fadeIn animation
   */
  handleShow = (e) => {
    e.preventDefault();
    this.setState({ showModal: true });

    setTimeout(() => {
      this.setState({ visible: true });
    }, 50);
  };
}

export default ModalManager;
