import React from 'react';
import DeleteModal from '../../DeleteModal';
import { ACCESS_LVL } from '../../../../../constants';

const TableRow = ({
  id,
  s,
  name,
  update,
  muted,
  editedBy,
  docType,
  date,
  buttons,
  onDelete,
  title,
  onChange = () => {},
  shouldDisplayFourthColumn = true,
  access,
}) => (
  <tr>
    <td className="vcenter">
      <input type="checkbox" id="emailSelect" value={id} onChange={onChange} />
    </td>
    <td className="vAlign">
      <div>
        <span>
          <strong>{name}</strong>
          {title && <div>{title}</div>}
        </span>
      </div>
      {muted && <div className="muted-text">Phone: {muted}</div>}
    </td>
    <td className="vAlign">{date}</td>
    {shouldDisplayFourthColumn && <td className="vAlign">{editedBy || ''}</td>}
    <td className="vAlign">
      <div className="btn-group">
        <button
          type="button"
          className="btn btn-primary btn-xs dropdown-toggle"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          disabled={access === ACCESS_LVL.EXPIRED}
        >
          Action <span className="caret" />
        </button>
        <ul className="dropdown-menu">
          {buttons &&
            buttons.map((button) => (
              <li key={button.props.namespace}>{button}</li>
            ))}
          <li>
            <DeleteModal
              id={id}
              docType={docType}
              onDelete={onDelete}
              s={s}
              update={update}
            />
          </li>
        </ul>
      </div>
    </td>
  </tr>
);
export default TableRow;
