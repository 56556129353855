import React from 'react';
import _ from 'lodash';
import { TextField, TextareaField } from '../../../../FormFields';
import FormWrapper from '../../../../FormWrapper';
import { Reference } from '../../../../../models/Candidate';
import { unitIdMap } from '../../../../../constants';

class References extends FormWrapper {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);

    this.state = props.id
      ? props.fieldValues.references.find((ref) => ref.id === props.id)
      : new Reference();
  }

  onReferenceSubmit = (e) => {
    e.preventDefault();

    this.props.saveReference(this.state, !!this.props.id);
  };

  onInputFieldChange = ({ target: { name, type }, target }) => {
    const value = type === 'checkbox' ? target.checked : target.value;

    this.setState({ [name]: value });
  };

  options = () => {};

  render() {
    const {
      props: { id, thirdStep, fieldValues, onRemove = () => {} },
      state,
      onInputFieldChange,
      onReferenceSubmit,
    } = this;

    const mapItem = [];

    _.forIn(unitIdMap, (value, key) => {
      mapItem.push(
        <option key={key} value={key}>
          {unitIdMap[key]}
        </option>,
      );
    });

    return (
      <div className="panel panel-default">
        <div className="panel-heading">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12">
              <div className="text-large">{`${fieldValues.employer} - References`}</div>
            </div>
          </div>
        </div>
        <div className="panel-body">
          <form onSubmit={onReferenceSubmit}>
            <div className="row">
              <div className="col-xs-12 col-sm-8 col-md-8">
                <TextField
                  label="Reference Name"
                  name="referenceName"
                  id="referenceName"
                  required
                  value={state.referenceName}
                  onChange={onInputFieldChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-8 col-md-8">
                <TextField
                  label="Title"
                  name="title"
                  id="title"
                  value={state.title}
                  onChange={onInputFieldChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-8 col-md-8">
                <div className="form-group">
                  <label htmlFor="unitId">Unit:</label>
                  <select
                    className="form-control"
                    id="unitId"
                    name="unitId"
                    value={state.unitId}
                    onChange={onInputFieldChange}
                  >
                    {mapItem.map((item) => item)}
                  </select>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-8 col-md-8">
                <TextField
                  label="Phone"
                  name="phone"
                  id="phone"
                  value={state.phone}
                  onChange={onInputFieldChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-8 col-md-8">
                <TextField
                  label="Alt Phone"
                  name="phone2"
                  id="phone2"
                  value={state.phone2}
                  onChange={onInputFieldChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-8 col-md-8">
                <TextField
                  label="Email"
                  name="email"
                  id="email"
                  value={state.email}
                  onChange={onInputFieldChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-8">
                <TextareaField
                  id="notes"
                  name="notes"
                  label="Additional Info"
                  value={state.notes}
                  rows={10}
                  onChange={onInputFieldChange}
                />
              </div>
            </div>
            <div className="add-edit-btn">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 text-center">
                  <div className="form-group mt-20">
                    <button type="submit" className="btn btn-primary mt-5 mb-3">
                      Save
                    </button>
                    <button
                      onClick={() => {
                        thirdStep(this.state, false);
                      }}
                      className="btn btn-warning mt-5 mb-3"
                    >
                      Cancel
                    </button>
                    {id && (
                      <button
                        className="btn btn-danger mt-5 mb-3"
                        onClick={(e) => onRemove(e, this.state)}
                      >
                        Remove
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default References;
