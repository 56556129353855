/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import { Link } from 'react-router-dom';
import SendMessage from './SendMessageModal';
import AddNoteModal from './AddNoteModal';
import { ACCESS_LVL } from '../../constants';

const ContactContainer = ({
  isDetailed,
  id,
  photo,
  legacyUrl,
  firstName,
  lastName,
  phone,
  licenseCerts,
  designations,
  specialties,
  access,
  updateData,
  showOnlyUpdates,
}) =>
  ((showOnlyUpdates && updateData.key) || !showOnlyUpdates) && (
    <div className="contact-container">
      <div className="row">
        <div className="col-xs-4 col-xs-offset-4 col-sm-3 col-sm-offset-0 col-md-2 col-md-offset-0">
          <div className="bp-heading">
            <img
              className="img-responsive img-circle"
              id=""
              src={photo}
              alt="..."
            />
          </div>
        </div>
        <div className="col-xs-12 col-sm-8 col-md-9">
          <div className="text-large capitalize">
            {firstName} {lastName}
            {designations ? <div>,{designations}</div> : ''}
          </div>
          <p>
            <span className="text-bold">Phone: </span>
            {phone}
          </p>
          <p>
            <span className="text-bold">Licenses & Certifications: </span>
            {licenseCerts}
          </p>
          <p>
            <span className="text-bold">Specialties: </span>
            {specialties}
          </p>
          {isDetailed ? (
            <div className="bp-heading">
              <SendMessage legacyUrl={legacyUrl} access={access} />
              <AddNoteModal access={access} />
            </div>
          ) : (
            <div className="bp-heading">
              <Link to={{ pathname: `/${id}` }} className="btn btn-default">
                <span className="glyphicon glyphicon-zoom-in" /> View
              </Link>
              <SendMessage legacyUrl={legacyUrl} access={access} />
              {updateData.key && access === ACCESS_LVL.ACTIVE && (
                <Link
                  to={{ pathname: `/${id}/updates` }}
                  className="btn btn-warning"
                >
                  <span className="glyphicon glyphicon-bell" /> Updates
                  Available
                </Link>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );

export default ContactContainer;
