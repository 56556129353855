import React from 'react';
import { connect } from 'react-redux';
import { update } from '../../../../actions/candidates';
import { history } from '../../../../store';
import ComputerSkillForm from '../../../../components/Candidate/Profile/ComputerSkills/ComputerSkillForm';
import { getCandidateData } from '../../../../selectors';
import NotFound from '../../../../components/NotFound/NotFound';
import Loading from '../../../../components/Loading';
import { LOG_MESSAGES } from '../../../../constants';

class EditComputerSkill extends React.Component {
  onSubmit = (skill) => {
    const { candidate, candidateUrl } = this.props;
    candidate.editComputerSkill(skill);
    this.props.update(LOG_MESSAGES.COMPUTER_SKILL_INFORMATION_MODIFIED);
    history.push(`/${candidateUrl}/profile`);
  };

  onRemove = (skill) => {
    const { candidate, candidateUrl } = this.props;
    candidate.removeComputerSkill(skill);
    this.props.update(LOG_MESSAGES.COMPUTER_SKILL_INFORMATION_MODIFIED);
    history.push(`/${candidateUrl}/profile`);
  };

  render() {
    const {
      onSubmit,
      onRemove,
      props: {
        candidate,
        candidateUrl,
        isFetching,
        match: {
          params: { id },
        },
      },
    } = this;

    if (isFetching) {
      return <Loading />;
    }
    if (candidate === undefined) {
      return <NotFound />;
    }

    const computerSkill = candidate.computerSkills.find(
      (skill) => skill.computerSkillId === parseInt(id, 10),
    );

    return (
      <ComputerSkillFormComponent
        key={candidateUrl}
        title="Edit Computer Skill"
        onSubmit={onSubmit}
        onRemove={onRemove}
        candidateUrl={candidateUrl}
        edit
        data={computerSkill}
      />
    );
  }
}

class ComputerSkillFormComponent extends ComputerSkillForm {
  constructor(props) {
    super(props);

    this.state = props.data;
  }

  onSubmit = (e) => {
    const {
      props: { onSubmit = () => {} },
      state,
    } = this;

    e.preventDefault();
    onSubmit(state);
  };

  onRemove = (e) => {
    const {
      props: { onRemove = () => {} },
      state,
    } = this;

    e.preventDefault();
    onRemove(state);
  };
}

const mapStateToProps = (state) => ({
  candidateUrl: state.globalInfo.candidateUrl,
  candidate: getCandidateData(state),
});

export default connect(mapStateToProps, { update })(EditComputerSkill);
