import axios from 'axios';
import { history } from '../store';
import { API_URL } from '../constants';
import { FETCH_LOGS, FETCH_LOGS_SUCCESS, FETCH_LOGS_ERROR } from '.';
import { getPage } from '../selectors';

const fetchLogs = () => ({
  type: FETCH_LOGS,
});

const fetchLogsSuccess = (data, url) => ({
  type: FETCH_LOGS_SUCCESS,
  data,
  url,
});

const fetchLogsError = () => ({
  type: FETCH_LOGS_ERROR,
});

export default () => async (dispatch, getState) => {
  const state = getState();
  const { candidateUrl, token, companyId } = state.globalInfo;
  const { logs } = state;
  const page = getPage(state);

  if (logs.isFetching) {
    return;
  }

  dispatch(fetchLogs());
  try {
    const response = await axios.get(
      `${API_URL}logs/${candidateUrl}?companyId=${companyId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );

    if (response.data.data.length / 10 + 1 < page) {
      history.push(`${state.routing.location.pathname}`);
    }

    dispatch(fetchLogsSuccess(response.data.data, candidateUrl));
  } catch (e) {
    dispatch(fetchLogsError());
  }
};
