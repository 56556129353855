import React from 'react';
import Table from './Table';
import TableRow from './TableRow';
import { EditCoverLetterModal } from '../CoverLetterModals';

const CoverLettersTable = ({
  columns,
  coverLetters = [],
  docType,
  documents,
  update,
  handleDeleteLetter,
  onLetterChange,
  user,
  access,
}) => (
  <Table columns={columns}>
    {coverLetters.length > 0 &&
      coverLetters.map((letter) => (
        <TableRow
          key={letter.id}
          id={letter.id}
          name={letter.title}
          editedBy={letter.lastEditedBy}
          date={letter.getModifiedDate()}
          onChange={onLetterChange}
          onDelete={() => handleDeleteLetter(letter.id)}
          access={access}
          buttons={[
            <EditCoverLetterModal
              data={letter}
              namespace={`edit-cover-letter-button-${letter.id}`}
              docType={docType}
              documents={documents}
              update={update}
              user={user}
            />,
          ]}
        />
      ))}
  </Table>
);

export default CoverLettersTable;
