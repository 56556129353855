import React from 'react';

const Title = ({ title, isSubtitle = false }) => {
  const titleClass = isSubtitle ? '' : ' text-center text-large';
  const titleLabel = isSubtitle ? (
    <span className="muted-text-xl">{title}</span>
  ) : (
    title
  );

  if (!title) return null;

  return (
    <div className="row">
      <div className={`col-xs-12 col-sm-12 col-md-12${titleClass}`}>
        {titleLabel}
      </div>
    </div>
  );
};

export default Title;
