import axios from 'axios';
import { history } from '../store';
import { API_URL } from '../constants';
import { FETCH_NOTES, FETCH_NOTES_SUCCESS, FETCH_NOTES_ERROR } from '.';
import { getPage } from '../selectors';

const fetchNotes = () => ({
  type: FETCH_NOTES,
});

const fetchNotesSuccess = (data, url) => ({
  type: FETCH_NOTES_SUCCESS,
  data,
  url,
});

const fetchNotesError = (url) => ({
  type: FETCH_NOTES_ERROR,
  url,
});

export default () => async (dispatch, getState) => {
  const state = getState();
  const { candidateUrl, token, companyId } = state.globalInfo;
  const { notes } = state;
  const page = getPage(state);

  if (notes.isFetching || notes[candidateUrl]) {
    return;
  }

  dispatch(fetchNotes());
  try {
    const response = await axios.get(
      `${API_URL}notes/${candidateUrl}?companyId=${companyId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );

    if (response.data.data.length / 10 + 1 < page) {
      history.push(`${state.routing.location.pathname}`);
    }

    dispatch(fetchNotesSuccess(response.data.data, candidateUrl));
  } catch (e) {
    dispatch(fetchNotesError(candidateUrl));
  }
};
