import React from 'react';
import { connect } from 'react-redux';
import { history } from '../../../../store';
import ExperienceForm from '../../../../components/Candidate/Profile/Experience/ExperienceForm';
import { getCandidateData } from '../../../../selectors';
import { update } from '../../../../actions/candidates';
import { LOG_MESSAGES } from '../../../../constants';

class AddExperience extends React.Component {
  constructor() {
    super();
    this.state = {
      key: 0,
    };
  }

  onSubmit = (e, addSub = false) => {
    const { candidate, update, isSubPosition, candidateUrl, match } =
      this.props;

    if (isSubPosition) {
      candidate.addSubposition(e, match.params.positionId);
    } else {
      candidate.addPosition(e);
    }
    update(LOG_MESSAGES.EXPERIENCE_INFORMATION_MODIFIED);
    if (addSub) {
      history.push(
        `/candidates/${candidateUrl}/profile/add-position/${e.positionId}/assignment`,
      );

      let { key } = this.state;
      key += 1;
      this.setState({ key });
    } else {
      history.push(`/${candidateUrl}/profile`);
    }
  };

  onPartialSubmit = (e) => {
    const { candidate, update, isSubPosition, match, candidateUrl } =
      this.props;
    const toInt = parseInt(e.facilityType, 10);

    const nextPage = toInt === 1 || toInt === 2 || toInt === 17 ? 2 : 3;

    let id = '';

    if (isSubPosition) {
      id = candidate.addSubposition(e, match.params.positionId);
    } else {
      id = candidate.addPosition(e);
    }

    update(LOG_MESSAGES.EXPERIENCE_INFORMATION_MODIFIED);

    if (isSubPosition) {
      history.push(
        `/${candidateUrl}/profile/edit-position/${match.params.positionId}/assignment/${id}/${nextPage}`,
      );
    } else {
      history.push(`edit-position/${id}/${nextPage}`);
    }
  };

  backToEditPosition = () => {
    history.push(
      `/${this.props.candidateUrl}/profile/edit-position/${this.props.match.params.positionId}/add`,
    );
  };

  render() {
    const {
      onSubmit,
      onPartialSubmit,
      props: {
        candidate,
        candidateUrl,
        page = 1,
        isSubPosition = false,
        match,
      },
    } = this;

    return (
      <ExperienceFormComponent
        isSubPosition={isSubPosition}
        page={page}
        dates={candidate.getSubtitleForAgencyAssignment(
          match.params.positionId,
        )}
        key={this.state.key}
        title1="Add Position"
        title2="Facility Info"
        title3={`${candidate.getName()} - References`}
        title4="Supervisor"
        title5="References"
        candidateUrl={candidateUrl}
        onSubmit={onSubmit}
        onPartialSubmit={onPartialSubmit}
        backToEditPosition={this.backToEditPosition}
      />
    );
  }
}

class ExperienceFormComponent extends ExperienceForm {
  onSubmit = (e, addSub) => {
    const {
      props: { onSubmit = () => {} },
      state,
    } = this;

    if (e) {
      e.preventDefault();
    }

    onSubmit(state, addSub);
  };

  onPartialSubmit = () => {
    const {
      props: { onPartialSubmit = () => {} },
      state,
    } = this;

    onPartialSubmit(state);
  };
}

const mapStateToProps = (state) => ({
  candidateUrl: state.globalInfo.candidateUrl,
  candidate: getCandidateData(state),
});

export default connect(mapStateToProps, { update })(AddExperience);
