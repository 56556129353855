import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { update } from '../../../../actions/candidates';
import { history } from '../../../../store';
import SpecialtiesForm from '../../../../components/Candidate/Profile/Specialties/SpecialtiesForm';
import { getCandidateData, getSpecialties } from '../../../../selectors';
import { LOG_MESSAGES } from '../../../../constants';

const EditSpecialty = ({
  candidate,
  candidateUrl,
  update,
  fetchSpecialties,
  specialties,
  match: {
    params: { id },
  },
}) => {
  const speciality = candidate.specialties.find(
    (s) => s.specialtyId === parseInt(id, 10),
  );

  return (
    <SpecialtiesFormComponent
      title="Edit Specialty"
      candidateUrl={candidateUrl}
      speciality={speciality}
      fetchSpecialties={fetchSpecialties}
      autosuggestSource={specialties}
      candidate={candidate}
      update={update}
      edit
    />
  );
};

class SpecialtiesFormComponent extends SpecialtiesForm {
  constructor(props) {
    super(props);

    this.state = {
      ...props.speciality,
      showCustomForm: false,
    };
  }

  onSubmit = (e) => {
    const { candidate, update, candidateUrl } = this.props;
    e.preventDefault();
    const specialty = _.omit(this.state, 'showCustomForm');
    candidate.editSpecialty(specialty);
    update(LOG_MESSAGES.SPECIALTY_INFORMATION_MODIFIED);
    history.push(`/${candidateUrl}/profile`);
  };

  onRemove = (e) => {
    const { candidate, update, candidateUrl } = this.props;
    e.preventDefault();
    const specialty = _.omit(this.state, 'showCustomForm');
    candidate.removeSpecialty(specialty);
    update(LOG_MESSAGES.SPECIALTY_INFORMATION_MODIFIED);
    history.push(`/${candidateUrl}/profile`);
  };
}

const mapStateToProps = (state) => ({
  candidate: getCandidateData(state),
  candidateUrl: state.globalInfo.candidateUrl,
  specialties: getSpecialties(state),
});

export default connect(mapStateToProps, { update })(EditSpecialty);
