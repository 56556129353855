/* eslint-disable no-nested-ternary */

import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import {
  homeFetchAgain,
  startFetchConnections,
} from '../../actions/connections';
import toggleUpdates from '../../actions/toggleUpdates';
import {
  getConnections,
  getConnectionsWithUpdates,
  getPage,
  numberOfResults,
  numberOfResultsWithUpdates,
} from '../../selectors';
import Candidates from '../../components/Candidates';
import Loading from '../../components/Loading';
import { history } from '../../store';

const resultsPerPage = 10;

export class CandidatesContainer extends Component {
  componentDidMount() {
    if (!this.props.errors) {
      this.props.homeFetchAgain();
      this.props.startFetchConnections();
    }
  }

  componentWillReceiveProps() {
    window.scrollTo(0, 0);
    if (!this.props.errors) {
      this.props.startFetchConnections();
    }
  }

  handleToggleUpdates = () => {
    this.props.toggleUpdates();
    history.push('/');
  };

  render() {
    const {
      page,
      numberOfConnections,
      numberOfConnectionsWithUpdates,
      candidates,
      candidatesWithUpdates,
      companyName,
      isFetching,
      match: { url },
      query,
      access,
      showOnlyUpdates,
    } = this.props;
    return (
      <div className="row">
        <Helmet>
          <title>{`${companyName} Candidates`}</title>
        </Helmet>
        <div className="col-xs-12 col-sm-12 col-md-12">
          <div className="panel panel-default bp-panel">
            <div className="panel-body">
              {_.some(candidates) || query ? (
                <Candidates
                  page={page}
                  numberOfConnections={
                    showOnlyUpdates
                      ? numberOfConnectionsWithUpdates
                      : numberOfConnections
                  }
                  candidates={
                    showOnlyUpdates ? candidatesWithUpdates : candidates
                  }
                  resultsPerPage={resultsPerPage}
                  url={url}
                  query={query}
                  access={access}
                  showOnlyUpdates={showOnlyUpdates}
                  toggleUpdates={this.handleToggleUpdates}
                />
              ) : isFetching ? (
                <Loading />
              ) : (
                <div>
                  <h4 className="text-center">
                    No Candidates have shared their profile with {companyName}{' '}
                    yet. Get busy Recruiting!
                  </h4>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CandidatesContainer.defaultProps = {
  candidates: [],
  candidatesWithUpdates: [],
  numberOfConnections: 0,
  numberOfConnectionsWithUpdates: 0,
};

const mapStateToProps = (state) => ({
  candidates: getConnections(state),
  candidatesWithUpdates: getConnectionsWithUpdates(state),
  isFetching: state.connections.isFetching,
  errors: state.connections.errors,
  companyName: state.globalInfo.companyName,
  companyId: state.globalInfo.companyId,
  numberOfConnections: numberOfResults(state),
  numberOfConnectionsWithUpdates: numberOfResultsWithUpdates(state),
  page: getPage(state),
  query: state.globalInfo.query,
  access: state.globalInfo.access,
  showOnlyUpdates: state.globalInfo.showOnlyUpdates,
});

const mapDispatchToProps = (dispatch) => ({
  homeFetchAgain: () => dispatch(homeFetchAgain()),
  startFetchConnections: () => dispatch(startFetchConnections()),
  toggleUpdates: () => dispatch(toggleUpdates()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CandidatesContainer);
