import React from 'react';
import SkillLevelRow from './SkillLevelRow';

const SkillLevelTable = ({
  tableLabel = '',
  skills = [],
  levels = [],
  onLevelChange = () => {},
}) => (
  <table className="table table-condensed">
    <tbody>
      <tr>
        <th>{tableLabel}</th>
        {levels.map(({ id, label }) => (
          <th key={`skill-level-header-row-${id}`} className="text-center">
            {label}
          </th>
        ))}
      </tr>
      {skills.map(({ id, label, levelId }) => (
        <SkillLevelRow
          id={id}
          key={`skill-level-row-${id}`}
          label={label}
          levels={levels}
          currentLevelId={levelId}
          onLevelChange={onLevelChange}
        />
      ))}
    </tbody>
  </table>
);
export default SkillLevelTable;
