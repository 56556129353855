import React from 'react';
import { connect } from 'react-redux';
import Modal from '../../Modal';
import FormWrapper from '../../FormWrapper';
import addNote from '../../../actions/addNote';
import { ACCESS_LVL } from '../../../constants';

class AddNoteModal extends Modal.Manager {
  render() {
    const { access } = this.props;
    const {
      handleClose,
      handleShow,
      state: { showModal, visible, modalData },
    } = this;
    const modalComponent = showModal ? (
      <ModalComponent
        visible={visible}
        handleClose={handleClose}
        addNote={this.props.addNote}
        modalData={modalData}
      />
    ) : (
      ''
    );

    return (
      <span>
        <button
          type="button"
          className="btn btn-info"
          onClick={handleShow}
          disabled={access === ACCESS_LVL.EXPIRED}
        >
          <span className="glyphicon glyphicon-file" /> Add Note
        </button>
        {modalComponent}
      </span>
    );
  }
}

class ModalComponent extends FormWrapper {
  state = {
    note: this.props.modalData.note.note,
    typeOfData: this.props.modalData.note.typeOfData,
  };

  onNoteChange = (e) => {
    this.setState({
      note: e.target.value,
    });
  };

  onSubmit = () => {
    if (this.validate()) {
      this.props.addNote(this.state.note);
      this.props.handleClose();
    }
  };

  validate() {
    return this.state.note.length > 0;
  }

  render() {
    const {
      onNoteChange,
      onSubmit,
      props: { visible, handleClose },
    } = this;

    return (
      <Modal
        visible={visible}
        hideComponent={() => handleClose(this.state, this.state.typeOfData)}
      >
        <Modal.Header
          title="Add Note"
          hideComponent={() => handleClose(this.state, this.state.typeOfData)}
        />
        <Modal.Body>
          <form>
            <div className="form-group">
              <label htmlFor="notes">Enter Note:</label>
              <textarea
                className="form-control"
                id="message"
                name="message"
                rows="8"
                value={this.state.note}
                onChange={onNoteChange}
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 text-center">
              <button
                id="message-submit"
                type="submit"
                className="btn btn-primary"
                onClick={onSubmit}
              >
                <span className="glyphicon glyphicon-send" /> Save
              </button>
              <button
                type="button"
                className="btn btn-default"
                onClick={() => handleClose(this.state, this.state.typeOfData)}
              >
                <span className="glyphicon glyphicon-remove-circle" /> Cancel
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default connect(null, {
  addNote,
})(AddNoteModal);
