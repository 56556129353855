import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import FormWrapper from '../../../../FormWrapper';
import DocumentsContents from '../../DocumentsContents';
import Modal from '../../../../Modal';
import { LOG_MESSAGES } from '../../../../../constants';

const allowedFiles = [
  'image/png',
  'image/jpeg',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/pdf',
  'application/rtf',
  'text/rtf',
  'text/plain',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
];

const allowedFileSize = 5000000;

export class UploadModalContent extends FormWrapper {
  constructor() {
    super();

    this.state = {
      file: {},
      content: [],
      notes: '',
      isValid: true,
    };
  }

  onSubmit = async (e) => {
    if (e) {
      e.preventDefault();
    }

    const {
      props: {
        onEdit = () => {},
        edit,
        id,
        uploadDocument,
        putDocument,
        handleClose,
        update,
        log,
      },
      state,
    } = this;

    if (edit) {
      onEdit(state, id);
      update();
      handleClose();
      return true;
    }

    const jsonData = {
      type: 'fileNew',
      documentPayload: {
        fileId: +new Date(),
        s3key: state.file.name,
        title: state.file.name,
        notes: state.notes,
        expiration: null,
        size: state.file.size,
        fileType: state.file.type,
        content: state.content.map((content) => ({
          expireDate: content.expireDate,
          type: '',
          name: content.name,
          acronym: content.acronym,
          typeId: content.typeId,
        })),
      },
    };

    if (this.state.file) {
      const fileUploaded = await uploadDocument(state.file);

      if (fileUploaded) {
        await putDocument(jsonData);
        handleClose();
        log(LOG_MESSAGES.DOCUMENT_UPLOADED);
        window.location.reload();
      }
    }
    return true;
  };

  onAddContent = (contentName, expireDate, acronym) => {
    this.setState({
      content: [
        ...this.state.content,
        {
          typeId: moment().valueOf(),
          name: contentName,
          expireDate,
          acronym,
        },
      ],
    });
  };

  onDeleteContent = (typeId) => {
    this.setState((prevState) => ({
      content: prevState.content.filter((content) => content.typeId !== typeId),
    }));
  };

  onNotesChange = (notes) => {
    this.setState({ notes });
  };

  onFileChange = (e) => {
    const file = e.target.files[0];

    if (
      _.indexOf(allowedFiles, file.type) >= 0 &&
      file.size < allowedFileSize
    ) {
      this.setState({ file: e.target.files[0], isValid: true });
    } else {
      this.setState({ isValid: false });
    }
  };

  getFileComponent = (onFileChange) => (
    <div>
      <div className={`input-group ${this.state.isValid ? '' : 'has-error'}`}>
        <span className="input-group-btn">
          <span className="btn btn-primary btn-file" id="upload-document">
            Browse…{' '}
            <input
              type="file"
              name="file"
              onChange={onFileChange}
              accept={_.join(allowedFiles, ', ')}
              required
            />
          </span>
        </span>
        <input
          type="text"
          className={`form-control ${
            this.state.isValid ? '' : 'has-error red-text'
          }`}
          readOnly
          value={
            this.state.isValid
              ? this.state.file.name
              : 'Please upload correct file'
          }
        />
      </div>
      <p className="text-muted">
        (5mb Max: jpg, jpeg, doc, docx, pdf, rtf, png, txt, xlx, xlsx, ppt,
        pptx)
      </p>
    </div>
  );

  render() {
    const {
      onSubmit,
      onAddContent,
      onDeleteContent,
      onNotesChange,
      onFileChange,
      props: { visible, handleClose, source, edit = false },
      state: { content, notes },
    } = this;

    const fileComponent = this.getFileComponent(onFileChange);

    return (
      <Modal visible={visible} hideComponent={handleClose} wide>
        <Modal.Header
          title={`${edit ? 'Edit Document Details' : 'Upload New Document'}`}
          hideComponent={handleClose}
        />
        <Modal.Body>
          <form onSubmit={onSubmit} id="upload">
            {fileComponent}
            <DocumentsContents
              content={content}
              notes={notes}
              onAddContent={onAddContent}
              onDeleteContent={onDeleteContent}
              onNotesChange={onNotesChange}
              source={source}
            />
          </form>
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 text-center">
              <div className="form-group">
                <button
                  type="submit"
                  className="btn btn-success"
                  value="Submit"
                  form="upload"
                >
                  Save
                </button>
                <button
                  type="button"
                  className="btn btn-default"
                  data-dismiss="modal"
                  onClick={handleClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default UploadModalContent;
