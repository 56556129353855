/* eslint-disable react/sort-comp */
import React from 'react';
import { connect } from 'react-redux';
import { Alert } from 'react-bootstrap';
import { hideAlert } from '../../actions/alert';

class AlertDismissable extends React.Component {
  handleDismiss = () => {
    this.props.hideAlert();
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.alert) {
      setTimeout(() => {
        this.handleDismiss();
      }, 6000);
    }
  }

  render() {
    return this.props.alert ? (
      <Alert bsStyle={this.props.type} onDismiss={this.handleDismiss}>
        <p className="text-center">{this.props.message}</p>
      </Alert>
    ) : null;
  }
}

const mapStateToProps = (state) => ({
  alert: state.globalInfo.alert,
  type: state.globalInfo.alertType,
  message: state.globalInfo.alertMessage,
});

export default connect(mapStateToProps, { hideAlert })(AlertDismissable);
