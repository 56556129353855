import React from 'react';
import Table from './Table';
import TableRow from './TableRow';
import RequestUpdateModal from '../RequestUpdateModal';
import { LOG_MESSAGES } from '../../../../constants';

const SkillsChecklistsTable = ({
  columns,
  body,
  docType,
  requestUpdate,
  onDeleteStatement,
  update,
  access,
  onChange = () => {},
}) => (
  <Table columns={columns}>
    {body &&
      body.map((row) => (
        <TableRow
          key={row.userInfo.checklistId}
          id={row.userInfo.checklistId}
          onChange={onChange}
          shouldDisplayFourthColumn={false}
          update={update}
          name={row.data.name}
          onDelete={() => onDeleteStatement(row.userInfo.checklistId)}
          date={row.getDate()}
          access={access}
          buttons={[
            <RequestUpdateModal
              id={row.userInfo.checklistId}
              namespace={`request-update-button-${row.userInfo.checklistId}`}
              docType={docType}
              update={update}
              requestUpdate={requestUpdate}
              message={LOG_MESSAGES.REQUESTED_UPDATE_OF_SKILLS}
            />,
          ]}
        />
      ))}
  </Table>
);

export default SkillsChecklistsTable;
