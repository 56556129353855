import React from 'react';
import { Link } from 'react-router-dom';
import { TextField, AddOrChooseField } from '../../../../FormFields';
import FormWrapper from '../../../../FormWrapper';
import { Specialty } from '../../../../../models/Candidate';

const initialState = new Specialty();

const experienceOptions = [
  { value: -1, label: '-' },
  { value: 0, label: 'Less than 1' },
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
  { value: 6, label: '6' },
  { value: 7, label: '7' },
  { value: 8, label: '8' },
  { value: 9, label: '9' },
  { value: 10, label: '10-15' },
  { value: 15, label: '15-20' },
  { value: 20, label: '20+' },
];

class SpecialtiesForm extends FormWrapper {
  constructor() {
    super();

    this.state = {
      ...initialState,
      showCustomForm: false,
    };
    window.scrollTo(0, 0);
  }

  onSpecialtySelect = ({ name, acronym }) => {
    this.setState({
      name,
      acronym,
    });
  };

  onShowCustomForm = (value) => {
    this.setState({ ...initialState, name: value, showCustomForm: true });
  };

  onShowAutocomplete = () => {
    this.setState({ showCustomForm: false, name: '' });
  };

  render() {
    const {
      onSpecialtySelect,
      onShowCustomForm,
      onShowAutocomplete,
      onInputFieldChange,
      setValueForLeaveHandler,
      onSubmit = () => {},
      onRemove = () => {},
      props: { title, edit = false, autosuggestSource = [], candidateUrl },
      state: { name, acronym, yearsExperience, showCustomForm },
    } = this;

    return (
      <div className="panel panel-default">
        <div className="panel-heading">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12">
              <div className="text-large">{title}</div>
            </div>
          </div>
        </div>
        <div className="panel-body">
          <form onSubmit={onSubmit}>
            <div>
              <AddOrChooseField
                title="Add New Specialty"
                value={name}
                fieldLabel="Specialty"
                fieldName="specialty"
                disabled={edit}
                required
                source={autosuggestSource}
                showCustomForm={showCustomForm}
                onSelect={onSpecialtySelect}
                setValueForLeaveHandler={setValueForLeaveHandler}
                onShowCustomForm={onShowCustomForm}
                onShowAutocomplete={onShowAutocomplete}
              >
                <TextField
                  id="specialty-acronym"
                  name="acronym"
                  label="Acronym"
                  value={acronym}
                  onChange={onInputFieldChange}
                />
                <TextField
                  id="specialty-name"
                  name="name"
                  label="Name*"
                  required
                  value={name}
                  onChange={onInputFieldChange}
                />
              </AddOrChooseField>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-8 col-md-8">
                <div className="form-group">
                  {edit ? (
                    <TextField
                      id="specialty-acronym"
                      name="acronym"
                      label="Acronym"
                      disabled={edit}
                      value={acronym}
                    />
                  ) : (
                    ''
                  )}

                  <label htmlFor="years-experience">Years of Experience:</label>
                  <select
                    className="form-control"
                    id="years-experience"
                    name="yearsExperience"
                    value={yearsExperience}
                    onChange={onInputFieldChange}
                  >
                    {experienceOptions.map(({ value, label }) => (
                      <option key={`experience-option-${value}`} value={value}>
                        {label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 text-center">
                <div className="form-group mt-20">
                  <input
                    type="submit"
                    className="btn btn-primary mt-5 mb-3"
                    value="Save"
                  />
                  <Link
                    to={`/${candidateUrl}/profile`}
                    className="btn btn-warning mt-5 mb-3"
                  >
                    Cancel
                  </Link>
                  {edit && (
                    <input
                      type="button"
                      className="btn btn-danger mt-5 mb-3"
                      onClick={onRemove}
                      value="Remove"
                    />
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default SpecialtiesForm;
