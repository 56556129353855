/* eslint-disable jsx-a11y/label-has-for */

import React from 'react';
import { DocumentEditModal } from '../../DocumentModals';
import { ACCESS_LVL } from '../../../../../constants';

const checkbox = {
  marginLeft: '0px',
};

const UploadedDocumentsRow = ({
  id,
  name,
  onFileRawChange,
  onFilePdfChange,
  createdDate,
  notes,
  content = [],
  deleteFile,
  onEdit,
  source,
  access,
}) => (
  <tr>
    <td className="vcenter">
      <div className="checkbox">
        <input
          type="checkbox"
          value={id}
          name="raw"
          onChange={onFileRawChange}
          style={checkbox}
        />
        <label htmlFor="raw">In original format</label>
      </div>
      <div className="checkbox">
        <input
          type="checkbox"
          value={id}
          name="pdf"
          onChange={onFilePdfChange}
          style={checkbox}
        />
        <label htmlFor="pdf">Added to PDF</label>
      </div>
    </td>
    <td>
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12">
          <div className="doc-wrapper">
            <div className="row">
              <div className="col-xs-12 col-sm-6 col-md-6">
                <div className="margin-bottom-medium">
                  <div className="text-bold">File name:</div>
                  <div>{name}</div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-6">
                <div className="margin-bottom-medium">
                  <div className="text-bold">Created:</div>
                  <div>{createdDate}</div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12">
                <div className="margin-bottom-medium">
                  <div className="text-bold">Notes:</div>
                  <div>{notes}</div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-6 col-md-6">
                <label htmlFor="content">Contents:</label>
                {content.length > 0 ? (
                  <select
                    className="form-control"
                    id="content"
                    name="content"
                    value={content[0].acronym}
                    onChange={() => {}}
                  >
                    {content.map((c) => (
                      <option key={c.typeId} value={c.typeId}>
                        {c.acronym}
                      </option>
                    ))}
                  </select>
                ) : (
                  <select
                    className="form-control"
                    id="content"
                    name="content"
                    value="-"
                    onChange={() => {}}
                  >
                    <option value="-">-</option>
                  </select>
                )}
              </div>
              <div className="col-xs-12 col-sm-6 col-md-6">
                <div className="connections-body">
                  <DocumentEditModal
                    id={id}
                    name={name}
                    content={content}
                    notes={notes}
                    onEdit={onEdit}
                    source={source}
                    access={access}
                  />
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => deleteFile(id)}
                    disabled={access === ACCESS_LVL.EXPIRED}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </td>
  </tr>
);

export default UploadedDocumentsRow;
