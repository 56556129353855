import React from 'react';
import { Link } from 'react-router-dom';
import { TextField } from '../../../../FormFields';
import FormWrapper from '../../../../FormWrapper';
import LocationInformation from '../../../../FormFields/LocationInformation';
import RichEditorField from '../../../../FormFields/RichEditorField';

class ExperienceFormStep1 extends FormWrapper {
  constructor(props) {
    super(props);

    this.state = {
      positionError: false,
      subposError: false,
    };

    window.scrollTo(0, 0);
  }

  validateType = () =>
    this.props.edit
      ? true
      : (parseInt(this.props.fieldValues.employmentType, 10) || 0) +
          (parseInt(this.props.fieldValues.assignmentType, 10) || 0) >
        0;

  validateEmpTypeSub = () =>
    parseInt(this.props.fieldValues.facilityType, 10) > 0;

  validateEmpTypePos = () =>
    parseInt(this.props.fieldValues.facilityType, 10) > 0;

  validateEmployer = () => this.props.fieldValues.employer !== '';

  validateJobTitle = () => this.props.fieldValues.title !== '';

  validateDates = () => {
    const { fieldValues } = this.props;
    if (fieldValues.isCurrent) {
      return (
        fieldValues.startDate ||
        (fieldValues.monthFrom && fieldValues.dayFrom && fieldValues.yearFrom)
      );
    }
    return (
      (fieldValues.startDate ||
        (fieldValues.monthFrom &&
          fieldValues.dayFrom &&
          fieldValues.yearFrom)) &&
      (fieldValues.endDate ||
        (fieldValues.monthTo && fieldValues.dayTo && fieldValues.yearTo))
    );
  };

  validateLocations = () =>
    !this.state.positionError && !this.state.subposError;

  validate = () => {
    if (parseInt(this.props.fieldValues.employmentType, 10) === 1) {
      return true;
    }
    return (
      (this.validateEmpTypeSub() || this.validateEmpTypePos()) &&
      this.validateType() &&
      this.validateEmployer() &&
      this.validateJobTitle() &&
      this.validateDates() &&
      this.validateLocations()
    );
  };

  validatePosition = () => {
    this.setState({ positionError: false });
  };

  changePostion = (value) => {
    if (value !== '') {
      this.setState({ positionError: true });
    } else {
      this.setState({ positionError: false });
    }
  };

  validateSubpos = () => {
    this.setState({ subposError: false });
  };

  changeSubpos = (value) => {
    if (value !== '') {
      this.setState({ subposError: true });
    } else {
      this.setState({ subposError: false });
    }
  };

  render() {
    const {
      props: {
        title,
        edit = false,
        isSubPosition,
        candidateUrl,
        nextStep,
        backToEditPosition,
        subtitle,
        fieldValues,
        onInputFieldChange,
        handleCityChange,
        onRichEditorChange,
        onRemove = () => {},
        onSubmit = () => {},
      },
      state: { positionError, subposError },
      validate,
      validateEmpTypePos,
      validateType,
      validateEmployer,
      validateJobTitle,
      validateDates,
      validatePosition,
      changePostion,
      validateSubpos,
      changeSubpos,
    } = this;

    return (
      <div className="panel panel-default">
        <div className="panel-heading">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12">
              <div className="text-large">
                {isSubPosition
                  ? `${edit ? 'Edit' : 'Add'} Agency Assignment`
                  : title}
                {isSubPosition && <div>{subtitle}</div>}
              </div>
            </div>
          </div>
        </div>
        <div className="panel-body">
          <form onSubmit={onSubmit}>
            {!edit && (
              <div className="row">
                <div className="col-xs-12 col-sm-8 col-md-8">
                  {isSubPosition ? (
                    <div
                      className={`form-group ${
                        validateType() ? '' : 'has-error'
                      }`}
                    >
                      <label htmlFor="assignmentType">Assignment Type:</label>
                      <select
                        className="form-control"
                        id="assignmentType"
                        name="assignmentType"
                        value={fieldValues.assignmentType}
                        onChange={onInputFieldChange}
                        required
                      >
                        <option value="0">-</option>
                        <option value="1">PRN/Per Diem</option>
                        <option value="2">Locum</option>
                        <option value="3">Travel</option>
                      </select>
                    </div>
                  ) : (
                    <div
                      className={`form-group ${
                        validateType() ? '' : 'has-error'
                      }`}
                    >
                      <label htmlFor="employmentType">Employment Type:</label>
                      <select
                        className="form-control"
                        id="employmentType"
                        name="employmentType"
                        value={fieldValues.employmentType}
                        onChange={onInputFieldChange}
                        required
                      >
                        <option value="0">-</option>
                        <option value="1">Agency (Travel, Locum, PRN)</option>
                        <option value="2">Hospital Direct PRN/Per Diem</option>
                        <option value="3">Permanent Employee</option>
                        <option value="5">Other</option>
                      </select>
                    </div>
                  )}
                </div>
              </div>
            )}
            {edit && isSubPosition && (
              <div className="row">
                <div className="col-xs-12 col-sm-8 col-md-8">
                  <div
                    className={`form-group ${
                      validateType() ? '' : 'has-error'
                    }`}
                  >
                    <label htmlFor="assignmentType">Assignment Type:</label>
                    <select
                      className="form-control"
                      id="assignmentType"
                      name="assignmentType"
                      value={fieldValues.assignmentType}
                      onChange={onInputFieldChange}
                      required
                    >
                      <option value="0">-</option>
                      <option value="1">PRN/Per Diem</option>
                      <option value="2">Locum</option>
                      <option value="3">Travel</option>
                    </select>
                  </div>
                </div>
              </div>
            )}
            {(fieldValues.employmentType || isSubPosition) && (
              <div>
                {isSubPosition ? (
                  <div>
                    <div className="row">
                      <div className="col-xs-12 col-sm-8 col-md-8">
                        <TextField
                          label="Facility"
                          name="employer"
                          id="employer"
                          value={fieldValues.employer}
                          onChange={onInputFieldChange}
                          validate={validateEmployer}
                        />
                      </div>
                    </div>
                    <LocationInformation
                      onChange={onInputFieldChange}
                      handleCityChange={handleCityChange}
                      addressLine1={fieldValues.address.addressLine1}
                      addressLine2={fieldValues.address.addressLine2}
                      region={fieldValues.address.region}
                      city={fieldValues.address.city}
                      postalCode={fieldValues.address.postalCode}
                      country={fieldValues.address.country}
                      validateCity={validateSubpos}
                      onCityChange={(value) => changeSubpos(value)}
                      hasError={subposError}
                    />
                  </div>
                ) : (
                  <div className="ml-15">
                    <div className="row">
                      {parseInt(fieldValues.employmentType, 10) !== 1 ? (
                        <div className="row">
                          <div className="col-xs-12 col-sm-8 col-md-8">
                            <TextField
                              label="Employer"
                              name="employer"
                              id="employer"
                              value={fieldValues.employer}
                              onChange={onInputFieldChange}
                              validate={validateEmployer}
                            />
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="row">
                            <div className="col-xs-12 col-sm-8 col-md-8">
                              <p>
                                Please enter the agency here. You will have an
                                opportunity to add hospital assignments for this
                                agency later.
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-xs-12 col-sm-8 col-md-8">
                              <TextField
                                label="Agency"
                                name="employer"
                                id="employer"
                                value={fieldValues.employer}
                                onChange={onInputFieldChange}
                                validate={validateEmployer}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      <LocationInformation
                        onChange={onInputFieldChange}
                        handleCityChange={handleCityChange}
                        addressLine1={fieldValues.address.addressLine1}
                        addressLine2={fieldValues.address.addressLine2}
                        region={fieldValues.address.region}
                        city={fieldValues.address.city}
                        postalCode={fieldValues.address.postalCode}
                        country={fieldValues.address.country}
                        validateCity={validatePosition}
                        onCityChange={(value) => changePostion(value)}
                        hasError={positionError}
                      />
                    </div>
                  </div>
                )}

                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-8">
                    <TextField
                      label="Main Phone"
                      name="phone"
                      id="phone"
                      value={fieldValues.phone}
                      onChange={onInputFieldChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-8">
                    <TextField
                      label="Alt Phone"
                      name="phone2"
                      id="phone2"
                      value={fieldValues.phone2}
                      onChange={onInputFieldChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12 col-sm-8 col-md-8">
                    {isSubPosition ? (
                      <div
                        className={`form-group ${
                          this.validateEmpTypeSub() ? '' : 'has-error'
                        }`}
                      >
                        <label htmlFor="facilityType">Facility Type:</label>
                        <select
                          className="form-control"
                          id="facilityType"
                          name="facilityType"
                          value={fieldValues.facilityType}
                          onChange={onInputFieldChange}
                        >
                          <option value="0"> -</option>
                          <option value="1">Acute Care Hospital</option>
                          <option value="19">Addiction Treatment Center</option>
                          <option value="23">Adult Day Care Center</option>
                          <option value="9">Ambulatory Surgery Center</option>
                          <option value="13">
                            Assisted-Care Living Facility
                          </option>
                          <option value="12">Birthing Center</option>
                          <option value="2">Children&apos;s Hospital</option>
                          <option value="20">Clinic</option>
                          <option value="4">Correctional Facility</option>
                          <option value="14">Dialysis Center</option>
                          <option value="3">Home Health Company</option>
                          <option value="18">Home Health Provider</option>
                          <option value="26">Insurance Company</option>
                          <option value="24">Legal Consulting</option>
                          <option value="27">Other</option>
                          <option value="15">
                            Outpatient Diagnostic Center
                          </option>
                          <option value="16">Private Practice</option>
                          <option value="5">Psychiatric Facility</option>
                          <option value="25">Public Health Department</option>
                          <option value="6">Rehabilitation Center</option>
                          <option value="11">Residential Hospice</option>
                          <option value="22">School System</option>
                          <option value="7">
                            Senior Nursing Facility/Nursing Home
                          </option>
                          <option value="21">Surgery Center</option>
                          <option value="10">Women&apos;s Health Center</option>
                          <option value="17">Women&apos;s Hospital</option>
                        </select>
                      </div>
                    ) : (
                      <div>
                        {parseInt(fieldValues.employmentType, 10) !== 1 && (
                          <div
                            className={`form-group ${
                              validateEmpTypePos() ? '' : 'has-error'
                            }`}
                          >
                            <label htmlFor="facilityType">Employer Type:</label>
                            <select
                              className="form-control"
                              id="facilityType"
                              name="facilityType"
                              value={fieldValues.facilityType}
                              onChange={onInputFieldChange}
                            >
                              <option value="0"> -</option>
                              <option value="1">Acute Care Hospital</option>
                              <option value="19">
                                Addiction Treatment Center
                              </option>
                              <option value="23">Adult Day Care Center</option>
                              <option value="9">
                                Ambulatory Surgery Center
                              </option>
                              <option value="13">
                                Assisted-Care Living Facility
                              </option>
                              <option value="12">Birthing Center</option>
                              <option value="2">
                                Children&apos;s Hospital
                              </option>
                              <option value="20">Clinic</option>
                              <option value="4">Correctional Facility</option>
                              <option value="14">Dialysis Center</option>
                              <option value="3">Home Health Company</option>
                              <option value="18">Home Health Provider</option>
                              <option value="26">Insurance Company</option>
                              <option value="24">Legal Consulting</option>
                              <option value="27">Other</option>
                              <option value="15">
                                Outpatient Diagnostic Center
                              </option>
                              <option value="16">Private Practice</option>
                              <option value="5">Psychiatric Facility</option>
                              <option value="25">
                                Public Health Department
                              </option>
                              <option value="6">Rehabilitation Center</option>
                              <option value="11">Residential Hospice</option>
                              <option value="22">School System</option>
                              <option value="7">
                                Senior Nursing Facility/Nursing Home
                              </option>
                              <option value="21">Surgery Center</option>
                              <option value="10">
                                Women&apos;s Health Center
                              </option>
                              <option value="17">Women&apos;s Hospital</option>
                            </select>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12 col-sm-10 col-md-10">
                    <label htmlFor="form-group">Time Period:</label>
                    <div
                      className={`form-group ${
                        validateDates() ? '' : 'has-error'
                      }`}
                    >
                      <div className="row">
                        <div className="col-sm-7 col-md-8">
                          <label htmlFor="isCurrent">
                            <input
                              className="mt-10"
                              type="checkbox"
                              name="isCurrent"
                              checked={fieldValues.isCurrent}
                              onChange={onInputFieldChange}
                            />
                            <span className="ml-5">I currently work here</span>
                          </label>
                        </div>
                      </div>
                      <div className="row">
                        <div
                          className="col-xs-12 col-sm-4 col-md-4"
                          id="monthFrom"
                        >
                          <select
                            className="form-control"
                            name="monthFrom"
                            value={fieldValues.monthFrom}
                            onChange={onInputFieldChange}
                          >
                            <option value="">Month</option>
                            <option value="01">Jan</option>
                            <option value="02">Feb</option>
                            <option value="03">Mar</option>
                            <option value="04">Apr</option>
                            <option value="05">May</option>
                            <option value="06">Jun</option>
                            <option value="07">Jul</option>
                            <option value="08">Aug</option>
                            <option value="09">Sept</option>
                            <option value="10">Oct</option>
                            <option value="11">Nov</option>
                            <option value="12">Dec</option>
                          </select>
                        </div>
                        <div className="col-xs-12 col-sm-4 col-md-4">
                          <select
                            className="form-control"
                            id="dayFrom"
                            name="dayFrom"
                            value={fieldValues.dayFrom}
                            onChange={onInputFieldChange}
                          >
                            <option value="">Day</option>
                            <option value="01">01</option>
                            <option value="02">02</option>
                            <option value="03">03</option>
                            <option value="04">04</option>
                            <option value="05">05</option>
                            <option value="06">06</option>
                            <option value="07">07</option>
                            <option value="08">08</option>
                            <option value="09">09</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                            <option value="13">13</option>
                            <option value="14">14</option>
                            <option value="15">15</option>
                            <option value="16">16</option>
                            <option value="17">17</option>
                            <option value="18">18</option>
                            <option value="19">19</option>
                            <option value="20">20</option>
                            <option value="21">21</option>
                            <option value="22">22</option>
                            <option value="23">23</option>
                            <option value="24">24</option>
                            <option value="25">25</option>
                            <option value="26">26</option>
                            <option value="27">27</option>
                            <option value="28">28</option>
                            <option value="29">29</option>
                            <option value="30">30</option>
                            <option value="31">31</option>
                          </select>
                        </div>
                        <div className="col-xs-12 col-sm-4 col-md-3">
                          <input
                            type="text"
                            id="yearFrom"
                            name="yearFrom"
                            className="form-control input-sm"
                            size="4"
                            placeholder="YYYY"
                            maxLength="4"
                            value={fieldValues.yearFrom}
                            onChange={onInputFieldChange}
                          />
                        </div>
                      </div>
                      {!fieldValues.isCurrent && (
                        <div>
                          <p className="mt-5">
                            <strong>To:</strong>
                          </p>
                          <div className="row">
                            <div className="col-xs-12 col-sm-4 col-md-4">
                              <select
                                className="form-control mt-3"
                                id="monthTo"
                                name="monthTo"
                                value={fieldValues.monthTo}
                                onChange={onInputFieldChange}
                              >
                                <option value="">Month</option>
                                <option value="01">Jan</option>
                                <option value="02">Feb</option>
                                <option value="03">Mar</option>
                                <option value="04">Apr</option>
                                <option value="05">May</option>
                                <option value="06">Jun</option>
                                <option value="07">Jul</option>
                                <option value="08">Aug</option>
                                <option value="09">Sept</option>
                                <option value="10">Oct</option>
                                <option value="11">Nov</option>
                                <option value="12">Dec</option>
                              </select>
                            </div>
                            <div className="col-xs-12 col-sm-4 col-md-4">
                              <select
                                className="form-control mt-3"
                                id="dayTo"
                                name="dayTo"
                                value={fieldValues.dayTo}
                                onChange={onInputFieldChange}
                              >
                                <option value="">Day</option>
                                <option value="01">01</option>
                                <option value="02">02</option>
                                <option value="03">03</option>
                                <option value="04">04</option>
                                <option value="05">05</option>
                                <option value="06">06</option>
                                <option value="07">07</option>
                                <option value="08">08</option>
                                <option value="09">09</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                                <option value="13">13</option>
                                <option value="14">14</option>
                                <option value="15">15</option>
                                <option value="16">16</option>
                                <option value="17">17</option>
                                <option value="18">18</option>
                                <option value="19">19</option>
                                <option value="20">20</option>
                                <option value="21">21</option>
                                <option value="22">22</option>
                                <option value="23">23</option>
                                <option value="24">24</option>
                                <option value="25">25</option>
                                <option value="26">26</option>
                                <option value="27">27</option>
                                <option value="28">28</option>
                                <option value="29">29</option>
                                <option value="30">30</option>
                                <option value="31">31</option>
                              </select>
                            </div>
                            <div className="col-xs-12 col-sm-4 col-md-3">
                              <input
                                type="text"
                                id="yearTo"
                                name="yearTo"
                                className="form-control input-sm"
                                size="4"
                                placeholder="YYYY"
                                maxLength="4"
                                value={fieldValues.yearTo}
                                onChange={onInputFieldChange}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    {isSubPosition &&
                      parseInt(fieldValues.assignmentType, 10) === 1 && (
                        <div className="row">
                          <div className="col-xs-12 col-sm-8 col-md-8">
                            <label htmlFor="job-dates">Shifts Per Month:</label>
                            <div className="form-group has-feedback">
                              <input
                                className="phone"
                                type="text"
                                name="shiftsPerMonth"
                                id="shiftsPerMonth"
                                maxLength="2"
                                size="2"
                                value={
                                  fieldValues.shiftsPerMonth === 0
                                    ? ''
                                    : fieldValues.shiftsPerMonth
                                }
                                onChange={onInputFieldChange}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-8">
                <TextField
                  label="Job Title"
                  name="title"
                  id="title"
                  value={fieldValues.title}
                  onChange={onInputFieldChange}
                  validate={validateJobTitle}
                />
              </div>
            </div>
            {parseInt(fieldValues.employmentType, 10) === 1 &&
              !isSubPosition && (
                <div className="col-xs-12 col-sm-8 col-md-8">
                  <div className="form-group">
                    <div className="hint">
                      Note: You will have the opportunity to add agency
                      assignments later in the process.
                    </div>
                  </div>
                </div>
              )}
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-8">
                <RichEditorField
                  id="description"
                  name="description"
                  label="Job Details/Duties"
                  onChange={onRichEditorChange}
                  content={fieldValues.description}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-8">
                <div className="form-group">
                  <label htmlFor="reasonForLeaving">Reason for Leaving:</label>
                  <select
                    className="form-control"
                    id="reasonForLeaving"
                    name="reasonForLeaving"
                    value={fieldValues.reasonForLeaving}
                    onChange={onInputFieldChange}
                  >
                    <option value=" - "> - </option>
                    <option value="Accepted a New Position">
                      Accepted a New Position
                    </option>
                    <option value="Decline to State">Decline to State</option>
                    <option value="PRN/AGENCY">PRN/AGENCY</option>
                    <option value="Resigned">Resigned</option>
                    <option value="Still Employed">Still Employed</option>
                    <option value="Terminated">Terminated</option>
                    <option value="Travel Contract Complete">
                      Travel Contract Complete
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div className="add-edit-btn">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 text-center">
                  <div className="form-group mt-20">
                    {!edit && isSubPosition && (
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          backToEditPosition();
                        }}
                        className="btn btn-default mt-5 mb-3"
                      >
                        Back to References
                      </button>
                    )}
                    <button
                      onClick={(e) => nextStep(fieldValues, e)}
                      className="btn btn-primary mt-5 mb-3"
                      disabled={!validate()}
                    >
                      Next
                    </button>
                    <Link
                      to={`/${candidateUrl}/profile`}
                      className="btn btn-warning mt-5 mb-3"
                    >
                      Cancel
                    </Link>
                    {edit && (
                      <button
                        className="btn btn-danger mt-5 mb-3"
                        onClick={onRemove}
                      >
                        Remove
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default ExperienceFormStep1;
