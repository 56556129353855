import React from 'react';
import { Helmet } from 'react-helmet';
import UpdateBlock from './UpdateBlock/UpdateBlock';
import { ACCESS_LVL } from '../../../constants';

const Updates = ({ updates, name, access }) => (
  <div>
    <Helmet>
      <title>{`${name} Updates`}</title>
    </Helmet>
    {updates &&
      access === ACCESS_LVL.ACTIVE &&
      updates.map((update) => (
        <UpdateBlock
          timestamp={update.timestamp}
          key={update.timestamp}
          actionType={update.actionType}
          title={update.key}
          current={update.current}
          update={update.update}
          access={access}
        />
      ))}
  </div>
);

export default Updates;
