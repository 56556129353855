import jwt from 'jsonwebtoken';
import moment from 'moment';
import _ from 'lodash';
import { READ_COOKIE, NOT_AUTHORIZED } from '.';
import { DOMAIN, ACCESS_LVL } from '../constants';

const readCookie = (data) => ({
  type: READ_COOKIE,
  data,
});

const notAuthorized = (message) => ({
  type: NOT_AUTHORIZED,
  message,
});

export default (token, companyId) => (dispatch) => {
  // login page when empty jwt is causing infinite loop

  // if (!token) {
  //   const ref = encodeURI(window.location.href);
  //   window.location.assign(`${DOMAIN}login?ref=${ref}`);
  //   return;
  // }

  if (!token) {
    window.location.assign(`${DOMAIN}profile/edit`);
    return;
  }

  try {
    const decoded = jwt.decode(token);
    const { user, companies, exp } = decoded;
    if (exp < Math.round(+new Date() / 1000)) {
      const ref = encodeURI(window.location.href);
      window.location.assign(`${DOMAIN}login?ref=${ref}`);
      return;
    }
    const company = companyId
      ? companies[companyId]
      : companies[Object.keys(companies)[0]];
    const subscription = company.subscriptions.find(
      (sub) => sub.type === 'Candidates',
    );
    const { activated } = company;
    const start = moment(subscription.start).isSameOrBefore();
    const expire = moment(subscription.expiration).isAfter();
    let jobsAccess = null;

    const jobsSubscription = company.subscriptions.find(
      (sub) => sub.type === 'Jobs',
    );
    if (jobsSubscription) {
      jobsAccess =
        moment(jobsSubscription.start).isSameOrBefore() &&
        moment(jobsSubscription.expiration).isAfter()
          ? ACCESS_LVL.ACTIVE
          : ACCESS_LVL.EXPIRED;
    } else {
      jobsAccess = ACCESS_LVL.NO_ACCESS;
    }

    const res = {
      companyId: company.id,
      userName: `${user.first_name} ${user.last_name}`,
      userEmail: user.email,
      companyName: company.name,
      phone: _.get(company, 'address.phone', null),
      access:
        start && expire && activated ? ACCESS_LVL.ACTIVE : ACCESS_LVL.EXPIRED,
      token,
      jobsAccess,
    };
    dispatch(readCookie(res));
  } catch (e) {
    dispatch(notAuthorized(e));
  }
};
