import React from 'react';

const CheckboxField = ({
  id,
  label,
  value,
  name,
  checked,
  onChange = () => {},
}) => (
  <div>
    <input
      id={id}
      type="checkbox"
      name={name}
      value={value}
      checked={checked}
      onChange={onChange}
    />{' '}
    {label}
  </div>
);

export default CheckboxField;
