import React from 'react';

class RemoveButton extends React.Component {
  onRemove = (event) => {
    event.preventDefault();
    const { removeTag, id } = this.props;
    removeTag(id);
  };

  render() {
    const {
      onRemove,
      props: { label },
    } = this;

    return (
      <div className="tag">
        {label}
        <span
          role="button"
          className="delete ml-5"
          onClick={onRemove}
          onKeyPress={() => {}}
          tabIndex="0"
        >
          [x]
        </span>
      </div>
    );
  }
}
export default RemoveButton;
