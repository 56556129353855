import React from 'react';
import { connect } from 'react-redux';
import { update } from '../../../../actions/candidates';
import { history } from '../../../../store';
import { getCandidateData } from '../../../../selectors';
import ComputerSkillForm from '../../../../components/Candidate/Profile/ComputerSkills/ComputerSkillForm';
import { LOG_MESSAGES } from '../../../../constants';

class AddComputerSkill extends React.Component {
  onSubmit = (state) => {
    const { candidate, candidateUrl } = this.props;
    candidate.addComputerSkill(state);
    this.props.update(LOG_MESSAGES.COMPUTER_SKILL_INFORMATION_MODIFIED);
    history.push(`/${candidateUrl}/profile`);
  };

  render() {
    const {
      onSubmit,
      props: { candidateUrl },
    } = this;

    return (
      <ComputerSkillFormComponent
        key={candidateUrl}
        title="Add Computer Skill"
        candidateUrl={candidateUrl}
        onSubmit={onSubmit}
      />
    );
  }
}

class ComputerSkillFormComponent extends ComputerSkillForm {
  onSubmit = (e) => {
    const { state, props } = this;
    e.preventDefault();
    props.onSubmit(state);
  };
}

const mapStateToProps = (state) => ({
  candidateUrl: state.globalInfo.candidateUrl,
  candidate: getCandidateData(state),
});

export default connect(mapStateToProps, { update })(AddComputerSkill);
