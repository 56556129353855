import React from 'react';
import { connect } from 'react-redux';
import Documents from '../../components/Candidate/Documents';
import { update } from '../../actions/candidates';
import generatePDF from '../../actions/generatePDF';
import requestUpdate from '../../actions/requestUpdate';
import fetchPdfSettings from '../../actions/getPdfSettings';
import NotFound from '../../components/NotFound/NotFound';
import Loading from '../../components/Loading';
import { getCandidateData, getPdfSettings } from '../../selectors';

class DocumentsContainer extends React.Component {
  componentDidMount() {
    this.props.fetchPdfSettings();
  }

  handleDeleteLetter = (id) => {
    this.props.candidate.documents.deleteLetter(id);
    this.props.update();
  };

  handleDeleteReference = (id) => {
    this.props.candidate.documents.deleteReferenceCheck(id);
    this.props.update();
  };

  handleDeleteFile = (id) => {
    this.props.candidate.documents.deleteFile(id);
    this.props.update();
  };

  render() {
    const {
      candidate,
      isFetching,
      update,
      user,
      requestUpdate,
      generatePDF,
      access,
      settings,
    } = this.props;

    if (isFetching) {
      return <Loading />;
    }
    if (candidate.documents === {}) {
      return <NotFound name={candidate.getName()} page="Documents" />;
    }

    return (
      <Documents
        key={candidate.url}
        documents={candidate.documents}
        connected={candidate.connected}
        name={candidate.getName()}
        checklists={candidate.checklists}
        update={update}
        user={user}
        handleDeleteLetter={this.handleDeleteLetter}
        handleDeleteReference={this.handleDeleteReference}
        deleteChecklist={candidate.deleteChecklist}
        requestUpdate={requestUpdate}
        handleDeleteFile={this.handleDeleteFile}
        generatePDF={generatePDF}
        access={access}
        branding={settings.branding}
        color={settings.color}
        fontLight={settings.fontLight}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  candidate: getCandidateData(state),
  user: state.globalInfo.userName,
  access: state.globalInfo.access,
  branding: state.globalInfo.branding,
  settings: getPdfSettings(state),
});

export default connect(mapStateToProps, {
  update,
  requestUpdate,
  generatePDF,
  fetchPdfSettings,
})(DocumentsContainer);
