import React from 'react';
import Modal from '../../../Modal';

class ModalForRow extends Modal.Manager {
  render() {
    const {
      handleClose,
      handleShow,
      state: { showModal, visible },
      props: { note },
    } = this;
    const modalComponent = showModal ? (
      <ModalComponent visible={visible} handleClose={handleClose} note={note} />
    ) : (
      ''
    );

    return (
      <span>
        <button
          type="button"
          className="btn btn-primary btn-xs"
          onClick={handleShow}
        >
          View
        </button>
        {modalComponent}
      </span>
    );
  }
}

const ModalComponent = ({ visible, handleClose, note }) => (
  <Modal visible={visible} hideComponent={handleClose}>
    <Modal.Header title="Message" hideComponent={handleClose} />
    <Modal.Body>
      <div>{note}</div>
    </Modal.Body>
    <Modal.Footer>
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 text-center">
          <button
            type="button"
            className="btn btn-default"
            onClick={handleClose}
          >
            <span className="glyphicon glyphicon-remove-circle" /> Close
          </button>
        </div>
      </div>
    </Modal.Footer>
  </Modal>
);

export default ModalForRow;
