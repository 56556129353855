import React from 'react';
import moment from 'moment';
import FormWrapper from '../../../../FormWrapper';
import { TextField } from '../../../../FormFields';
import { MOMENTS_DATES_FORMATS } from '../../../../../constants';

class ExperienceFormStep2 extends FormWrapper {
  constructor() {
    super();
    window.scrollTo(0, 0);
  }

  render() {
    const {
      props: {
        title,
        edit = false,
        fieldValues,
        nextStep,
        previousStep,
        onInputFieldChange,
        onSubmit = () => {},
        onRemove = () => {},
      },
    } = this;

    return (
      <div className="panel panel-default">
        <div className="panel-heading">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12">
              <div className="text-large">{title}</div>
              <div className="text-large">{`${fieldValues.employer}: ${moment(
                fieldValues.startDate,
                MOMENTS_DATES_FORMATS,
              ).format('MM-DD-YYYY')} - ${
                fieldValues.isCurrent
                  ? 'Present'
                  : moment(fieldValues.endDate, MOMENTS_DATES_FORMATS).format(
                      'MM-DD-YYYY',
                    )
              }`}</div>
            </div>
          </div>
        </div>
        <div className="panel-body">
          <form onSubmit={onSubmit}>
            <label htmlFor="Teaching">Teaching Facility?:</label>
            <div className="row">
              <div className="col-xs-12 col-sm-8 col-md-8">
                <div className="form-group">
                  <label className="radio-inline">
                    <input
                      type="radio"
                      name="facilityIsTeaching"
                      id="inlineRadio1"
                      value
                      checked={fieldValues.facilityIsTeaching}
                      onChange={onInputFieldChange}
                    />
                    Yes
                  </label>
                  <label className="radio-inline">
                    <input
                      type="radio"
                      name="facilityIsTeaching"
                      id="inlineRadio2"
                      value={false}
                      checked={fieldValues.facilityIsTeaching === false}
                      onChange={onInputFieldChange}
                    />
                    No
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-8 col-md-8">
                <div className="form-group">
                  <label htmlFor="facilityTraumaLevel">Trauma Level:</label>
                  <select
                    className="form-control"
                    id="facilityTraumaLevel"
                    name="facilityTraumaLevel"
                    value={fieldValues.facilityTraumaLevel}
                    onChange={onInputFieldChange}
                  >
                    <option value="0"> - </option>
                    <option value="255">N/A</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-8 col-md-8">
                <div className="form-group">
                  <label htmlFor="facilityNumBeds">Number of Beds:</label>
                  <select
                    className="form-control"
                    id="facilityNumBeds"
                    name="facilityNumBeds"
                    value={fieldValues.facilityNumBeds}
                    onChange={onInputFieldChange}
                  >
                    <option value="0"> - </option>
                    <option value="50">50 or less</option>
                    <option value="100">100 - 149</option>
                    <option value="150">150 - 199</option>
                    <option value="200">200 - 249</option>
                    <option value="250">250 - 299</option>
                    <option value="300">300 - 349</option>
                    <option value="350">350 - 399</option>
                    <option value="400">400 - 449</option>
                    <option value="450">450 - 499</option>
                    <option value="500">500 - 549</option>
                    <option value="550">550+</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-8 col-md-8">
                <div className="form-group">
                  <label htmlFor="facilityUnitId">Unit:</label>
                  <select
                    className="form-control"
                    id="facilityUnitId"
                    name="facilityUnitId"
                    value={fieldValues.facilityUnitId}
                    onChange={onInputFieldChange}
                  >
                    <option value="0"> - </option>
                    <option value="1">Admin</option>
                    <option value="2">Ambulatory Care</option>
                    <option value="3">Ambulatory PACU</option>
                    <option value="4">Ambulatory Pre-Post Op</option>
                    <option value="5">Bone Marrow</option>
                    <option value="6">Burn</option>
                    <option value="7">Burn ICU</option>
                    <option value="8">Cardiac</option>
                    <option value="9">Cardiac Cath Lab</option>
                    <option value="10">Cardiac SDU</option>
                    <option value="11">Case Manager</option>
                    <option value="12">Cath Lab</option>
                    <option value="13">CCU</option>
                    <option value="14">Clinic</option>
                    <option value="15">Corrections</option>
                    <option value="16">CSU</option>
                    <option value="17">CVICU</option>
                    <option value="18">CVOR</option>
                    <option value="19">Dermatology</option>
                    <option value="20">Dialysis</option>
                    <option value="21">Director</option>
                    <option value="22">DOU</option>
                    <option value="23">Ear Nose &amp; Throat</option>
                    <option value="24">ED</option>
                    <option value="25">Employee Health</option>
                    <option value="26">ENDO/GI LAB</option>
                    <option value="27">Endocrinology</option>
                    <option value="28">Endoscopy</option>
                    <option value="29">EP/Electrophysiology</option>
                    <option value="30">ER</option>
                    <option value="31">Family Care</option>
                    <option value="32">Family Practice</option>
                    <option value="33">FCC</option>
                    <option value="34">Gastroenterology</option>
                    <option value="35">General Practice</option>
                    <option value="36">Geriatrics</option>
                    <option value="37">GI Lab</option>
                    <option value="38">Home Health</option>
                    <option value="39">Home Health Hospice</option>
                    <option value="40">Home Health Oncology</option>
                    <option value="41">Home Health PEDS</option>
                    <option value="42">Home Health Psych</option>
                    <option value="43">Hospice</option>
                    <option value="44">Hospitalist</option>
                    <option value="45">House Supervisor</option>
                    <option value="46">ICU</option>
                    <option value="47">Infection Control</option>
                    <option value="48">Internal Medicine</option>
                    <option value="49">Interventional Radiology</option>
                    <option value="50">L&amp;D</option>
                    <option value="51">Level 1 Nursery</option>
                    <option value="52">Long Term Acute Care</option>
                    <option value="53">LPN</option>
                    <option value="54">LTC</option>
                    <option value="55">LVN</option>
                    <option value="56">Mental Health</option>
                    <option value="57">MICU</option>
                    <option value="58">Midwife</option>
                    <option value="59">Monitor Tech</option>
                    <option value="60">Mother Baby</option>
                    <option value="103">N/A</option>
                    <option value="64">Neonatal ICU</option>
                    <option value="61">Nephrology</option>
                    <option value="62">Neuro</option>
                    <option value="63">Neuro ICU</option>
                    <option value="65">Nurse Department Manager</option>
                    <option value="66">Nurse Educator</option>
                    <option value="67">Nursing Assistant</option>
                    <option value="68">Nursing Supervisor</option>
                    <option value="69">OB/GYN</option>
                    <option value="70">Occ Health</option>
                    <option value="71">Oncology</option>
                    <option value="72">Ophthalmology</option>
                    <option value="73">OR</option>
                    <option value="74">Ortho</option>
                    <option value="75">Outpatient Clinic</option>
                    <option value="76">Outpatient Oncology</option>
                    <option value="77">PACU</option>
                    <option value="78">Palliative Care</option>
                    <option value="79">Patient Care Coordinator</option>
                    <option value="81">PCICU</option>
                    <option value="80">PCU</option>
                    <option value="82">Pediatrics</option>
                    <option value="83">PEDS</option>
                    <option value="84">Phlebotomist</option>
                    <option value="85">PICC</option>
                    <option value="86">PICU</option>
                    <option value="87">Postpartum</option>
                    <option value="88">Psych</option>
                    <option value="89">Rehab</option>
                    <option value="90">RNFA</option>
                    <option value="91">SDU</option>
                    <option value="92">SICU</option>
                    <option value="93">Sitter</option>
                    <option value="94">TCU</option>
                    <option value="95">TELE</option>
                    <option value="96">Telephone Triage</option>
                    <option value="97">Transplant</option>
                    <option value="98">Trauma Manager</option>
                    <option value="99">Triage</option>
                    <option value="100">Urology</option>
                    <option value="101">Utilization Review</option>
                    <option value="102">Wound Care</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-8 col-md-8">
                <div className="form-group">
                  <label htmlFor="facilityUnitLevel">Unit Trauma Level:</label>
                  <select
                    className="form-control"
                    id="facilityUnitLevel"
                    name="facilityUnitLevel"
                    value={fieldValues.facilityUnitLevel}
                    onChange={onInputFieldChange}
                  >
                    <option value="0"> - </option>
                    <option value="255">N/A</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-8 col-md-8">
                <div className="form-group">
                  <label htmlFor="facilityUnitBeds">Unit Number of Beds:</label>
                  <select
                    className="form-control"
                    id="facilityUnitBeds"
                    name="facilityUnitBeds"
                    value={fieldValues.facilityUnitBeds}
                    onChange={onInputFieldChange}
                  >
                    <option value="0"> - </option>
                    <option value="5">5 or less</option>
                    <option value="10">10 - 14</option>
                    <option value="15">15 - 19</option>
                    <option value="20">20 - 24</option>
                    <option value="25">25 - 29</option>
                    <option value="30">30 - 34</option>
                    <option value="35">35 - 39</option>
                    <option value="40">40 - 44</option>
                    <option value="45">45 - 49</option>
                    <option value="50">50+</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-8 col-md-8">
                <TextField
                  label="ChartingSystem"
                  id="chartingSystem"
                  name="chartingSystem"
                  value={fieldValues.chartingSystem}
                  onChange={onInputFieldChange}
                />
              </div>
            </div>
            <label htmlFor="patientRatio">Patient Ratio or Caseload:</label>
            <div className="row">
              <div className="col-xs-12 col-sm-8 col-md-8">
                <div className="form-group">
                  <div className="row">
                    <div className="col-xs-12 col-sm-4 col-md-4">
                      <select
                        className="form-control mt-3"
                        id="caseload"
                        name="caseload"
                        value={fieldValues.caseload}
                        onChange={onInputFieldChange}
                      >
                        <option value="0"> - </option>
                        <option value="255">N/A</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                        <option value="13">13</option>
                        <option value="14">14</option>
                        <option value="15">15</option>
                        <option value="16">16</option>
                        <option value="17">17</option>
                        <option value="18">18</option>
                        <option value="19">19</option>
                        <option value="20">20</option>
                        <option value="21">21</option>
                        <option value="22">22</option>
                        <option value="23">23</option>
                        <option value="24">24</option>
                        <option value="25">25</option>
                        <option value="26">26</option>
                        <option value="27">27</option>
                        <option value="28">28</option>
                        <option value="29">29</option>
                        <option value="30">30</option>
                        <option value="31">31</option>
                        <option value="32">32</option>
                        <option value="33">33</option>
                        <option value="34">34</option>
                        <option value="35">35</option>
                        <option value="36">36</option>
                        <option value="37">37</option>
                        <option value="38">38</option>
                        <option value="39">39</option>
                        <option value="40">40</option>
                        <option value="41">41</option>
                        <option value="42">42</option>
                        <option value="43">43</option>
                        <option value="44">44</option>
                        <option value="45">45</option>
                        <option value="46">46</option>
                        <option value="47">47</option>
                        <option value="48">48</option>
                        <option value="49">49</option>
                        <option value="50">50+</option>
                      </select>
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-3">
                      <p className="mt-7">patients</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="add-edit-btn">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 text-center">
                  <div className="form-group mt-20">
                    <button
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        previousStep(fieldValues);
                      }}
                      className="btn btn-default mt-5 mb-3"
                    >
                      Previous
                    </button>
                    <button
                      onClick={(e) => {
                        nextStep(fieldValues, e);
                      }}
                      className="btn btn-primary mt-5 mb-3"
                    >
                      Next
                    </button>
                    {edit && (
                      <button
                        className="btn btn-danger mt-5 mb-3"
                        onClick={onRemove}
                      >
                        Remove
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default ExperienceFormStep2;
