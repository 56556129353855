import React from 'react';
import ReactDOM from 'react-dom';

class Portal extends React.Component {
  render() {
    // React does *not* create a new div. It renders the children into `document.body`.
    return ReactDOM.createPortal(this.props.children, document.body);
  }
}

export default Portal;
