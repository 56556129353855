import React from 'react';
import UploadedDocumentsRow from './UploadedDocumentsRow/UploadedDocumentsRow';

const UploadedDocuments = ({
  files = [],
  documents,
  handleDeleteFile,
  onFileRawChange,
  onFilePdfChange,
  access,
}) => (
  <table className="table table-condensed">
    <thead>
      <tr>
        <th>Select for Download</th>
        <th />
      </tr>
    </thead>
    <tbody>
      {files &&
        files.map((file) => (
          <UploadedDocumentsRow
            key={file.fileId}
            onFileRawChange={onFileRawChange}
            onFilePdfChange={onFilePdfChange}
            id={file.fileId}
            name={file.title}
            createdDate={file.getCreatedDate()}
            notes={file.notes}
            content={file.content}
            onEdit={documents.editFile}
            deleteFile={handleDeleteFile}
            access={access}
          />
        ))}
    </tbody>
  </table>
);

export default UploadedDocuments;
