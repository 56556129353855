import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { update } from '../../../../actions/candidates';
import { history } from '../../../../store';
import LicenseOrCertificationForm from '../../../../components/Candidate/Profile/LicenseOrCertification/LicenseOrCertificationForm';
import fetchLicenses from '../../../../actions/licensesAndCertsOptions';
import { getCandidateData, getLicensesAndCerts } from '../../../../selectors';
import { LOG_MESSAGES } from '../../../../constants';

const AddLicenseOrCertification = ({
  candidateUrl,
  candidate,
  fetchLicenses,
  licenseCertOptions = {
    1: { items: [], bodies: [] },
    2: { items: [], bodies: [] },
    3: { items: [], bodies: [] },
  },
  update,
}) => (
  <LicenseOrCertificationFormComponent
    title="Add License or Certification"
    key={candidateUrl}
    candidateUrl={candidateUrl}
    autosuggestSource={licenseCertOptions}
    candidate={candidate}
    update={update}
    fetchLicenses={fetchLicenses}
  />
);

class LicenseOrCertificationFormComponent extends LicenseOrCertificationForm {
  constructor(props) {
    super(props);
    props.fetchLicenses(1);
    props.fetchLicenses(2);
    props.fetchLicenses(3);
  }

  onSubmit = (e) => {
    const {
      state,
      props: { candidate, candidateUrl },
    } = this;
    e.preventDefault();
    const license = _.omit(state, [
      'showLicCertCustomForm',
      'showLicCertBodyCustomForm',
    ]);
    candidate.addLicenseCerts(license);
    this.props.update(LOG_MESSAGES.LIC_AND_CERTS_INFORMATION_MODIFIED);
    history.push(`/${candidateUrl}/profile`);
  };
}

const mapStateToProps = (state) => ({
  candidateUrl: state.globalInfo.candidateUrl,
  candidate: getCandidateData(state),
  licenseCertOptions: getLicensesAndCerts(state),
});

export default connect(mapStateToProps, { update, fetchLicenses })(
  AddLicenseOrCertification,
);
