import React from 'react';
import { Link } from 'react-router-dom';
import { ACCESS_LVL } from '../../../../constants';

const AddEditDropdown = ({ label, links, access }) => (
  <div className="pull-right">
    <div className="btn-group">
      <button
        type="button"
        className="btn btn-primary btn-dropdown btn-xs dropdown-toggle"
        data-toggle="dropdown"
        disabled={access === ACCESS_LVL.EXPIRED}
      >
        {label} <span className="caret" />
      </button>
      <ul className="dropdown-menu" role="menu">
        {links.map(({ url, label: linkLabel }) => (
          <li key={`${linkLabel}-${url}`}>
            <Link to={url}>{linkLabel}</Link>
          </li>
        ))}
      </ul>
    </div>
  </div>
);

export default AddEditDropdown;
