import React from 'react';
import { DOMAIN, ACCESS_LVL } from '../../../constants';

const SendMessage = ({ legacyUrl, access }) => (
  <span>
    <a
      type="button"
      className="btn btn-primary"
      target="_blank"
      disabled={access === ACCESS_LVL.EXPIRED}
      href={`${DOMAIN}${legacyUrl}`}
      rel="noopener noreferrer"
    >
      <span className="glyphicon glyphicon-envelope" /> Message
    </a>
  </span>
);

export default SendMessage;
