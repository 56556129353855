/* eslint-disable no-unused-expressions */
import React from 'react';
import FormWrapper from '../../../../FormWrapper';
import ExperienceFormStep1 from './ExperienceFormStep1';
import ExperienceFormStep2 from './ExperienceFormStep2';
import ExperienceFormStep3 from './ExperienceFormStep3';
import SupervisorForm from './SupervisorForm';
import References from './References';
import NotFound from '../../../../NotFound/NotFound';
import { Position } from '../../../../../models/Candidate';

class ExperienceForm extends FormWrapper {
  constructor(props) {
    super(props);

    const position = new Position();
    this.state = {
      step: props.page,
      ...position,
    };

    window.scrollTo(0, 0);
  }

  referencesStep = (e, fields, id, shouldSave = true) => {
    e.preventDefault();

    shouldSave ? this.saveValues(fields) : null;
    this.setState({
      step: 5,
      editItem: id,
    });
  };

  thirdStep = (fields, shouldSave = true) => {
    shouldSave ? this.saveValues(fields) : null;

    this.setState({
      step: 3,
    });
  };

  saveValues = (fields) => {
    this.setState(fields);
    this.onPartialSubmit(fields);
  };

  saveSupervisor = (supervisor) => {
    this.setState(
      {
        supervisor,
        step: 3,
      },
      () => this.onPartialSubmit(this.state),
    );
  };

  saveReference = (reference, edit) => {
    let { references } = this.state;

    if (edit) {
      references = references.map((ref) =>
        ref.id === reference.id ? reference : ref,
      );
    } else {
      references.push(reference);
    }

    this.setState(
      {
        references,
        step: 3,
        editItem: null,
      },
      () => this.onPartialSubmit(this.state),
    );
  };

  shouldDisplayFacility(facilityType, isForward = true) {
    const toInt = parseInt(facilityType, 10);

    if (this.state.step === 3 && isForward) {
      this.setState({
        step: 4,
      });
      return;
    }
    if (this.state.step === 2 && !isForward) {
      this.setState({
        step: 1,
      });
    } else {
      let offset = isForward ? 2 : -2;

      if (toInt === 1 || toInt === 2 || toInt === 17) {
        offset = isForward ? 1 : -1;
      }

      this.setState({
        step: this.state.step + offset,
      });
    }
  }

  previousStep = (fields) => {
    this.saveValues(fields);
    return this.shouldDisplayFacility(fields.facilityType, false);
  };

  nextStep = (fields, e) => {
    if (e) {
      e.preventDefault();
    }
    this.saveValues(fields);
    return this.shouldDisplayFacility(fields.facilityType, true);
  };

  saveAgencyAndAddAssignment = (e, fields) => {
    if (e) {
      e.preventDefault();
    }
    this.saveValues(fields);
    this.props.onSubmit(this.state, true);
  };

  onRichEditorChange = (name, content) => {
    this.setState({ [name]: content });
  };

  removeReference = (e, reference) => {
    e.preventDefault();

    let { references } = this.state;

    references = references.filter((ref) => ref.id !== reference.id);

    this.setState(
      {
        references,
        step: 3,
        editItem: null,
      },
      () => this.onPartialSubmit(this.state),
    );
  };

  render() {
    const {
      onInputFieldChangeReferences,
      onInputFieldChange,
      handleCityChange,
      onSubmit = () => {},
      onRemove = () => {},
      props: {
        title1,
        title2,
        title3,
        title5,
        dates,
        edit = false,
        candidateUrl,
        isSubPosition,
      },
      nextStep,
      referencesStep,
      thirdStep,
      previousStep,
      saveValues,
      saveSupervisor,
      saveReference,
      shouldDisplayFacility,
      onRichEditorChange,
      removeReference,
      state,
    } = this;

    switch (state.step) {
      case 1:
        return (
          <ExperienceFormStep1
            fieldValues={state}
            onRichEditorChange={onRichEditorChange}
            shouldDisplayFacility={shouldDisplayFacility}
            isSubPosition={isSubPosition}
            title={title1}
            edit={edit}
            subtitle={dates}
            onInputFieldChange={onInputFieldChange}
            handleCityChange={handleCityChange}
            onRemove={onRemove}
            candidateUrl={candidateUrl}
            nextStep={nextStep}
            previousStep={previousStep}
            thirdStep={thirdStep}
            backToEditPosition={this.props.backToEditPosition}
          />
        );
      case 2:
        return (
          <ExperienceFormStep2
            fieldValues={state}
            title={title2}
            edit={edit}
            isSubPosition={isSubPosition}
            onInputFieldChange={onInputFieldChange}
            onRemove={onRemove}
            candidateUrl={candidateUrl}
            nextStep={nextStep}
            previousStep={previousStep}
            saveValues={saveValues}
          />
        );
      case 3:
        return (
          <ExperienceFormStep3
            fieldValues={state}
            title={title3}
            edit={edit}
            isSubPosition={isSubPosition}
            onInputFieldChange={onInputFieldChange}
            onSubmit={onSubmit}
            candidateUrl={candidateUrl}
            nextStep={nextStep}
            referencesStep={referencesStep}
            previousStep={previousStep}
            saveValues={saveValues}
            saveAgencyAndAddAssignment={this.saveAgencyAndAddAssignment}
          />
        );
      case 4:
        return (
          <SupervisorForm
            fieldValues={state}
            title={state.employer}
            edit={edit}
            isSubPosition={isSubPosition}
            candidateUrl={candidateUrl}
            onInputFieldChange={onInputFieldChange}
            onRemove={onRemove}
            thirdStep={thirdStep}
            saveValues={saveValues}
            saveSupervisor={saveSupervisor}
          />
        );
      case 5:
        return (
          <References
            fieldValues={state}
            id={state.editItem}
            title={title5}
            pageTitle={state.employer}
            isSubPosition={isSubPosition}
            candidateUrl={candidateUrl}
            onInputFieldChange={onInputFieldChangeReferences}
            onRemove={removeReference}
            thirdStep={thirdStep}
            saveValues={saveValues}
            saveReference={saveReference}
          />
        );
      default:
        return <NotFound />;
    }
  }
}

export default ExperienceForm;
