/* eslint-disable react/no-array-index-key */

import React from 'react';
import CheckboxField from '../../../FormFields/CheckboxField';

const desiredEmploymentTypesOptions = [
  [
    { id: 1, label: 'PRN/Per Diem' },
    { id: 2, label: 'Locum' },
    { id: 3, label: 'Travel' },
  ],
  [
    { id: 4, label: 'Hospital Direct PRN/Per Diem' },
    { id: 5, label: 'Permanent Employee' },
    { id: 6, label: 'Other' },
  ],
];

const EmploymentType = ({ checked, onChange }) => (
  <div className="row">
    <div className="col-xs-12 col-sm-12 col-md-12">
      <div className="form-group">
        <label htmlFor="employment-occupation">Employment type:</label>
        <div className="row">
          {desiredEmploymentTypesOptions.map((column, i) => (
            <div key={i} className="col-xs-12 col-sm-12 col-md-4">
              {column.map((shift) => (
                <div key={shift.id} className="checkbox">
                  <label>
                    <CheckboxField
                      label={shift.label}
                      value="1"
                      checked={checked.indexOf(shift.label) > -1}
                      name={shift.label}
                      onChange={onChange}
                    />
                  </label>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
);

export default EmploymentType;
