/* eslint-disable max-len */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { update } from '../../../../actions/candidates';
import { getCandidateData } from '../../../../selectors/index';
import { history } from '../../../../store';
import EditSummary from '../../../../components/Candidate/Profile/EditSummary/EditSummary';
import { LOG_MESSAGES } from '../../../../constants';

class SummaryContainer extends Component {
  onSubmit = (e) => {
    const { candidate, candidateUrl, update } = this.props;
    e.preventDefault();
    candidate.setProperty('summary', e.target.summary.value);
    update(LOG_MESSAGES.SUMMARY_INFORMATION_MODIFIED);
    history.push(`/${candidateUrl}/profile`);
  };

  render() {
    const { candidate, candidateUrl } = this.props;

    return (
      <EditSummary
        key={candidateUrl}
        summary={candidate.summary}
        candidateUrl={candidateUrl}
        onSubmit={this.onSubmit}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  candidate: getCandidateData(state),
  candidateUrl: state.globalInfo.candidateUrl,
});

export default connect(mapStateToProps, {
  update,
})(SummaryContainer);
