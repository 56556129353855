/* eslint-disable max-len */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import Log from '../../components/Candidate/Log';
import fetchLogs from '../../actions/logs';
import NotFound from '../../components/NotFound/NotFound';
import Loading from '../../components/Loading';
import {
  getLogs,
  getNumberOfLogs,
  getPage,
  getCandidateData,
} from '../../selectors/index';

class LogContainer extends Component {
  componentDidMount() {
    this.props.fetchLogs();
  }

  render() {
    const {
      logs,
      isFetching,
      numberOfLogs,
      name,
      page,
      match: { url },
    } = this.props;

    if (isFetching) {
      return <Loading />;
    }
    if (_.isEmpty(logs)) {
      return <NotFound name={name} page="Log" />;
    }

    return (
      <Log
        key={url}
        logs={logs}
        numberOfLogs={numberOfLogs}
        url={url}
        page={page}
        name={name}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  logs: getLogs(state),
  isFetching: state.logs.isFetching,
  numberOfLogs: getNumberOfLogs(state),
  page: getPage(state),
  name: getCandidateData(state).getName(),
});

export default connect(mapStateToProps, {
  fetchLogs,
})(LogContainer);
