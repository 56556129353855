import React from 'react';
import TimeOffModal from './Modal';

const TimeOff = ({ candidate, timeOff, update, access }) => (
  <div className="row">
    <div className="col-xs-12 col-sm-12 col-md-12">
      <div className="muted-text-xl mg-btm-sm">Requested Time Off</div>
      <TimeOffModal candidate={candidate} update={update} access={access} />
      <hr />
      <div className="table-responsive">
        <table className="table table-hover subTable">
          <thead>
            <tr>
              <th>Starting</th>
              <th>Ending</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {timeOff.map((time) => (
              <tr key={time.to + time.from}>
                <td className="vAlign">{time.from}</td>
                <td className="vAlign">{time.to}</td>
                <td className="vAlign">
                  <button
                    type="button"
                    className="btn btn-danger btn-xs"
                    aria-haspopup="true"
                    aria-expanded="false"
                    onClick={() => candidate.deleteTimeOff(time, update)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  </div>
);

export default TimeOff;
