import React from 'react';

const TextareaField = ({
  id,
  name,
  label,
  value,
  placeholder,
  onChange = () => {},
  rows = 3,
}) => (
  <div className="form-group">
    <label htmlFor="notes">{label}:</label>
    <textarea
      className="form-control"
      id={id}
      name={name}
      rows={rows}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
    />
  </div>
);

export default TextareaField;
