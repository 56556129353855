import React from 'react';

const experienceOptions = {
  '': '-',
  0: '0-1 year',
  1: '1-2 years',
  2: '2-3 years',
  3: '3-5 years',
  5: '5+ years',
};

const specialtiesOptions = {
  '-1': '-',
  0: 'Less than 1 year',
  1: '1 year',
  2: '2 years',
  3: '3 years',
  4: '4 years',
  5: '5 years',
  6: '6 years',
  7: '7 years',
  8: '8 years',
  9: '9 years',
  10: '10-15 years',
  15: '15-20 years',
  20: '20+ years',
};

const Skill = ({ name, yearsExperience, experience, acronym }) => (
  <h3 className="h3-details">
    {acronym ? <span>{acronym} - </span> : ''}
    {name}
    {typeof yearsExperience !== 'undefined' && (
      <span className="h3-more ml-5">
        ({specialtiesOptions[yearsExperience]})
      </span>
    )}
    {experience && (
      <span className="h3-more ml-5">({experienceOptions[experience]})</span>
    )}
  </h3>
);

export default Skill;
