import React from 'react';
import { Helmet } from 'react-helmet';
import SimpleTable from '../../SimpleTable';
import Pagination from '../../Pagination/Pagination';

const Notes = ({ notes, numberOfNotes, url, page, name }) => (
  <div className="panel panel-default">
    <Helmet>
      <title>{`${name} Notes`}</title>
    </Helmet>
    <div className="panel-heading">
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12">
          <div className="text-large">Notes</div>
        </div>
      </div>
    </div>
    <div className="panel-body">
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12">
          <SimpleTable
            columns={['Note', 'Author', 'Date Modified', 'Action']}
            body={notes}
            action
          />
        </div>
        <Pagination
          numberOfItems={numberOfNotes}
          currentPage={page}
          url={url}
        />
      </div>
    </div>
  </div>
);

export default Notes;
