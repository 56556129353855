export const FETCH_CANDIDATES = 'FETCH_CANDIDATES';
export const FETCH_CANDIDATES_SUCCESS = 'FETCH_CANDIDATES_SUCCESS';
export const FETCH_CANDIDATES_ERROR = 'FETCH_CANDIDATES_ERROR';

export const PUT_CANDIDATES_SUCCESS = 'PUT_CANDIDATES_SUCCESS';

export const FETCH_SPECIALTIES_OPTIONS = 'FETCH_SPECIALTIES_OPTIONS';
export const FETCH_SPECIALTIES_OPTIONS_SUCCESS =
  'FETCH_SPECIALTIES_OPTIONS_SUCCESS';
export const FETCH_SPECIALTIES_OPTIONS_ERROR =
  'FETCH_SPECIALTIES_OPTIONS_ERROR';

export const FETCH_LIC_CERTS_OPTIONS = 'FETCH_LIC_CERTS_OPTIONS';
export const FETCH_LIC_CERTS_OPTIONS_SUCCESS =
  'FETCH_LIC_CERTS_OPTIONS_SUCCESS';
export const FETCH_LIC_CERTS_OPTIONS_ERROR = 'FETCH_LIC_CERTS_OPTIONS_ERROR';

export const FETCH_NOTES = 'FETCH_NOTES';
export const FETCH_NOTES_SUCCESS = 'FETCH_NOTES_SUCCESS';
export const FETCH_NOTES_ERROR = 'FETCH_NOTES_ERROR';

export const FETCH_LOGS = 'FETCH_LOGS';
export const FETCH_LOGS_SUCCESS = 'FETCH_LOGS_SUCCESS';
export const FETCH_LOGS_ERROR = 'FETCH_LOGS_ERROR';
export const POST_LOG_SUCCESS = 'POST_LOG_SUCCESS';

export const SEARCH_CANDIDATES = 'SEARCH_CANDIDATES';
export const SEARCH_CANDIDATES_SUCCESS = 'SEARCH_CANDIDATES_SUCCESS';
export const SEARCH_CANDIDATES_ERROR = 'SEARCH_CANDIDATES_ERROR';
export const SEARCH_CANDIDATES_CACHE = 'SEARCH_CANDIDATES_CACHE';

export const CANCEL_SEARCH = 'CANCEL_SEARCH';

export const FETCH_CONNECTIONS = 'FETCH_CONNECTIONS';
export const HOME_FETCH_AGAIN = 'HOME_FETCH_AGAIN';
export const FETCH_CONNECTIONS_SUCCESS = 'FETCH_CONNECTIONS_SUCCESS';
export const FETCH_CONNECTIONS_ERROR = 'FETCH_CONNECTIONS_ERROR';

export const SELECT_CANDIDATE = 'SELECT_CANDIDATE';
export const READ_COOKIE = 'READ_COOKIE';

export const POST_NOTE_SUCCESS = 'POST_NOTE_SUCCESS';

export const FETCH_UPDATES = 'FETCH_UPDATES';
export const FETCH_UPDATES_SUCCESS = 'FETCH_UPDATES_SUCCESS';
export const FETCH_UPDATES_ERROR = 'FETCH_UPDATES_ERROR';
export const REFRESH_UPDATES = 'REFRESH_UPDATES';
export const PUT_UPDATE_SUCCESS = 'PUT_UPDATE_SUCCESS';
export const TOGGLE_UPDATES = 'TOGGLE_UPDATES';

export const UPLOAD_DOCUMENT_SUCCESS = 'UPLOAD_DOCUMENT_SUCCESS';
export const PUT_DOCUMENT_SUCCESS = 'PUT_DOCUMENT_SUCCESS';

export const FETCH_DOCUMENTS = 'FETCH_DOCUMENTS';
export const FETCH_DOCUMENTS_SUCCESS = 'FETCH_DOCUMENTS_SUCCESS';
export const FETCH_DOCUMENTS_ERROR = 'FETCH_DOCUMENTS_ERROR';

export const FETCH_CONTENTS_OPTIONS = 'FETCH_CONTENTS_OPTIONS';
export const FETCH_CONTENTS_OPTIONS_SUCCESS = 'FETCH_CONTENTS_OPTIONS_SUCCESS';
export const FETCH_CONTENTS_OPTIONS_ERROR = 'FETCH_CONTENTS_OPTIONS_ERROR';

export const SHOW_ALERT = 'SHOW_ALERT';
export const HIDE_ALERT = 'HIDE_ALERT';

export const REQUEST_UPDATE = 'REQUEST_UPDATE';
export const REQUEST_UPDATE_SUCCESS = 'REQUEST_UPDATE_SUCCESS';
export const REQUEST_UPDATE_ERROR = 'REQUEST_UPDATE_ERROR';

export const NOT_AUTHORIZED = 'NOT_AUTHORIZED';

export const GENERATE_PDF_SUCCESS = 'GENERATE_PDF_SUCCESS';

export const FETCH_PDF_SETTINGS = 'FETCH_PDF_SETTINGS';
export const FETCH_PDF_SETTINGS_SUCCESS = 'FETCH_PDF_SETTINGS_SUCCESS';
export const FETCH_PDF_SETTINGS_ERROR = 'FETCH_PDF_SETTINGS_ERROR';
