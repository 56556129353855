/* eslint-disable jsx-a11y/label-has-for, no-unused-expressions */
import React from 'react';
import { Helmet } from 'react-helmet';
import SectionTitle from './SectionTitle';
import {
  ApplicationDocumentsTable,
  SkillsChecklistsTable,
  CoverLettersTable,
  ReferenceCheckTable,
} from './Tables';
import UploadedDocuments from './UploadedDocuments';
import { AddReferenceCheckModal } from './ReferenceCheckModals';
import { AddCoverLetterModal } from './CoverLetterModals';
import { DocumentUploadModal } from './DocumentModals';
import EmailSelectedDocumentsModal from './EmailSelectedDocumentsModal';

const coverColumns = [
  'Cover Letter Title',
  'Date Modified',
  'Last Edited By',
  'Action',
];

const referenceColumns = [
  'Reference Info',
  'Date Modified',
  'Last Edited By',
  'Action',
];

const uploadTitleSource = [
  { id: 1, label: 'Ambulatory Care' },
  { id: 2, label: 'Ambulatory Surgery' },
  { id: 3, label: 'CCU - Critical Care Unit' },
  { id: 4, label: 'ICU - Intensive Care Unit' },
  { id: 5, label: 'Ambulatory PACU' },
];

class Documents extends React.Component {
  state = {
    type: 0,
    referenceChecks: [],
    files: [],
    letters: [],
    statements: [],
    background: [],
    checklists: [],
    pdfFiles: [],
  };

  onBackgroundChange = ({ target: { value } }) => {
    const { background } = this.state;
    background.indexOf(value) > -1
      ? background.splice(background.indexOf(value), 1)
      : background.push(value);

    this.setState({ background });
  };

  onStatementsChange = ({ target: { value } }) => {
    const { statements } = this.state;
    statements.indexOf(value) > -1
      ? statements.splice(statements.indexOf(value), 1)
      : statements.push(value);

    this.setState({ statements });
  };

  onLetterChange = ({ target: { value } }) => {
    const { letters } = this.state;
    letters.indexOf(value) > -1
      ? letters.splice(letters.indexOf(value), 1)
      : letters.push(value);

    this.setState({ letters });
  };

  onReferenceCheckChange = ({ target: { value } }) => {
    const { referenceChecks } = this.state;
    referenceChecks.indexOf(value) > -1
      ? referenceChecks.splice(referenceChecks.indexOf(value), 1)
      : referenceChecks.push(value);

    this.setState({ referenceChecks });
  };

  onChecklistChange = ({ target: { value } }) => {
    const { checklists } = this.state;
    checklists.indexOf(value) > -1
      ? checklists.splice(checklists.indexOf(value), 1)
      : checklists.push(value);

    this.setState({ checklists });
  };

  onFileRawChange = ({ target: { value } }) => {
    const { files } = this.state;
    files.indexOf(value) > -1
      ? files.splice(files.indexOf(value), 1)
      : files.push(value);

    this.setState({ files });
  };

  onFilePdfChange = ({ target: { value } }) => {
    const { pdfFiles } = this.state;
    pdfFiles.indexOf(value) > -1
      ? pdfFiles.splice(pdfFiles.indexOf(value), 1)
      : pdfFiles.push(value);

    this.setState({ pdfFiles });
  };

  toggleType = ({ target }) => {
    switch (this.state.type) {
      case 0:
        return this.setState({
          type: this.state.type + (target.name === 'profile' ? 1 : 2),
        });
      case 1:
        return this.setState({
          type: this.state.type + (target.name === 'profile' ? -1 : 2),
        });
      case 2:
        return this.setState({
          type: this.state.type + (target.name === 'profile' ? 1 : -2),
        });
      case 3:
        return this.setState({
          type: this.state.type + (target.name === 'profile' ? -1 : -2),
        });

      default:
        return false;
    }
  };

  render() {
    const {
      props: {
        name,
        documents,
        connected,
        update,
        user,
        checklists,
        deleteChecklist,
        handleDeleteLetter,
        handleDeleteReference,
        requestUpdate,
        handleDeleteFile,
        generatePDF,
        access,
        branding,
        fontLight,
        color,
      },
      toggleType,
      onBackgroundChange,
      onStatementsChange,
      onLetterChange,
      onReferenceCheckChange,
      onFileRawChange,
      onFilePdfChange,
      onChecklistChange,
      state,
    } = this;

    return (
      <div className="panel panel-default">
        <Helmet>
          <title>{`${name} Documents`}</title>
        </Helmet>
        <div className="panel-heading">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12">
              <EmailSelectedDocumentsModal
                generatePDF={generatePDF}
                selected={state}
                branding={branding}
                fontLight={fontLight}
                color={color}
              />
              <DocumentUploadModal source={uploadTitleSource} access={access} />
            </div>
          </div>
        </div>
        <div className="panel-body">
          <form>
            <SectionTitle title="Profile Documents" />
            <div className="checkbox">
              <label>
                <input
                  type="checkbox"
                  name="profile"
                  id="resume"
                  onChange={toggleType}
                  value={1}
                  checked={state.type === 1 || state.type === 3}
                />
                Profile/Resume
              </label>
              <div className="checkbox">
                <label>
                  <input
                    type="checkbox"
                    name="application"
                    id="application"
                    value={2}
                    onChange={toggleType}
                    checked={state.type === 2 || state.type === 3}
                  />
                  Application
                </label>
              </div>
            </div>
            <div className="hr" />
            <SectionTitle title="Application Documents" />
            <ApplicationDocumentsTable
              name={name}
              columns={['Document Name', 'Signing Date', 'Action']}
              background={documents.background}
              connected={connected}
              statements={documents.statements}
              onBackgroundChange={onBackgroundChange}
              onStatementsChange={onStatementsChange}
              requestUpdate={requestUpdate}
              onDeleteStatement={documents.deleteStatement}
              onDeleteBackground={documents.deleteBackground}
              update={update}
              access={access}
            />
            <div className="hr" />
            <SectionTitle title="Skills Checklists" />
            <SkillsChecklistsTable
              columns={['Checklist Title', 'Signing Date', 'Action']}
              body={checklists}
              requestUpdate={requestUpdate}
              update={update}
              onDeleteStatement={deleteChecklist}
              onChange={onChecklistChange}
              access={access}
            />
            <div className="hr" />
            <SectionTitle title="Cover Letters" />
            <div className="mg-btm-mid">
              <AddCoverLetterModal
                documents={documents}
                update={update}
                user={user}
                access={access}
              />
            </div>
            <CoverLettersTable
              columns={coverColumns}
              coverLetters={documents.coverLetters}
              documents={documents}
              update={update}
              user={user}
              handleDeleteLetter={handleDeleteLetter}
              onLetterChange={onLetterChange}
              access={access}
            />
            <div className="hr" />
            <SectionTitle title="Reference Checks" />
            <div className="mg-btm-mid">
              <AddReferenceCheckModal
                documents={documents}
                update={update}
                user={user}
                access={access}
              />
            </div>
            <ReferenceCheckTable
              docType="reference"
              columns={referenceColumns}
              referenceChecks={documents.referenceChecks}
              documents={documents}
              update={update}
              user={user}
              handleDeleteReference={handleDeleteReference}
              onReferenceCheckChange={onReferenceCheckChange}
              access={access}
            />
            <div className="hr" />
            <SectionTitle title="Uploaded Documents" />
            <UploadedDocuments
              files={documents.files}
              onFileRawChange={onFileRawChange}
              onFilePdfChange={onFilePdfChange}
              source={uploadTitleSource}
              documents={documents}
              handleDeleteFile={handleDeleteFile}
              access={access}
            />
          </form>
        </div>
        <div className="panel-footer">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12">
              <EmailSelectedDocumentsModal
                generatePDF={generatePDF}
                selected={state}
                branding={branding}
                fontLight={fontLight}
                color={color}
              />
              <DocumentUploadModal source={uploadTitleSource} access={access} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Documents;
