import _ from 'lodash';
import {
  FETCH_CONNECTIONS,
  HOME_FETCH_AGAIN,
  FETCH_CONNECTIONS_SUCCESS,
  SEARCH_CANDIDATES_SUCCESS,
  SEARCH_CANDIDATES_ERROR,
  FETCH_CONNECTIONS_ERROR,
} from '../actions';
import Candidate from '../models/Candidate';

const initialState = {
  isFetching: false,
  candidates: [],
  candidatesWithUpdates: [],
  search: [],
};

const connections = (state = initialState, action) => {
  switch (action.type) {
    case HOME_FETCH_AGAIN:
      return {
        ...state,
        candidates: [],
      };
    case FETCH_CONNECTIONS:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_CONNECTIONS_SUCCESS: {
      const newCandidates = _.chunk(
        action.data.map((c) => new Candidate(c)),
        10,
      );

      const candidatesWithUpdates = action.data.filter((kan) => kan.updateData);

      const newCandidatesWithUpdates = _.chunk(
        candidatesWithUpdates.map((c) => new Candidate(c)),
        10,
      );

      return {
        ...state,
        isFetching: false,
        candidates: newCandidates,
        candidatesWithUpdates: newCandidatesWithUpdates,
        isFetchingHomeCandidates: true,
      };
    }
    case FETCH_CONNECTIONS_ERROR:
      return {
        ...state,
        isFetching: false,
        candidates: [],
        errors: action.data,
      };
    case SEARCH_CANDIDATES_SUCCESS: {
      const newCandidates = _.chunk(
        action.data.data.map((c) => new Candidate(c)),
        10,
      );
      const { search } = state;
      return {
        ...state,
        search: {
          ...search,
          [action.data.name]: newCandidates,
        },
      };
    }
    case SEARCH_CANDIDATES_ERROR: {
      const { search } = state;
      return {
        ...state,
        search: {
          ...search,
          [action.name]: [],
        },
      };
    }
    default:
      return state;
  }
};

export default connections;
