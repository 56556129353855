import React from 'react';
import Title from './Title';

const Section = ({
  title,
  children,
  isMainSection = true,
  lineBelow = true,
}) => {
  const lineBelowSection = lineBelow ? <hr /> : null;

  return (
    <div>
      <Title title={title} isSubtitle={!isMainSection} />
      <hr />
      {children}
      {lineBelowSection}
    </div>
  );
};

export default Section;
