import React from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { MOMENTS_DATES_FORMATS } from '../../constants';

class DatePickerField extends React.Component {
  constructor({ selected }) {
    super();
    this.state = {
      selected,
    };
  }

  onChange = (date) => {
    const { onChange = () => {}, name } = this.props;

    const momentDate = moment.isMoment(date)
      ? date.format('MM-DD-YYYY')
      : moment(date, MOMENTS_DATES_FORMATS).format('MM-DD-YYYY');

    this.setState({ selected: date });
    onChange(name, momentDate);
  };

  render() {
    const {
      onChange,
      state: { selected },
      props: { name },
    } = this;

    const value = selected ? { selected } : {};
    return (
      <DatePicker
        className="form-control"
        name={name}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        placeholderText="Choose a date"
        onChange={onChange}
        dateFormat="MM-DD-YYYY"
        {...value}
      />
    );
  }
}

export default DatePickerField;
