/* eslint-disable jsx-a11y/label-has-for */

import React from 'react';
import moment from 'moment';
import Modal from '../../../../Modal';
import FormWrapper from '../../../../FormWrapper';
import {
  TextField,
  DatePickerField,
  TextareaField,
} from '../../../../FormFields';
import SkillLevelTable from './SkillLevelTable';
import { MOMENTS_DATES_FORMATS } from '../../../../../constants';

const levels = [
  { id: 1, label: 'Above Average' },
  { id: 2, label: 'Average' },
  { id: 3, label: 'Below Average' },
  { id: 4, label: 'Poor' },
];

const employeeFields = [
  { id: 'employeeName', name: 'employeeName', label: 'Employee Name' },
  { id: 'employeeTitle', name: 'employeeTitle', label: 'Employee Title' },
  {
    id: 'facilityEmployer',
    name: 'facilityEmployer',
    label: 'Facility/Employer',
  },
  { id: 'unitWorked', name: 'unitWorked', label: 'Unit Worked' },
];

const referenceFields = [
  { id: 'referenceName', name: 'referenceName', label: 'Reference name' },
  { id: 'referenceTitle', name: 'referenceTitle', label: 'Reference title' },
  { id: 'referencePhone', name: 'referencePhone', label: 'Reference phone' },
  { id: 'referenceEmail', name: 'referenceEmail', label: 'Reference email' },
  {
    id: 'referenceCheckedBy',
    name: 'referenceCheckedBy',
    label: 'Reference checked by',
  },
];

const additionalQuestionFiels = [
  {
    id: 'resignedOrTerminated',
    name: 'resignedOrTerminated',
    label: 'Resigned or Terminated?',
  },
  {
    id: 'eligibleForRehire',
    name: 'eligibleForRehire',
    label: 'Eligible for Rehire?',
  },
  {
    id: 'reasonForLeaving',
    name: 'reasonForLeaving',
    label: 'Reason for leaving?',
  },
];

class ReferenceCheckContent extends FormWrapper {
  constructor(props) {
    super(props);
    let modalHandler = {
      referenceCheck: {},
    };
    if (props) {
      modalHandler = {
        ...props.modalData.referenceCheck,
      };
    }
    this.state = {
      stateChecker: 'referencer',
      ...modalHandler,
    };
  }

  onSubmit = () => {};

  onLevelChange = (id, newLevelId) => {
    this.setState({ [id]: newLevelId });
    this.setState((prevState) => ({
      skills: prevState.skills.map((skill) => {
        if (id === skill.id) {
          return {
            ...skill,
            levelId: newLevelId,
          };
        }
        return skill;
      }),
    }));
  };

  render() {
    const {
      onSubmit,
      onLevelChange,
      onInputFieldChange,
      onDatePickerFieldChange,
      props: { handleClose, visible },
      state: {
        skills,
        timePeriodCurrentEmployer,
        additionalComments,
        timePeriodFrom,
        timePeriodTo,
        dateReferenceChecked,
        chargeDuty,
      },
      state,
    } = this;

    /* eslint-disable no-nested-ternary */
    const timeFrom = timePeriodFrom
      ? Number.isInteger(timePeriodFrom)
        ? moment(timePeriodFrom)
        : moment(timePeriodFrom, MOMENTS_DATES_FORMATS)
      : null;
    const timeTo = timePeriodTo
      ? Number.isInteger(timePeriodTo)
        ? moment(timePeriodTo)
        : moment(timePeriodTo, MOMENTS_DATES_FORMATS)
      : null;
    const dateReference = dateReferenceChecked
      ? Number.isInteger(dateReferenceChecked)
        ? moment(dateReferenceChecked)
        : moment(dateReferenceChecked, MOMENTS_DATES_FORMATS)
      : null;
    /* eslint-enable no-nested-ternary */

    return (
      <Modal
        visible={visible}
        hideComponent={() => handleClose(this.state, this.state.stateChecker)}
      >
        <Modal.Header
          title="Add Reference Check"
          hideComponent={() => handleClose(this.state, this.state.stateChecker)}
        />
        <Modal.Body>
          <form>
            {employeeFields.map(({ id, name, label }) => (
              <TextField
                key={id}
                id={id}
                name={name}
                label={label}
                value={state[name]}
                onChange={onInputFieldChange}
              />
            ))}
            <div className="form-group">
              <label htmlFor="date">Time Period:</label>
              <div className="row">
                <div className="col-sm-7 col-md-8">
                  <input
                    type="checkbox"
                    id="timePeriodCurrentEmployer"
                    name="timePeriodCurrentEmployer"
                    checked={timePeriodCurrentEmployer}
                    className="mt-10 mr-5"
                    onChange={onInputFieldChange}
                  />
                  <label htmlFor="job-current">Current Employer</label>
                </div>
              </div>
              <DatePickerField
                selected={timeFrom}
                name="timePeriodFrom"
                onChange={onDatePickerFieldChange}
              />
              {!timePeriodCurrentEmployer && (
                <div>
                  <p className="mt-5">
                    <strong>To:</strong>
                  </p>
                  <DatePickerField
                    selected={timeTo}
                    name="timePeriodTo"
                    onChange={onDatePickerFieldChange}
                  />
                </div>
              )}
            </div>
            <div className="hr mb-20" />
            <SkillLevelTable
              tableLabel="Criteria"
              onLevelChange={onLevelChange}
              skills={skills}
              levels={levels}
            />
            <div className="hr mb-20" />
            <div className="mg-btm-mid">
              <strong className="mb-0 mr-5">Charge Duty:</strong>
              <label className="radio-inline">
                <input
                  type="radio"
                  name="chargeDuty"
                  checked={chargeDuty === true}
                  value="true"
                  onChange={onInputFieldChange}
                />
                YES
              </label>
              <label className="radio-inline">
                <input
                  type="radio"
                  name="chargeDuty"
                  checked={chargeDuty === false}
                  value="false"
                  onChange={onInputFieldChange}
                />
                NO
              </label>
            </div>
            {additionalQuestionFiels.map(({ id, name, label }) => (
              <TextField
                key={id}
                id={id}
                name={name}
                label={label}
                value={state[name]}
                onChange={onInputFieldChange}
              />
            ))}
            <TextareaField
              id="additionalComments"
              name="additionalComments"
              label="Additional Comment's"
              value={additionalComments}
              onChange={onInputFieldChange}
            />
            {referenceFields.map(({ id, name, label }) => (
              <TextField
                key={id}
                id={id}
                name={name}
                label={label}
                value={state[name]}
                onChange={onInputFieldChange}
              />
            ))}
            <div className="form-group">
              <label htmlFor="date">Date reference checked:</label>
              <DatePickerField
                name="dateReferenceChecked"
                selected={dateReference}
                onChange={onDatePickerFieldChange}
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 text-center">
              <div className="form-group connections-body">
                <input
                  type="submit"
                  value="Save"
                  className="btn btn-primary"
                  onClick={onSubmit}
                />
                <input
                  type="submit"
                  value="Cancel"
                  className="btn btn-default"
                  onClick={() =>
                    handleClose(this.state, this.state.stateChecker)
                  }
                />
              </div>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ReferenceCheckContent;
