import React from 'react';

const IconTitle = ({ icon, title }) => (
  <h2 className="h2-details mp-0">
    <span className={`glyphicon details-ico glyphicon-${icon} pr-5`} />
    {title}
  </h2>
);

export default IconTitle;
