import React from 'react';

class File extends React.Component {
  onDelete = () => {
    const { typeId, onDelete = () => {} } = this.props;

    onDelete(typeId);
  };

  render() {
    const {
      onDelete,
      props: { name, expireDate },
    } = this;

    return (
      <div className="col-xs-12 col-sm-6 col-md-6">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12">
            <div className="exp-wrapper">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12">{name}</div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12">
                  Expiration: {expireDate}
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12">
                  <button
                    type="button"
                    className="btn btn-default"
                    onClick={onDelete}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default File;
