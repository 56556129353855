import _ from 'lodash';
import {
  FETCH_NOTES,
  FETCH_NOTES_ERROR,
  FETCH_NOTES_SUCCESS,
  POST_NOTE_SUCCESS,
} from '../actions';

const initialState = {
  isFetching: false,
};

const notes = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_NOTES:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_NOTES_SUCCESS: {
      const notesChunk = _.chunk(action.data, 10);
      return {
        ...state,
        isFetching: false,
        [action.url]: notesChunk,
      };
    }
    case POST_NOTE_SUCCESS: {
      return {
        ...initialState,
      };
    }
    case FETCH_NOTES_ERROR:
      return {
        ...state,
        isFetching: false,
        [action.url]: [],
      };
    default:
      return state;
  }
};

export default notes;
