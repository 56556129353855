import { SHOW_ALERT, HIDE_ALERT } from '.';

export const showAlert = (data) => ({
  type: SHOW_ALERT,
  data,
});

export const hideAlert = () => ({
  type: HIDE_ALERT,
});
