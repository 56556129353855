/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import { NavLink } from 'react-router-dom';
import { ACCESS_LVL } from '../../constants';

const SubNav = ({ id, updates, access }) => (
  <nav className="navbar navbar-default navbar-bp-custom">
    <div className="container-fluid">
      <div className="navbar-header">
        <button
          type="button"
          className="navbar-toggle collapsed"
          data-toggle="collapse"
          data-target="#navbar-candidates"
          aria-expanded="false"
        >
          <span className="sr-only">Toggle navigation</span>
          <span className="icon-bar" />
          <span className="icon-bar" />
          <span className="icon-bar" />
        </button>
      </div>
      <div className="collapse navbar-collapse" id="navbar-candidates">
        <ul className="nav navbar-nav navbar-subnav-default">
          <li>
            <NavLink exact to={`/${id}`}>
              Main
            </NavLink>
          </li>
          <li>
            <NavLink to={`/${id}/profile`}>Profile</NavLink>
          </li>
          <li>
            <NavLink to={`/${id}/documents`}>Documents</NavLink>
          </li>
          <li>
            <NavLink to={`/${id}/updates`}>
              Updates
              {updates > 0 && access === ACCESS_LVL.ACTIVE && (
                <span className="badge message-badge">{updates}</span>
              )}
            </NavLink>
          </li>
          <li>
            <NavLink to={`/${id}/notes`}>Notes</NavLink>
          </li>
          <li>
            <NavLink to={`/${id}/log`}>Log</NavLink>
          </li>
        </ul>
      </div>
    </div>
  </nav>
);

export default SubNav;
