import React from 'react';
import isEmpty from 'lodash/isEmpty';
import FormWrapper from '../../../../FormWrapper';
import { unitIdMap } from '../../../../../constants';

class ExperienceFormStep3 extends FormWrapper {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
  }

  render() {
    const {
      props: {
        isSubPosition,
        saveAgencyAndAddAssignment,
        fieldValues,
        nextStep,
        previousStep,
        referencesStep,
        onInputFieldChange,
        onSubmit = () => {},
      },
    } = this;

    return (
      <div className="panel panel-default">
        <div className="panel-heading">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12">
              <div className="text-large">{`${fieldValues.employer}${
                fieldValues.employer ? ' - ' : ''
              }References`}</div>
            </div>
          </div>
        </div>
        <div className="panel-body">
          <form onSubmit={onSubmit}>
            <label htmlFor="contact">
              In case you apply through BluePipes, may this employer be
              contacted?
            </label>
            <div className="row">
              <div className="col-xs-12 col-sm-8 col-md-8">
                <div className="form-group">
                  <label className="radio-inline">
                    <input
                      type="radio"
                      name="canContactEmployer"
                      id="inlineRadio1"
                      value
                      checked={fieldValues.canContactEmployer}
                      onChange={onInputFieldChange}
                    />
                    Yes
                  </label>
                  <label className="radio-inline">
                    <input
                      type="radio"
                      name="canContactEmployer"
                      id="inlineRadio2"
                      value={false}
                      checked={fieldValues.canContactEmployer === false}
                      onChange={onInputFieldChange}
                    />
                    No
                  </label>
                </div>
              </div>
            </div>
            <div className="reference-container">
              <div className="row">
                <div className="col-xs-12 col-sm-10 col-md-9">
                  <p className="text-one">Supervisor</p>
                  {!isEmpty(fieldValues.supervisor) && (
                    <div>
                      <h3 className="h3-edit">{fieldValues.supervisor.name}</h3>
                      <div className="ref-title">
                        <strong>{fieldValues.supervisor.title}</strong>
                      </div>
                      {fieldValues.supervisor.phone && (
                        <div className="lic-info-edit">
                          <strong>Phone:</strong>
                          {` ${fieldValues.supervisor.phone || ''}`}
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className="col-xs-12 col-sm-2 col-md-3">
                  <div>
                    <button
                      onClick={(e) => {
                        nextStep(fieldValues, e);
                      }}
                      className="btn btn-primary btn-xs"
                    >
                      {isEmpty(fieldValues.supervisor)
                        ? 'Add Supervisor'
                        : 'Edit Supervisor'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="reference-container">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12">
                  <div className="row">
                    <div className="col-xs-12 col-sm-10 col-md-9">
                      <p className="text-one">References</p>
                    </div>
                    <div className="col-xs-12 col-sm-2 col-md-3">
                      <button
                        onClick={(e) => {
                          referencesStep(e);
                        }}
                        className="btn btn-primary btn-xs"
                      >
                        Add Reference
                      </button>
                    </div>
                  </div>
                  {fieldValues.references &&
                    fieldValues.references.map((ref, index) => (
                      <div key={ref.id}>
                        <div className="row">
                          <div className="col-xs-12 col-sm-10 col-md-9">
                            <h3 className="h3-edit">{ref.referenceName}</h3>
                            {ref.title && (
                              <div className="ref-title">
                                <strong>{ref.title}</strong>
                              </div>
                            )}
                            {(ref.unitId || ref.phone) && (
                              <div className="lic-info-edit">
                                {!!ref.unitId && (
                                  <div>
                                    <strong>Unit:</strong>
                                    {`${unitIdMap[ref.unitId]}`}
                                  </div>
                                )}
                                {ref.phone && (
                                  <div>
                                    <strong>Phone:</strong>
                                    {` ${ref.phone}`}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                          <div className="col-xs-12 col-sm-2 col-md-3">
                            <button
                              onClick={(e) => {
                                referencesStep(e, fieldValues, ref.id, true);
                              }}
                              type="button"
                              className="btn btn-primary btn-xs"
                            >
                              Edit Reference
                            </button>
                          </div>
                        </div>
                        {index + 1 < fieldValues.references.length && (
                          <div className="hr mb-15" />
                        )}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="add-edit-btn">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 text-center">
                  {parseInt(fieldValues.employmentType, 10) === 1 &&
                  !isSubPosition ? (
                    <div className="form-group mt-20">
                      <input
                        type="submit"
                        onClick={(e) => {
                          e.preventDefault();
                          previousStep(fieldValues);
                        }}
                        className="btn btn-default mt-5 mb-3"
                        value="Previous"
                      />
                      <button
                        onClick={(e) =>
                          saveAgencyAndAddAssignment(e, fieldValues)
                        }
                        className="btn btn-info mt-5 mb-3"
                      >
                        Add Agency Assignment
                      </button>
                      <input
                        type="submit"
                        className="btn btn-primary mt-5 mb-3"
                        value="Done"
                      />
                    </div>
                  ) : (
                    <div className="form-group mt-20">
                      <input
                        type="submit"
                        onClick={(e) => {
                          e.preventDefault();
                          previousStep(fieldValues);
                        }}
                        className="btn btn-default mt-5 mb-3"
                        value="Previous"
                      />
                      <input
                        type="submit"
                        className="btn btn-primary mt-5 mb-3"
                        value="Done"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default ExperienceFormStep3;
