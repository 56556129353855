import React from 'react';

const Location = ({ site, label, city, region }) => (
  <div>
    <a href={site}>{label}</a>
    {city ? `, ${city}` : ''}
    {region ? `, ${region}` : ''}
  </div>
);

export default Location;
