import React from 'react';
import { Helmet } from 'react-helmet';

const NotFound = ({ name = '', page = '' }) => (
  <div className="contact-container">
    <Helmet>
      <title>{`${name} ${page}`}</title>
    </Helmet>
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-12">
        <i className="center-block bp-call-text text-center">No items found.</i>
      </div>
    </div>
  </div>
);

export default NotFound;
