import React from 'react';

const SelectField = ({
  id,
  name,
  label,
  options = [],
  selected,
  onChange = () => {},
}) => (
  <div className="form-group">
    <label htmlFor={id}>{label}:</label>
    <select
      className="form-control"
      id={id}
      name={name}
      defaultValue={selected}
      onChange={(e) => onChange(e, name)}
    >
      {options.map((option) => (
        <option key={option.id} value={option.label}>
          {option.label}
        </option>
      ))}
    </select>
  </div>
);

export default SelectField;
