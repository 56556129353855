import React from 'react';
import { connect } from 'react-redux';
import { history } from '../../../../store';
import EducationForm from '../../../../components/Candidate/Profile/Education/EducationForm';
import { getCandidateData } from '../../../../selectors';
import { update } from '../../../../actions/candidates';
import NotFound from '../../../../components/NotFound/NotFound';
import Loading from '../../../../components/Loading';
import { LOG_MESSAGES } from '../../../../constants';

class EditEducation extends React.Component {
  onSubmit = (e) => {
    this.props.candidate.setEducation(e);
    this.props.update(LOG_MESSAGES.EDUCATION_INFORMATION_MODIFIED);
    history.push(`/${this.props.candidateUrl}/profile`);
  };

  onRemove = (e) => {
    this.props.candidate.removeEducation(e);
    this.props.update(LOG_MESSAGES.EDUCATION_INFORMATION_MODIFIED);
    history.push(`/${this.props.candidateUrl}/profile`);
  };

  render() {
    const {
      onSubmit,
      onRemove,
      props: {
        candidate,
        candidateUrl,
        isFetching,
        match: {
          params: { schoolId },
        },
      },
    } = this;

    if (isFetching) {
      return <Loading />;
    }
    if (candidate === undefined) {
      return <NotFound />;
    }

    const education = candidate.educations.find(
      (edu) => edu.schoolId === parseInt(schoolId, 10),
    );

    return (
      <EducationFormComponent
        key={candidateUrl}
        title="Edit Education"
        onSubmit={onSubmit}
        onRemove={onRemove}
        candidateUrl={candidateUrl}
        edit
        data={education}
      />
    );
  }
}

class EducationFormComponent extends EducationForm {
  constructor(props) {
    super(props);

    this.state = {
      ...props.data,
    };
  }

  onSubmit = (e) => {
    const {
      props: { onSubmit = () => {} },
      state,
    } = this;

    e.preventDefault();
    onSubmit(state);
  };

  onRemove = (e) => {
    const {
      props: { onRemove = () => {} },
      state,
    } = this;

    e.preventDefault();
    onRemove(state);
  };
}

const mapStateToProps = (state) => ({
  candidateUrl: state.globalInfo.candidateUrl,
  candidate: getCandidateData(state),
});

export default connect(mapStateToProps, { update })(EditEducation);
