import React from 'react';

const ModalFooter = ({ children }) => (
  <div className="modal-footer">
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-12 text-center">
        {children}
      </div>
    </div>
  </div>
);

export default ModalFooter;
