import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { update } from '../../../../actions/candidates';
import { history } from '../../../../store';
import LicenseOrCertificationForm from '../../../../components/Candidate/Profile/LicenseOrCertification/LicenseOrCertificationForm';
import fetchLicenses from '../../../../actions/licensesAndCertsOptions';
import { getCandidateData, getLicensesAndCerts } from '../../../../selectors';
import { LOG_MESSAGES } from '../../../../constants';

const EditLicense = ({
  candidate,
  candidateUrl,
  update,
  fetchLicenses,
  licenseCertOptions = {
    1: { items: [], bodies: [] },
    2: { items: [], bodies: [] },
    3: { items: [], bodies: [] },
  },
  match: {
    params: { id },
  },
}) => {
  const licenseCert = candidate.licenseCerts.find(
    (l) => l.licenseCertId === parseInt(id, 10),
  );
  return (
    <SpecialtiesFormComponent
      key={candidateUrl}
      title="Edit License or Certification"
      candidateUrl={candidateUrl}
      licenseCert={licenseCert}
      candidate={candidate}
      autosuggestSource={licenseCertOptions}
      update={update}
      fetchLicenses={fetchLicenses}
      id={parseInt(id, 10)}
      edit
    />
  );
};

class SpecialtiesFormComponent extends LicenseOrCertificationForm {
  constructor(props) {
    super(props);

    props.fetchLicenses(1);
    props.fetchLicenses(2);
    props.fetchLicenses(3);

    this.state = {
      ...props.licenseCert,
      showLicCertCustomForm: false,
      showLicCertBodyCustomForm: false,
    };
  }

  onSubmit = (e) => {
    const {
      state,
      props: { candidate, candidateUrl, update, id },
    } = this;
    e.preventDefault();
    const license = _.omit(state, [
      'showLicCertCustomForm',
      'showLicCertBodyCustomForm',
    ]);
    candidate.editLicenseCerts(license, id);
    update(LOG_MESSAGES.LIC_AND_CERTS_INFORMATION_MODIFIED);
    history.push(`/${candidateUrl}/profile`);
  };

  onRemove = (e) => {
    const {
      state,
      props: { candidate, candidateUrl, update },
    } = this;
    e.preventDefault();
    candidate.removeLicenseCerts(state);
    update(LOG_MESSAGES.LIC_AND_CERTS_INFORMATION_MODIFIED);
    history.push(`/${candidateUrl}/profile`);
  };
}

const mapStateToProps = (state) => ({
  candidate: getCandidateData(state),
  candidateUrl: state.globalInfo.candidateUrl,
  licenseCertOptions: getLicensesAndCerts(state),
});

export default connect(mapStateToProps, { update, fetchLicenses })(EditLicense);
