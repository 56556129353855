import React from 'react';
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';
import SimpleTableRow from './SimpleTableRow';

const SimpleTable = ({ columns, body, action }) => (
  <table className="table table-hover subTable">
    <thead>
      <tr>{columns && columns.map((name) => <th key={name}>{name}</th>)}</tr>
    </thead>
    <tbody>
      {body &&
        body.map((row) => (
          <SimpleTableRow
            key={row.timestamp}
            note={ReactHtmlParser(row.note)}
            date={moment(row.timestamp).format('MM/DD/YYYY')}
            author={row.author}
            action={action}
          />
        ))}
    </tbody>
  </table>
);

export default SimpleTable;
