import AutosuggestField from './AutosuggestField';

const addNewLabel = 'Add New';

class AddNewAutosuggestField extends AutosuggestField {
  onChange = (event, { newValue, method }) => {
    if (['up', 'down'].includes(method) && newValue === addNewLabel) {
      return;
    }
    this.suggestionSelected = false;
    this.setState({
      value: newValue,
    });
  };

  onSuggestionsClearRequested = () => {
    const {
      props: {
        onShowCustomForm = () => {},
        workWithMains,
        mainAcronym,
        mainName,
        editPage,
      },
      state: { value },
      suggestionSelected,
    } = this;

    if (value && !suggestionSelected && (!workWithMains || !editPage)) {
      onShowCustomForm(value);
    }

    if (value && !suggestionSelected && workWithMains && editPage) {
      onShowCustomForm(value);
    }

    if (mainName && !suggestionSelected && workWithMains) {
      onShowCustomForm({ name: mainName, acronym: mainAcronym });
    }
  };

  onSuggestionSelected = (event, { suggestion, method }) => {
    this.suggestionSelected = true;

    const {
      props: {
        onShowCustomForm = () => {},
        onSelect = () => {},
        workWithMains,
        mainAcronym,
        mainName,
        editPage,
      },
      state: { value },
    } = this;

    if (method === 'enter') {
      event.preventDefault();
    }

    if (suggestion.addNew && (!workWithMains || !editPage)) {
      onShowCustomForm(value);
      return;
    }

    if (suggestion.addNew && workWithMains) {
      onShowCustomForm({ name: mainName, acronym: mainAcronym });
      return;
    }

    onSelect(suggestion);
  };

  onKeyDown = (event) => {
    const {
      suggestionSelected,
      props: { onShowCustomForm = () => {} },
    } = this;
    if (event.keyCode === 13) {
      const { value } = this.state;

      event.preventDefault();

      if (!suggestionSelected) {
        onShowCustomForm(value);
      }
    }
  };

  filterSuggestions = (suggestions) => {
    const {
      state: { value },
      props: { labelKey },
    } = this;
    const items = suggestions.filter((suggestion) => {
      const parts = suggestion[labelKey]
        .split(/[\s-]+/)
        .map((part) => part.trim());
      return parts.find(
        (part) => part.toLowerCase().indexOf(value.toLowerCase()) === 0,
      );
    });

    if (
      items.length === 1 &&
      items[0][labelKey].toLowerCase() === value.toLowerCase()
    ) {
      return [];
    }

    items.push({
      [labelKey]: addNewLabel,
      name: '',
      addNew: true,
    });

    return items;
  };
}

export default AddNewAutosuggestField;
