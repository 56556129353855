/* eslint-disable max-len */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import Notes from '../../components/Candidate/Notes';
import fetchNotes from '../../actions/notes';
import NotFound from '../../components/NotFound/NotFound';
import Loading from '../../components/Loading';
import {
  getNotes,
  getNumberOfNotes,
  getPage,
  getCandidateData,
} from '../../selectors/index';

class NotesContainer extends Component {
  componentDidMount() {
    this.props.fetchNotes();
  }

  componentWillReceiveProps() {
    this.props.fetchNotes();
  }

  render() {
    const {
      notes,
      isFetching,
      numberOfNotes,
      page,
      name,
      match: { url },
    } = this.props;

    if (isFetching) {
      return <Loading />;
    }
    if (notes.length === 0) {
      return <NotFound name={name} page="Notes" />;
    }

    return (
      <Notes
        candidateUrl={url}
        notes={notes}
        numberOfNotes={numberOfNotes}
        url={url}
        page={page}
        name={name}
      />
    );
  }
}

NotesContainer.defaultProps = {
  notes: [],
};

const mapStateToProps = (state) => ({
  isFetching: state.notes.isFetching,
  notes: getNotes(state),
  numberOfNotes: getNumberOfNotes(state),
  page: getPage(state),
  name: getCandidateData(state).getName(),
});

export default connect(mapStateToProps, {
  fetchNotes,
})(NotesContainer);
