import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import candidates from './candidates';
import connections from './connections';
import globalInfo from './globalInfo';
import notes from './notes';
import logs from './logs';
import updates from './updates';
import specialties from './specialties';
import licenseCert from './licensesCrets';
import contents from './contents';
import settings from './settings';

export default combineReducers({
  routing: routerReducer,
  candidates,
  connections,
  globalInfo,
  notes,
  logs,
  updates,
  specialties,
  licenseCert,
  contents,
  settings,
});
