/* eslint-disable consistent-return */

import axios from 'axios';
import { SPECIALTIES_LOOKUP_URL } from '../constants';
import {
  FETCH_SPECIALTIES_OPTIONS,
  FETCH_SPECIALTIES_OPTIONS_SUCCESS,
  FETCH_SPECIALTIES_OPTIONS_ERROR,
} from '.';
import { getSpecialties } from '../selectors/index';

const fetchSpecialtiesOptions = () => ({
  type: FETCH_SPECIALTIES_OPTIONS,
});

const fetchSpecialtiesOptionsSuccess = (occupationId, data) => ({
  type: FETCH_SPECIALTIES_OPTIONS_SUCCESS,
  occupationId,
  data,
});

const fetchSpecialtiesOptionsError = () => ({
  type: FETCH_SPECIALTIES_OPTIONS_ERROR,
});

export default (occupationId) => async (dispatch, getState) => {
  const state = getState();

  if (state.specialties.isFetching || getSpecialties(state)) {
    return Promise.resolve();
  }

  dispatch(fetchSpecialtiesOptions());
  try {
    const response = await axios.get(
      `${SPECIALTIES_LOOKUP_URL}${occupationId}`,
      { withCredentials: true },
    );

    dispatch(fetchSpecialtiesOptionsSuccess(occupationId, response.data));
  } catch (e) {
    console.error(e);
    dispatch(fetchSpecialtiesOptionsError());
  }
};
