import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';
import { withCookies } from 'react-cookie';
import queryString from 'query-string';
import { history } from '../../store';
import getCookie from '../../actions/cookie';
import { cancelSearch } from '../../actions/navigationHelpers';
import Header from '../../components/Header';
import Candidates from '../Candidates';
import CandidatePage from '../Candidate';
import { AUTH_COOKIE, ACCESS_LVL } from '../../constants';
import NoAccess from '../../components/NoAccess/NoAccess';

class Content extends Component {
  componentWillMount() {
    const params = queryString.parse(history.location.search);
    const token = this.props.cookies.get(AUTH_COOKIE);
    this.props.getCookie(token, params.companyId);
  }

  render() {
    const { companyName, companyId, query, cancelSearch, access, jobsAccess } =
      this.props;

    if (access === ACCESS_LVL.NO_ACCESS) {
      return <NoAccess />;
    }

    return (
      <ConnectedRouter history={history}>
        <div className="container profile-page">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12">
              <div className="panel panel-default">
                <div className="panel-heading customfont300 txt-md-em">
                  <Header
                    name={companyName}
                    companyId={companyId}
                    jobsAccess={jobsAccess}
                    handleCancelSearch={query ? cancelSearch : () => {}}
                  />
                </div>
                <div className="panel-body">
                  <Switch>
                    <Route path="/:id" component={CandidatePage} />
                    <Route path="/" component={Candidates} />
                  </Switch>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ConnectedRouter>
    );
  }
}

const mapStateToProps = (state) => ({
  companyName: state.globalInfo.companyName,
  companyId: state.globalInfo.companyId,
  query: state.globalInfo.query,
  access: state.globalInfo.access,
  message: state.globalInfo.message,
  jobsAccess: state.globalInfo.jobsAccess,
});

export default connect(mapStateToProps, {
  getCookie,
  cancelSearch,
})(withCookies(Content));
