import React from 'react';
import moment from 'moment';
import Modal from '../../../Modal';
import CovertLetterContent from './CoverLetterContent';
import { LOG_MESSAGES } from '../../../../constants';

class EditCoverLetterModal extends Modal.Manager {
  render() {
    const {
      handleClose,
      handleShow,
      state: { showModal, visible },
      props: { id, data, documents, update, user },
    } = this;

    return (
      <span>
        <a href="/" className="dropdown-menu-link" onClick={handleShow}>
          Edit
        </a>
        {showModal && (
          <ModalComponent
            visible={visible}
            handleClose={handleClose}
            data={data}
            id={id}
            documents={documents}
            update={update}
            user={user}
          />
        )}
      </span>
    );
  }
}

class ModalComponent extends CovertLetterContent {
  constructor({ data }) {
    super();

    this.state = data;
  }

  onSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }
    const {
      props: { id, handleClose, documents, update, user },
      state,
    } = this;

    const newLetter = {
      ...state,
      dateModified: moment().format('MM/DD/YYYY'),
      lastEditedBy: user,
    };
    documents.editLetter(newLetter, id);
    update(LOG_MESSAGES.EXISTING_COVER_LETTER_EDITED);
    handleClose();
  };
}

export default EditCoverLetterModal;
