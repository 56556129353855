/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import { Link } from 'react-router-dom';
import { ACCESS_LVL } from '../../../../constants';

const AddEditButton = ({ label, url, access }) => (
  <div className="pull-right">
    <Link to={url}>
      <button
        type="button"
        className="btn btn-primary btn-no-margin btn-xs"
        disabled={access === ACCESS_LVL.EXPIRED}
      >
        {label}
      </button>
    </Link>
  </div>
);

export default AddEditButton;
