import {
  FETCH_SPECIALTIES_OPTIONS,
  FETCH_SPECIALTIES_OPTIONS_SUCCESS,
  FETCH_SPECIALTIES_OPTIONS_ERROR,
} from '../actions';

const initialState = {
  isFetching: false,
  specialties: {},
};

const candidates = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SPECIALTIES_OPTIONS:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_SPECIALTIES_OPTIONS_SUCCESS: {
      const { specialties } = state;
      return {
        ...state,
        isFetching: false,
        specialties: {
          ...specialties,
          [action.occupationId]: action.data,
        },
      };
    }
    case FETCH_SPECIALTIES_OPTIONS_ERROR:
      return {
        ...state,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default candidates;
