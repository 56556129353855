import React from 'react';
import moment from 'moment';
import { Alert } from 'react-bootstrap';
import Modal from '../../../Modal';
import FormWrapper from '../../../FormWrapper';
import { ACCESS_LVL } from '../../../../constants';

class TimeOffModal extends Modal.Manager {
  render() {
    const {
      handleClose,
      handleShow,
      state: { showModal, visible },
      props: { candidate, update, access },
    } = this;
    const modalComponent = showModal ? (
      <ModalComponent
        visible={visible}
        handleClose={handleClose}
        candidate={candidate}
        update={update}
      />
    ) : (
      ''
    );

    return (
      <span>
        <button
          type="button"
          className="btn btn-info"
          data-toggle="modal"
          // data-target="#message"
          onClick={handleShow}
          disabled={access === ACCESS_LVL.EXPIRED}
        >
          <span className="glyphicon glyphicon-plus" /> Add
        </button>
        {modalComponent}
      </span>
    );
  }
}

class ModalComponent extends FormWrapper {
  state = {
    isValid: true,
  };

  onSubmit = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    const { candidate } = this.props;
    const from = moment(
      `${e.target.yearFrom.value}-${e.target.monthFrom.value}-${e.target.dayFrom.value}`,
    );
    const to = moment(
      `${e.target.yearTo.value}-${e.target.monthTo.value}-${e.target.dayTo.value}`,
    );

    if (this.validate(from, to)) {
      candidate.addTimeOff(from, to);
      this.props.handleClose();
    }

    this.props.update(candidate.getTimeOff());
  };

  validate = (from, to) => {
    const isValid = from.isValid() && to.isValid();
    this.setState({ isValid });
    return isValid;
  };

  render() {
    const {
      onSubmit,
      props: { visible, handleClose },
    } = this;

    return (
      <Modal visible={visible} hideComponent={handleClose}>
        <Modal.Header title="Requested Time Off" hideComponent={handleClose} />
        <Modal.Body>
          {!this.state.isValid && (
            <Alert bsStyle="warning">
              <strong>Invalid Date.</strong> Please correct inserted dates.
            </Alert>
          )}
          <form onSubmit={onSubmit} id="timeOff">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12">
                <label htmlFor="form-group">Time Period:</label>
                <div className="form-group">
                  <div className="row">
                    <div className="col-xs-12 col-sm-4 col-md-4" id="">
                      <select
                        className="form-control mt-3"
                        id="monthFrom"
                        name="monthFrom"
                        required
                      >
                        <option value="">Month</option>
                        <option value="01">Jan</option>
                        <option value="02">Feb</option>
                        <option value="03">Mar</option>
                        <option value="04">Apr</option>
                        <option value="05">May</option>
                        <option value="06">Jun</option>
                        <option value="07">Jul</option>
                        <option value="08">Aug</option>
                        <option value="09">Sept</option>
                        <option value="10">Oct</option>
                        <option value="11">Nov</option>
                        <option value="12">Dec</option>
                      </select>
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4">
                      <select
                        className="form-control mt-3"
                        id="dayFrom"
                        name="dayFrom"
                        required
                      >
                        <option value="">Day</option>
                        <option value="01">01</option>
                        <option value="02">02</option>
                        <option value="03">03</option>
                        <option value="04">04</option>
                        <option value="05">05</option>
                        <option value="06">06</option>
                        <option value="07">07</option>
                        <option value="08">08</option>
                        <option value="09">09</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                        <option value="13">13</option>
                        <option value="14">14</option>
                        <option value="15">15</option>
                        <option value="16">16</option>
                        <option value="17">17</option>
                        <option value="18">18</option>
                        <option value="19">19</option>
                        <option value="20">20</option>
                        <option value="21">21</option>
                        <option value="22">22</option>
                        <option value="23">23</option>
                        <option value="24">24</option>
                        <option value="25">25</option>
                        <option value="26">26</option>
                        <option value="27">27</option>
                        <option value="28">28</option>
                        <option value="29">29</option>
                        <option value="30">30</option>
                        <option value="31">31</option>
                      </select>
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-3">
                      <input
                        type="text"
                        id="yearFrom"
                        name="yearFrom"
                        className="form-control input-sm mt-3"
                        size="4"
                        maxLength="4"
                        placeholder="YYYY"
                        required
                      />
                    </div>
                  </div>
                  <p className="mt-5">
                    <strong>To:</strong>
                  </p>
                  <div className="row">
                    <div className="col-xs-12 col-sm-4 col-md-4">
                      <select
                        className="form-control mt-3"
                        id="monthTo"
                        name="monthTo"
                        required
                      >
                        <option value="">Month</option>
                        <option value="01">Jan</option>
                        <option value="02">Feb</option>
                        <option value="03">Mar</option>
                        <option value="04">Apr</option>
                        <option value="05">May</option>
                        <option value="06">Jun</option>
                        <option value="07">Jul</option>
                        <option value="08">Aug</option>
                        <option value="09">Sept</option>
                        <option value="10">Oct</option>
                        <option value="11">Nov</option>
                        <option value="12">Dec</option>
                      </select>
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4">
                      <select
                        className="form-control mt-3"
                        id="dayTo"
                        name="dayTo"
                        required
                      >
                        <option value="">Day</option>
                        <option value="01">01</option>
                        <option value="02">02</option>
                        <option value="03">03</option>
                        <option value="04">04</option>
                        <option value="05">05</option>
                        <option value="06">06</option>
                        <option value="07">07</option>
                        <option value="08">08</option>
                        <option value="09">09</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                        <option value="13">13</option>
                        <option value="14">14</option>
                        <option value="15">15</option>
                        <option value="16">16</option>
                        <option value="17">17</option>
                        <option value="18">18</option>
                        <option value="19">19</option>
                        <option value="20">20</option>
                        <option value="21">21</option>
                        <option value="22">22</option>
                        <option value="23">23</option>
                        <option value="24">24</option>
                        <option value="25">25</option>
                        <option value="26">26</option>
                        <option value="27">27</option>
                        <option value="28">28</option>
                        <option value="29">29</option>
                        <option value="30">30</option>
                        <option value="31">31</option>
                      </select>
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-3">
                      <input
                        type="text"
                        id="yearTo"
                        name="yearTo"
                        className="form-control input-sm mt-3"
                        size="4"
                        maxLength="4"
                        placeholder="YYYY"
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 text-center">
              <button
                id="message-submit"
                type="submit"
                className="btn btn-primary"
                form="timeOff"
              >
                <span className="glyphicon glyphicon-send" /> Save
              </button>
              <button
                data-dismiss="modal"
                type="button"
                className="btn btn-default"
                onClick={handleClose}
              >
                <span className="glyphicon glyphicon-remove-circle" /> Cancel
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default TimeOffModal;
