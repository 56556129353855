
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { update } from '../../../actions/candidates';
import fetchSpecialties from '../../../actions/specialtiesOptions';
import Section from '../../../components/Candidate/Main/Section';
import Autosuggest from '../../../components/Autosuggest';
import {
  TextField,
  DatePickerField,
  SelectField,
} from '../../../components/FormFields';
import { getCandidateData, getSpecialties } from '../../../selectors';
import RemoveButton from '../../../components/Candidate/Main/RemoveButton/RemoveButton';
import DesiredShifts from '../../../components/Candidate/Main/DesiredShifts/DesiredShifts';
import EmploymentType from '../../../components/Candidate/Main/EmploymentType/EmploymentType';
import {
  LOG_MESSAGES,
  LOCATION_LOOKUP_URL,
  ACCESS_LVL,
} from '../../../constants';
import TimeOff from '../../../components/Candidate/Main/TimeOff/TimeOff';

const availableOptions = [
  { id: 1, label: '-' },
  { id: 2, label: 'Starting On:' },
  { id: 3, label: '1-2 Weeks After Offer' },
  { id: 4, label: '2-4 Weeks After Offer' },
  { id: 5, label: '4-6 Weeks After Offer' },
  { id: 6, label: 'Negotiable' },
];

const jobStatusOptions = [
  { id: 1, label: '-' },
  { id: 2, label: 'Actively Seeking' },
  { id: 3, label: 'Considering Options' },
  { id: 4, label: 'Unavailable' },
];

export class CandidateMainPage extends Component {
  state = {
    desiredLocationTags: this.props.candidate.getAvailability().getLocations(),
    desiredSpecialtiesTags: this.props.candidate
      .getAvailability()
      .getSpecialties(),
    isAvail:
      this.props.candidate.getAvailability().getAvailable() === 'Starting On:',
    desiredShifts: this.props.candidate.getAvailability().getShifts(),
    employmentTypes: this.props.candidate
      .getAvailability()
      .getEmploymentTypes(),
    timeOff: this.props.candidate.getTimeOff(),
    primaryAddressError: false,
    temporaryAddressError: false,
    // address: {
    //   primaryCityState: this.props.candidate.getPrimaryAddress().getCityState(),
    // },
  };

  componentDidMount() {
    this.props.fetchSpecialties(this.props.candidate.getOccupationId());
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      desiredLocationTags: nextProps.candidate.getAvailability().getLocations(),
      desiredSpecialtiesTags: nextProps.candidate
        .getAvailability()
        .getSpecialties(),
      isAvail:
        nextProps.candidate.getAvailability().getAvailable() === 'Starting On:',
      desiredShifts: nextProps.candidate.getAvailability().getShifts(),
      employmentTypes: nextProps.candidate
        .getAvailability()
        .getEmploymentTypes(),
      timeOff: nextProps.candidate.getTimeOff(),
      // address: {
      //   primaryCityState: nextProps.candidate.getPrimaryAddress().getCityState(),
      // },
    });
  }

  onLocationTagSelect = (tag) => {
    this.props.candidate.getAvailability().addLocations(tag);
    this.setState(this.state);
  };

  onLocationTagRemove = (tag) => {
    this.props.candidate.getAvailability().removeLocations(tag);
    this.setState(this.state);
  };

  onSpecialtiesTagSelect = (tag) => {
    this.props.candidate.getAvailability().addSpecialties(tag);
    this.setState(this.state);
  };

  onSpecialtiesTagRemove = (tag) => {
    this.props.candidate.getAvailability().removeSpecialties(tag);
    this.setState(this.state);
  };

  update = (timeOff) => {
    this.setState({ timeOff });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.update(LOG_MESSAGES.PERSONAL_CONTACT_INFORMATION_MODIFIED);
  };

  handleFirstNameChange = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (e.target.value === '') {
      return;
    }
    this.props.candidate.setFirstName(e.target.value);
  };

  handleLastNameChange = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (e.target.value === '') {
      return;
    }
    this.props.candidate.setLastName(e.target.value);
  };

  handleCandidateChange = (e, field) => {
    if (e) {
      e.preventDefault();
    }
    this.props.candidate.setProperty(field, e.target.value);
  };

  handlePrimaryAddressChange = (e, field) => {
    if (e) {
      e.preventDefault();
    }
    this.props.candidate.setPrimaryAddress(field, e.target.value);
  };

  handlePrimaryCityChange = (e) => {
    const props = e.value.split(',');
    const city = props[0] || '';
    let region = '';
    if (props[1].includes(' ')) {
      const tmpData = props[1].trim().split(' ');
      region = tmpData[0] || '';
    } else {
      region = props[1] || '';
    }

    this.props.candidate.setPrimaryAddress('city', city);
    this.props.candidate.setPrimaryAddress('region', region);
  };

  handleTemporaryCityChange = (e) => {
    const props = e.value.split(',');
    const city = props[0] || '';
    let region = '';
    if (props[1].includes(' ')) {
      const tmpData = props[1].trim().split(' ');
      region = tmpData[0] || '';
    } else {
      region = props[1] || '';
    }

    this.props.candidate.setTemporaryAddress('city', city);
    this.props.candidate.setTemporaryAddress('region', region);
  };

  handleTemporaryAddressChange = (e, field) => {
    if (e) {
      e.preventDefault();
    }
    this.props.candidate.setTemporaryAddress(field, e.target.value);
  };

  handleDateChange = (name, date) => {
    this.props.candidate.setProperty(name, date);
  };

  handleAvailDateChange = (name, date) => {
    this.props.candidate.getAvailability().setProperty(name, date);
  };

  handleEmergencyContactChange = (e, field) => {
    e.preventDefault();
    this.props.candidate
      .getEmergencyContact()
      .setProperty(field, e.target.value);
  };

  handleAvailabilityChange = (e, field) => {
    if (field === 'available') {
      this.setState({
        isAvail: e.target.value === 'Starting On:',
      });
    }
    this.props.candidate.getAvailability().setProperty(field, e.target.value);
  };

  handleDesiredShiftsChange = (e) => {
    this.props.candidate
      .getAvailability()
      .switchDesiredShifts(e.target.name, e.target.checked);
    this.setState(this.state);
  };

  handleEmploymentTypesChange = (e) => {
    this.props.candidate
      .getAvailability()
      .switchEmploymentTypes(e.target.name, e.target.checked);
    this.setState(this.state);
  };

  validatePrimaryLocation = () => {
    this.setState({ primaryAddressError: false });
  };

  changePrimaryAddress = (value) => {
    this.setState({ primaryAddressError: true });
    if (value !== '') {
      this.setState({ primaryAddressError: true });
    } else {
      this.setState({ primaryAddressError: false });
    }
  };

  validateTemporaryLocation = () => {
    this.setState({ temporaryAddressError: false });
  };

  changeTemporaryAddress = (value) => {
    if (value !== '') {
      this.setState({ temporaryAddressError: true });
    } else {
      this.setState({ temporaryAddressError: false });
    }
  };

  render() {
    const {
      handleSubmit,
      onSpecialtiesTagSelect,
      onSpecialtiesTagRemove,
      onLocationTagSelect,
      onLocationTagRemove,
      validatePrimaryLocation,
      changePrimaryAddress,
      validateTemporaryLocation,
      changeTemporaryAddress,
      state: {
        desiredSpecialtiesTags,
        desiredLocationTags,
        desiredShifts,
        employmentTypes,
        isAvail,
        primaryAddressError,
        temporaryAddressError,
      },
      props: { candidate, specialties, access },
    } = this;

    // console.log('render', candidate);

    return (
      <form key={candidate.url} onSubmit={handleSubmit}>
        <Helmet>
          <title>{`${candidate.getName()} Personal Information`}</title>
        </Helmet>
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 text-center">
            <div className="add-edit-btn">
              <div className="form-group">
                <input
                  type="submit"
                  value="Save Changes"
                  className="btn btn-primary"
                  disabled={access === ACCESS_LVL.EXPIRED}
                />
              </div>
            </div>
          </div>
        </div>
        <Section title="Personal Contact Information">
          <div className="row">
            <div className="col-xs-12 col-sm-6 col-md-6">
              <TextField
                label="First Name"
                required
                value={candidate.getFirstName()}
                onChange={(e) => {
                  this.handleFirstNameChange(e);
                }}
              />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6">
              <TextField
                required
                label="Last Name"
                value={candidate.getLastName()}
                onChange={(e) => {
                  this.handleLastNameChange(e);
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-6 col-md-6">
              <TextField
                label="Social Security Number"
                value={candidate.getSSN()}
                onChange={(e) => {
                  this.handleCandidateChange(e, 'ssn');
                }}
              />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6">
              <div className="form-group">
                <label htmlFor="date">Date of Birth</label>
                <DatePickerField
                  name="dob"
                  selected={candidate.getDOB()}
                  onChange={this.handleDateChange}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-6 col-md-6">
              <TextField
                label="Professional Designations"
                value={candidate.getDesignations()}
                onChange={(e) => {
                  this.handleCandidateChange(e, 'designations');
                }}
              />
            </div>
          </div>
        </Section>
        <Section title="Permanent Address" isMainSection={false}>
          <div className="row">
            <div className="col-xs-12 col-sm-6 col-md-6">
              <TextField
                label="Address Line 1"
                value={candidate.getPrimaryAddress().getAddressLine1()}
                onChange={(e) => {
                  this.handlePrimaryAddressChange(e, 'addressLine1');
                }}
              />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6">
              <TextField
                label="Address Line 2"
                value={candidate.getPrimaryAddress().getAddressLine2()}
                onChange={(e) => {
                  this.handlePrimaryAddressChange(e, 'addressLine2');
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-6 col-md-6">
              <Autosuggest
                onSelect={(e) => this.handlePrimaryCityChange(e)}
                htmlFor="City-State-Province"
                value={candidate.getPrimaryAddress().getCityState()}
                label="City, State/Province"
                minLength={3}
                source={LOCATION_LOOKUP_URL}
                shouldClean={false}
                validate={validatePrimaryLocation}
                onChange={(value) => changePrimaryAddress(value)}
                hasError={primaryAddressError}
              />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6">
              <TextField
                label="Postal Code"
                value={candidate.getPrimaryAddress().getCode()}
                onChange={(e) => {
                  this.handlePrimaryAddressChange(e, 'postalCode');
                }}
              />
            </div>
          </div>
        </Section>
        <Section
          title="Temporary Address"
          isMainSection={false}
          lineBelow={false}
        >
          <div className="row">
            <div className="col-xs-12 col-sm-6 col-md-6">
              <TextField
                label="Temp. Address Line 1"
                value={candidate.getTemporaryAddress().getAddressLine1()}
                onChange={(e) => {
                  this.handleTemporaryAddressChange(e, 'addressLine1');
                }}
              />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6">
              <TextField
                label="Temp. Address Line 2"
                value={candidate.getTemporaryAddress().getAddressLine2()}
                onChange={(e) => {
                  this.handleTemporaryAddressChange(e, 'addressLine2');
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-6 col-md-6">
              <Autosuggest
                onSelect={(e) => this.handleTemporaryCityChange(e)}
                htmlFor="City-State-Province"
                value={candidate.getTemporaryAddress().getCityState()}
                label="City, State/Province"
                minLength={3}
                source={LOCATION_LOOKUP_URL}
                shouldClean={false}
                validate={validateTemporaryLocation}
                onChange={(value) => changeTemporaryAddress(value)}
                hasError={temporaryAddressError}
              />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6">
              <TextField
                label="Temp. Postal Code"
                value={candidate.getTemporaryAddress().getCode()}
                onChange={(e) => {
                  this.handleTemporaryAddressChange(e, 'postalCode');
                }}
              />
            </div>
          </div>
        </Section>
        <Section>
          <div className="row">
            <div className="col-xs-12 col-sm-6 col-md-6">
              <TextField
                label="Primary Email"
                value={candidate.getPrimaryEmail()}
                onChange={(e) => {
                  this.handleCandidateChange(e, 'primaryEmail');
                }}
              />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6">
              <TextField
                label="Secondary Email"
                value={candidate.getSecondaryEmail()}
                onChange={(e) => {
                  this.handleCandidateChange(e, 'secondaryEmail');
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-4 col-md-4">
              <TextField
                label="Main Phone"
                value={candidate.getPhone()}
                onChange={(e) => {
                  this.handleCandidateChange(e, 'mainPhone');
                }}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4">
              <TextField
                label="Alt Phone"
                value={candidate.getPrimaryAddress().getPhone2()}
                onChange={(e) => {
                  this.handlePrimaryAddressChange(e, 'phone2');
                }}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4">
              <TextField
                label="Fax"
                value={candidate.getPrimaryAddress().getFax()}
                onChange={(e) => {
                  this.handlePrimaryAddressChange(e, 'fax');
                }}
              />
            </div>
          </div>
        </Section>
        <Section title="Emergency Contact Information">
          <div className="row">
            <div className="col-xs-12 col-sm-6 col-md-6">
              <TextField
                label="First Name"
                value={candidate.getEmergencyContact().getFirstName()}
                onChange={(e) => {
                  this.handleEmergencyContactChange(e, 'firstName');
                }}
              />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6">
              <TextField
                label="Last Name"
                value={candidate.getEmergencyContact().getLastName()}
                onChange={(e) => {
                  this.handleEmergencyContactChange(e, 'lastName');
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-6 col-md-6">
              <TextField
                label="Relationship"
                value={candidate.getEmergencyContact().getRelationship()}
                onChange={(e) => {
                  this.handleEmergencyContactChange(e, 'relationship');
                }}
              />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6">
              <TextField
                label="Phone"
                value={candidate.getEmergencyContact().getPhone()}
                onChange={(e) => {
                  this.handleEmergencyContactChange(e, 'phone');
                }}
              />
            </div>
          </div>
        </Section>
        <Section title="Availability Settings" lineBelow={false}>
          <div className="row">
            <div className="col-xs-12 col-sm-6 col-md-6">
              <SelectField
                id="job-status"
                name="jobSearchStatus"
                label="Job Search Status"
                options={jobStatusOptions}
                selected={candidate.getAvailability().getJobSearchStatus()}
                onChange={this.handleAvailabilityChange}
              />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6">
              <SelectField
                id="available-status"
                name="available"
                label="Available"
                options={availableOptions}
                selected={candidate.getAvailability().getAvailable()}
                onChange={this.handleAvailabilityChange}
              />
              {isAvail && (
                <div className="form-group">
                  <label htmlFor="date">Date Selector:</label>
                  <DatePickerField
                    name="availabilityDate"
                    selected={candidate.getAvailability().getDate()}
                    onChange={this.handleAvailDateChange}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-6 col-md-6">
              <Autosuggest
                onSelect={onLocationTagSelect}
                htmlFor="desired-locations"
                label="Desired location(s)"
                minLength={3}
                source={LOCATION_LOOKUP_URL}
              />
              {desiredLocationTags.map((tag, index) => (
                <RemoveButton
                  id={index}
                  key={`${tag.name}-${tag.region}`}
                  label={`${tag.nameAccented}, ${tag.region}`}
                  removeTag={onLocationTagRemove}
                />
              ))}
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6">
              <SelectField
                id="desired-specialties"
                name="desired-specialties"
                label="Desired specialties:"
                options={specialties}
                onChange={onSpecialtiesTagSelect}
              />
              {desiredSpecialtiesTags.map((tag, index) => (
                <RemoveButton
                  id={index}
                  key={`${tag.name}-${tag.acronym}`}
                  label={`${tag.acronym ? `${tag.acronym} -` : ''} ${tag.name}`}
                  removeTag={onSpecialtiesTagRemove}
                />
              ))}
            </div>
          </div>
          <DesiredShifts
            checked={desiredShifts}
            onChange={this.handleDesiredShiftsChange}
          />
          <EmploymentType
            checked={employmentTypes}
            onChange={this.handleEmploymentTypesChange}
          />
        </Section>
        <TimeOff
          candidate={candidate}
          timeOff={this.state.timeOff}
          update={this.update}
          access={access}
        />
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 text-center">
            <div className="add-edit-btn">
              <div className="form-group">
                <input
                  type="submit"
                  value="Save Changes"
                  className="btn btn-primary"
                  disabled={access === ACCESS_LVL.EXPIRED}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => ({
  candidate: getCandidateData(state),
  specialties: getSpecialties(state),
  access: state.globalInfo.access,
});

export default connect(mapStateToProps, { update, fetchSpecialties })(
  CandidateMainPage,
);
