import React from 'react';

const EditRow = ({
  editButton,
  children,
  wrapButtons = false,
  extraSpaceBelow = false,
}) => {
  const textCols = wrapButtons ? 12 : 8;
  const buttonCols = wrapButtons ? 12 : 4;
  const extraSpaceBelowClass = extraSpaceBelow ? ' mb-20' : '';

  return (
    <div className={`row${extraSpaceBelowClass}`}>
      <div className={`col-xs-${textCols} col-sm-${textCols} col-md-8`}>
        <div className="details-type">{children}</div>
      </div>
      <div className={`col-xs-${buttonCols} col-sm-${buttonCols} col-md-4`}>
        {editButton}
      </div>
    </div>
  );
};

export default EditRow;
