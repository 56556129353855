/* eslint-disable jsx-a11y/label-has-for */

import React from 'react';
import moment from 'moment';
import Modal from '../../../Modal';
import ReferenceCheckContent from './ReferenceCheckContent';
import { LOG_MESSAGES, ACCESS_LVL } from '../../../../constants';

class ReferenceCheckModal extends Modal.Manager {
  render() {
    const {
      handleClose,
      handleShow,
      state: { showModal, visible, modalData },
      props: { documents, update, user, access },
    } = this;

    return (
      <span>
        <button
          type="button"
          className="btn btn-warning"
          onClick={handleShow}
          disabled={access === ACCESS_LVL.EXPIRED}
        >
          Add New Reference Check
        </button>
        {showModal && (
          <ModalComponent
            visible={visible}
            handleClose={handleClose}
            documents={documents}
            update={update}
            user={user}
            modalData={modalData}
          />
        )}
      </span>
    );
  }
}

class ModalComponent extends ReferenceCheckContent {
  onSubmit = () => {
    const {
      props: { handleClose, documents, update, user },
      state,
    } = this;

    const newReference = {
      ...state,
      lastEditedBy: user,
      id: moment().valueOf(),
    };

    documents.addReferenceCheck(newReference);
    update(LOG_MESSAGES.NEW_REFERENCE_ADDED);
    handleClose();
  };
}

export default ReferenceCheckModal;
