import React from 'react';

const match = /(<([^>]+)>)/gi;

const removeHtml = (string = '') => {
  const toString = `${string}`;
  return toString.replace(match, '');
};

const TextField = ({
  id,
  name,
  label,
  value,
  placeholder,
  disabled,
  required,
  onChange = () => {},
  validate = () => true,
}) => (
  <div className={`form-group ${validate() ? '' : 'has-error'}`}>
    <label htmlFor={id}>{label}:</label>
    <input
      type="text"
      className="form-control"
      name={name}
      id={id}
      placeholder={placeholder}
      defaultValue={removeHtml(value)}
      disabled={disabled}
      onChange={onChange}
      required={required}
    />
  </div>
);

export default TextField;
