import React from 'react';
import { Link } from 'react-router-dom';
import { TextField, RichEditorField } from '../../../../FormFields';
import FormWrapper from '../../../../FormWrapper';

const experienceOptions = [
  { value: '', label: '-' },
  { value: '0', label: '0-1' },
  { value: '1', label: '1-2' },
  { value: '2', label: '2-3' },
  { value: '3', label: '3-5' },
  { value: '5', label: '5+' },
];

class ComputerSkillForm extends FormWrapper {
  constructor() {
    super();

    this.state = {
      name: '',
      details: '',
      experience: '',
    };
    window.scrollTo(0, 0);
  }

  render() {
    const {
      onInputFieldChange,
      onSubmit = () => {},
      onRemove = () => {},
      props: { title, edit = false, candidateUrl },
      state: { name, details, experience },
    } = this;

    return (
      <div className="panel panel-default">
        <div className="panel-heading">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12">
              <div className="text-large">{title}</div>
            </div>
          </div>
        </div>
        <div className="panel-body">
          <form onSubmit={onSubmit}>
            <div className="row">
              <div className="col-xs-12 col-sm-8 col-md-8">
                <TextField
                  required
                  label="Name"
                  name="name"
                  id="name"
                  value={name}
                  onChange={onInputFieldChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-8 col-md-8">
                <RichEditorField
                  id="details"
                  name="details"
                  label="Details"
                  content={details}
                  onChange={this.onRichEditorChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-8 col-md-8">
                <div className="form-group">
                  <label htmlFor="years-experience">Years of Experience:</label>
                  <select
                    className="form-control mt-3"
                    id="experience"
                    name="experience"
                    value={experience}
                    onChange={onInputFieldChange}
                  >
                    {experienceOptions.map(({ value, label }) => (
                      <option key={`experience-option-${value}`} value={value}>
                        {label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 text-center">
                <div className="form-group mt-20">
                  <button type="submit" className="btn btn-primary mt-5 mb-3">
                    Save
                  </button>
                  <Link
                    to={`/${candidateUrl}/profile`}
                    className="btn btn-warning mt-5 mb-3"
                  >
                    Cancel
                  </Link>
                  {edit && (
                    <button
                      className="btn btn-danger mt-5 mb-3"
                      onClick={onRemove}
                    >
                      Remove
                    </button>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default ComputerSkillForm;
