import React from 'react';
import Table from './Table';
import TableRow from './TableRow';
import { EditReferenceCheckModal } from '../ReferenceCheckModals';

const ReferenceCheckTable = ({
  columns,
  referenceChecks = [],
  docType,
  documents,
  update,
  handleDeleteReference,
  user,
  onReferenceCheckChange,
  access,
}) => (
  <Table columns={columns}>
    {referenceChecks.length > 0 &&
      referenceChecks.map((check) => (
        <TableRow
          key={check.id}
          id={check.id}
          name={check.getTitle()}
          editedBy={check.lastEditedBy}
          date={check.getModifiedDate()}
          onDelete={() => handleDeleteReference(check.id)}
          title={check.referenceTitle}
          muted={check.referencePhone}
          onChange={onReferenceCheckChange}
          access={access}
          buttons={[
            <EditReferenceCheckModal
              data={check}
              namespace={`edit-cover-letter-button-${check.id}`}
              docType={docType}
              documents={documents}
              update={update}
              user={user}
            />,
          ]}
        />
      ))}
  </Table>
);

export default ReferenceCheckTable;
