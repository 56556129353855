/* eslint-disable no-nested-ternary */

import React from 'react';
import SearchArea from '../SearchArea';
import FindUpdates from '../FindUpdates';
import Contact from '../Contact';
import ResultsNumber from './ResultsNumber';
import Pagination from '../Pagination';
import SearchLabel from './SearchLabel';

const Candidates = ({
  page,
  numberOfConnections,
  candidates,
  resultsPerPage,
  query,
  url,
  access,
  showOnlyUpdates,
  toggleUpdates,
}) => (
  <div>
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-12">
        <div className="row">
          <div className="col-xs-12 col-sm-5 col-md-4">
            <SearchArea />
            <FindUpdates
              showOnlyUpdates={showOnlyUpdates}
              toggleUpdates={toggleUpdates}
              access={access}
            />
          </div>
          <div className="col-xs-12 col-sm-7 col-md-8">
            <ResultsNumber
              currentPage={page}
              resultsPerPage={resultsPerPage}
              totalResultsCount={numberOfConnections}
            />
            {query && <SearchLabel query={query} />}
            {candidates.length > 0 ? (
              candidates.map((candidate) => (
                <Contact
                  key={candidate.getId()}
                  id={candidate.getId()}
                  firstName={candidate.getFirstName()}
                  lastName={candidate.getLastName()}
                  designations={candidate.getDesignations()}
                  phone={candidate.getPhone()}
                  licenseCerts={candidate.getLicensesNames()}
                  specialties={candidate.getAllSpecialties()}
                  photo={candidate.getPhoto()}
                  legacyUrl={candidate.getLegacyUrl()}
                  access={access}
                  updateData={candidate.getUpdates()}
                  showOnlyUpdates={showOnlyUpdates}
                />
              ))
            ) : (
              <h2>There are no results</h2>
            )}
          </div>
        </div>
      </div>
    </div>
    <Pagination
      numberOfItems={numberOfConnections}
      currentPage={page}
      resultsPerPage={resultsPerPage}
      url={url}
    />
  </div>
);

export default Candidates;
