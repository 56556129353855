import axios from 'axios';
import { API_URL } from '../constants';
import {
  FETCH_DOCUMENTS,
  FETCH_DOCUMENTS_SUCCESS,
  FETCH_DOCUMENTS_ERROR,
  UPLOAD_DOCUMENT_SUCCESS,
  PUT_DOCUMENT_SUCCESS,
} from '.';

const fetchDocuments = () => ({
  type: FETCH_DOCUMENTS,
});

const fetchDocumentsSuccess = (data, url) => ({
  type: FETCH_DOCUMENTS_SUCCESS,
  data,
  url,
});

const fetchDocumentsError = (url) => ({
  type: FETCH_DOCUMENTS_ERROR,
  url,
});

const uploadDocumentSuccess = (data) => ({
  type: UPLOAD_DOCUMENT_SUCCESS,
  data,
});

export const uploadDocument = (data) => async (dispatch, getState) => {
  const state = getState();
  const { candidateUrl, token, companyId } = state.globalInfo;

  try {
    const formData = new FormData();
    formData.append('file', data);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.post(
      `${API_URL}documents/${candidateUrl}?companyId=${companyId}`,
      formData,
      config,
    );
    if (response.status === 200) {
      dispatch(uploadDocumentSuccess());
      return true;
    }
  } catch (e) {
    console.error(e);
  }

  return false;
};

const putDocumentSuccess = (data) => ({
  type: PUT_DOCUMENT_SUCCESS,
  data,
});

export const putDocument = (data) => async (dispatch, getState) => {
  const state = getState();
  const { candidateUrl, companyId } = state.globalInfo;
  const config = {
    headers: {
      frontend: 'true',
    },
  };

  try {
    const response = await axios.put(
      `${API_URL}documents/${candidateUrl}?companyId=${companyId}`,
      {
        ...data,
        url: candidateUrl,
      },
      config,
    );
    if (response.status === 200) {
      dispatch(putDocumentSuccess());
    }
  } catch (e) {
    console.error(e);
  }
};

export default () => async (dispatch, getState) => {
  const state = getState();
  const { candidateUrl, companyId } = state.globalInfo;
  const { documents } = state;

  if (documents.isFetching || documents[candidateUrl]) {
    return;
  }

  dispatch(fetchDocuments());
  try {
    const response = await axios.get(
      `${API_URL}documents/${candidateUrl}?companyId=${companyId}`,
    );

    dispatch(fetchDocumentsSuccess(response.data.data, candidateUrl));
  } catch (e) {
    dispatch(fetchDocumentsError(candidateUrl));
    console.error(e);
  }
};
