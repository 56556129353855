import React from 'react';
import Table from './Table';
import TableRow from './TableRow';
import RequestUpdateModal from '../RequestUpdateModal';
import { LOG_MESSAGES } from '../../../../constants';

const ApplicationDocumentsTable = ({
  name,
  columns,
  docType,
  onDeleteStatement,
  onDeleteBackground,
  background,
  statements,
  connected,
  requestUpdate,
  update,
  onBackgroundChange,
  onStatementsChange,
  access,
}) => (
  <Table columns={columns}>
    {background &&
      background.map((b) => (
        <TableRow
          s={b}
          id={b.signatureTimestamp}
          shouldDisplayFourthColumn={false}
          update={update}
          onChange={onBackgroundChange}
          key={b.getDate()}
          docType={docType}
          name="Background Questions"
          onDelete={onDeleteBackground}
          date={b.getDate()}
          access={access}
          buttons={[
            <RequestUpdateModal
              name={name}
              id={b.signedDate}
              namespace={`request-update-button-${b.signedDate}`}
              docType={docType}
              connected={connected}
              update={update}
              requestUpdate={requestUpdate}
              message={LOG_MESSAGES.REQUESTED_UPDATE_OF_BACKGROUND}
            />,
          ]}
        />
      ))}
    {statements &&
      statements.map((s) => (
        <TableRow
          shouldDisplayFourthColumn={false}
          s={s}
          id={s.signatureTimestamp}
          update={update}
          key={s.signatureTimestamp}
          name="Application Signing Statements"
          docType={docType}
          onChange={onStatementsChange}
          onDelete={onDeleteStatement}
          date={s.getDate()}
          access={access}
          buttons={[
            <RequestUpdateModal
              id={s.signatureTimestamp}
              namespace={`request-update-button-${s.signatureTimestamp}`}
              docType={docType}
              update={update}
              requestUpdate={requestUpdate}
              message={LOG_MESSAGES.REQUESTED_UPDATE_OF_APPLICATION_SIGNING}
            />,
          ]}
        />
      ))}
  </Table>
);

export default ApplicationDocumentsTable;
