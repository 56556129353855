import React from 'react';

const Section = ({ iconTitle, editButton, children }) => (
  <div className="row">
    <div className="col-xs-12 col-sm-12 col-md-12">
      <div className="details-container">
        <div className="row">
          <div className="col-xs-7 col-sm-7 col-md-7">{iconTitle}</div>
          <div className="col-xs-5 col-sm-5 col-md-5">{editButton}</div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12">
            <div className="details-content">{children}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Section;
