import React from 'react';
import { connect } from 'react-redux';
import fetchContents from '../../../../actions/contentsOptions';
import { getContents } from '../../../../selectors/index';
import { uploadDocument, putDocument } from '../../../../actions/documents';
import { update } from '../../../../actions/candidates';
import log from '../../../../actions/addLog';

class ModalProvider extends React.Component {
  componentWillMount() {
    this.props.fetchContents(1);
    this.props.fetchContents(2);
    this.props.fetchContents(3);
  }

  render() {
    return React.cloneElement(this.props.children, {
      source: this.props.content,
      uploadDocument: this.props.uploadDocument,
      putDocument: this.props.putDocument,
      update: this.props.update,
      log: this.props.log,
    });
  }
}
const mapStateToProps = (state) => ({
  content: getContents(state),
});

export default connect(mapStateToProps, {
  fetchContents,
  uploadDocument,
  putDocument,
  update,
  log,
})(ModalProvider);
