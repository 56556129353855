import React from 'react';
import { Link } from 'react-router-dom';
import { RichEditorField } from '../../../FormFields';

class EditSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.summary,
    };
    window.scrollTo(0, 0);
  }

  onRichEditorChange = (name, content) => {
    this.setState({ [name]: content });
  };

  handleChange = (e) => {
    this.setState({ value: e.target.value });
  };

  render() {
    return (
      <div className="panel panel-default">
        <div className="panel-heading">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12">
              <div className="text-large">Edit Summary</div>
            </div>
          </div>
        </div>
        <div className="panel-body">
          <form onSubmit={this.props.onSubmit}>
            <RichEditorField
              id="summary"
              name="summary"
              onChange={this.onRichEditorChange}
              content={this.state.value}
            />
            <div className="add-edit-btn">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 text-center">
                  <div className="form-group">
                    <input
                      type="submit"
                      value="Save"
                      className="btn btn-primary"
                    />
                    <Link
                      to={`/${this.props.candidateUrl}/profile`}
                      className="btn btn-warning mt-5 mb-3"
                    >
                      Cancel
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default EditSummary;
