import React, { Component } from 'react';
import { connect } from 'react-redux';
import search from '../../actions/search';
import { history } from '../../store';
import AdvancedSearchModal from '../AdvancedSearch/AdvanceSearchModal';

class SearchArea extends Component {
  state = {
    name: '',
  };

  onSubmit = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    if (e && e.stopPropagation) e.stopPropagation();
    this.props.search({ name: this.state.name });
    history.push('/');
  };

  onChange = (e) => {
    this.setState({ name: e.target.value });
  };

  render() {
    return (
      <div className="panel panel-default bp-panel">
        <div className="panel-heading bp-panel-heading">
          <div className="pull-right">
            <AdvancedSearchModal search={this.props.search} />
          </div>
          <h3 className="panel-title bp-panel-title">Search candidates:</h3>
        </div>
        <div className="panel-body">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12">
              <form onSubmit={this.onSubmit}>
                <div className="form-group">
                  <label htmlFor="name">
                    <span className="bp-label">Name:</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    name="q"
                    id="q"
                    onChange={this.onChange}
                  />
                </div>
                <div className="text-center">
                  <button className="btn btn-block btn-primary" type="submit">
                    Find Candidates
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, { search })(SearchArea);
