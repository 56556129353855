import {
  FETCH_UPDATES,
  FETCH_UPDATES_ERROR,
  FETCH_UPDATES_SUCCESS,
  PUT_UPDATE_SUCCESS,
  REFRESH_UPDATES,
} from '../actions';

const initialState = {
  isFetching: false,
  updateAgain: false,
};

const updates = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_UPDATES:
      return {
        ...state,
        isFetching: true,
      };
    case REFRESH_UPDATES:
      return {
        ...state,
        updateAgain: false,
      };
    case FETCH_UPDATES_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        [action.url]: action.data,
        updateAgain: true,
      };
    }
    case PUT_UPDATE_SUCCESS: {
      const { candidateUrl, timestamp } = action;
      const candidateUpdates = state[candidateUrl].filter(
        (update) => update.timestamp !== timestamp,
      );
      return {
        ...state,
        [candidateUrl]: candidateUpdates,
      };
    }
    case FETCH_UPDATES_ERROR:
      return {
        ...state,
        isFetching: false,
        [action.url]: [],
      };
    default:
      return state;
  }
};

export default updates;
