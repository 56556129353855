import axios from 'axios';
import { API_URL } from '../constants';
import { POST_NOTE_SUCCESS } from '.';
import { showAlert } from './alert';

const postNoteSuccess = (data) => ({
  type: POST_NOTE_SUCCESS,
  data,
});

export default (data) => async (dispatch, getState) => {
  const state = getState();
  const { candidateUrl, userName, token, companyId } = state.globalInfo;

  try {
    const response = await axios.post(
      `${API_URL}notes/${candidateUrl}?companyId=${companyId}`,
      {
        author: userName,
        note: data,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    if (response.status === 201) {
      dispatch(postNoteSuccess());
      dispatch(
        showAlert({
          type: 'success',
          message: 'Successfully posted your note!',
        }),
      );
      window.scrollTo(0, 0);
    }
  } catch (e) {
    console.error(e);
    dispatch(
      showAlert({
        type: 'danger',
        message:
          'Oops! There was a problem. Please try again or contact support.',
      }),
    );
    window.scrollTo(0, 0);
  }
};
