import {
  FETCH_CONNECTIONS_SUCCESS,
  SELECT_CANDIDATE,
  READ_COOKIE,
  SEARCH_CANDIDATES_SUCCESS,
  SEARCH_CANDIDATES_ERROR,
  SEARCH_CANDIDATES_CACHE,
  CANCEL_SEARCH,
  SHOW_ALERT,
  HIDE_ALERT,
  NOT_AUTHORIZED,
  TOGGLE_UPDATES,
} from '../actions';
import { ACCESS_LVL } from '../constants';

const initialState = { showOnlyUpdates: false };

const globalInfo = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CONNECTIONS_SUCCESS:
      return {
        ...state,
      };
    case TOGGLE_UPDATES:
      return {
        ...state,
        showOnlyUpdates: !state.showOnlyUpdates,
      };
    case SELECT_CANDIDATE:
      return {
        ...state,
        candidateUrl: action.data,
      };
    case SEARCH_CANDIDATES_SUCCESS:
      return {
        ...state,
        query: action.data.name,
      };

    case SEARCH_CANDIDATES_CACHE:
      return {
        ...state,
        query: action.data.name,
      };

    case SEARCH_CANDIDATES_ERROR:
      return {
        ...state,
        query: action.name,
      };

    case CANCEL_SEARCH:
      return {
        ...state,
        query: null,
      };

    case SHOW_ALERT:
      return {
        ...state,
        alert: true,
        alertType: action.data.type,
        alertMessage: action.data.message,
      };

    case HIDE_ALERT:
      return {
        ...state,
        alert: false,
        alertType: null,
        alertMessage: '',
      };

    case READ_COOKIE:
      return {
        ...state,
        ...action.data,
        access: action.data.access,
      };

    case NOT_AUTHORIZED:
      return {
        access: ACCESS_LVL.NO_ACCESS,
        message: action.message,
      };
    default:
      return state;
  }
};

export default globalInfo;
