import React from 'react';
import _ from 'lodash';
import { TextField } from '../../../../FormFields';
import FormWrapper from '../../../../FormWrapper';
import { Supervisor } from '../../../../../models/Candidate';
import NotFound from '../../../../NotFound/NotFound';

class SupervisorForm extends FormWrapper {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);

    this.state = new Supervisor();
    this.state = props.fieldValues.supervisor;
  }

  remove = () => {
    this.props.saveSupervisor({});
  };

  onSupervisorSubmit = (e) => {
    e.preventDefault();
    this.props.saveSupervisor(this.state);
  };

  onInputFieldChange = ({ target: { name, type }, target }) => {
    const value = type === 'checkbox' ? target.checked : target.value;

    this.setState({ [name]: value });
  };

  render() {
    const {
      props: { title, thirdStep },
      state,
      remove,
      onInputFieldChange,
      onSupervisorSubmit,
    } = this;

    if (state === null) {
      return <NotFound />;
    }

    return (
      <div className="panel panel-default">
        <div className="panel-heading">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12">
              <div className="text-large">{`${title} - Supervisor`}</div>
            </div>
          </div>
        </div>
        <div className="panel-body">
          <form onSubmit={onSupervisorSubmit}>
            <div className="row">
              <div className="col-xs-12 col-sm-8 col-md-8">
                <TextField
                  label="Supervisor Name"
                  name="name"
                  id="supervisorName"
                  value={state.name}
                  onChange={onInputFieldChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-8 col-md-8">
                <TextField
                  label="Title"
                  name="title"
                  id="supervisorTitle"
                  value={state.title}
                  onChange={onInputFieldChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-8 col-md-8">
                <TextField
                  label="Phone"
                  name="phone"
                  id="supervisorPhone"
                  value={state.phone}
                  onChange={onInputFieldChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-8 col-md-8">
                <TextField
                  label="Email"
                  name="email"
                  id="supervisorEmail"
                  value={state.email}
                  onChange={onInputFieldChange}
                />
              </div>
            </div>
            <div className="add-edit-btn">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 text-center">
                  <div className="form-group mt-20">
                    <button type="submit" className="btn btn-primary mt-5 mb-3">
                      Save
                    </button>
                    <button
                      onClick={() => {
                        thirdStep(this.state, false);
                      }}
                      className="btn btn-warning mt-5 mb-3"
                    >
                      Cancel
                    </button>
                    {!_.isEmpty(this.props.fieldValues.supervisor) && (
                      <button
                        className="btn btn-danger mt-5 mb-3"
                        onClick={remove}
                      >
                        Remove
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default SupervisorForm;
