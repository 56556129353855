/* eslint-disable object-curly-newline */
import React from 'react';
import ModalForRow from './ModalForRow';

const getShortText = (text) => {
  if (!text) return '';
  if (text.length <= 140) {
    return text;
  }

  return `${text.substring(0, 140)}...`;
};

const SimpleTableRow = ({ note, author, date, action }) => (
  <tr>
    <td className="vAlign col-sm-7">
      <div>{getShortText(note)}</div>
    </td>
    <td className="vAlign col-sm-2">{author}</td>
    <td className="vAlign col-sm-2">{date}</td>
    {action && (
      <td className="vAlign col-sm-1">
        <div className="btn-group">
          <ModalForRow note={note} />
        </div>
      </td>
    )}
  </tr>
);

export default SimpleTableRow;
