/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { Component } from 'react';
import { Link } from 'react-router-dom';

const LEFT_PAGE = 'LEFT';
const RIGHT_PAGE = 'RIGHT';
/*
 * Helper method for creating a range of numbers
 */
const range = (from, to, step = 1) => {
  let i = from;
  const range = [];
  while (i <= to) {
    range.push(i);
    i += step;
  }
  return range;
};

export class PaginationComponent extends Component {
  render() {
    const {
      numberOfItems,
      resultsPerPage = 10,
      pageNeighbours = 2,
      url,
    } = this.props;
    let { currentPage = 1 } = this.props;

    const numberOFLinks = Number.isInteger(numberOfItems / resultsPerPage)
      ? numberOfItems / resultsPerPage
      : 1 + Math.ceil(parseInt(numberOfItems / resultsPerPage, 10));

    if (numberOfItems <= resultsPerPage) {
      return '';
    }

    const fetchPageNumbers = () => {
      /**
       * totalNumbers: the total page numbers to show on the control
       * totalBlocks: totalNumbers to cover for the left(<) and right(>) controls
       */
      const totalNumbers = pageNeighbours * 2 + 1;
      const totalBlocks = totalNumbers;

      if (numberOFLinks > totalBlocks) {
        const startPage = Math.max(2, currentPage - pageNeighbours);
        const endPage = Math.min(
          numberOFLinks - 1,
          currentPage + pageNeighbours,
        );
        let pages = range(startPage, endPage);

        /**
         * hasLeftSpill: has hidden pages to the left
         * hasRightSpill: has hidden pages to the right
         * spillOffset: number of hidden pages either to the left or to the right
         */
        const hasLeftSpill = startPage > 2;
        const hasRightSpill = numberOFLinks - endPage > 1;
        const spillOffset = totalNumbers - (pages.length + 1);

        switch (true) {
          // handle: (1) < {5 6} [7] {8 9} (10)
          case hasLeftSpill && !hasRightSpill: {
            const extraPages = range(startPage - spillOffset, startPage - 1);
            pages = [LEFT_PAGE, ...extraPages, ...pages];
            break;
          }

          // handle: (1) {2 3} [4] {5 6} > (10)
          case !hasLeftSpill && hasRightSpill: {
            const extraPages = range(endPage + 1, endPage + spillOffset);
            pages = [...pages, ...extraPages, RIGHT_PAGE];
            break;
          }

          // handle: (1) < {4 5} [6] {7 8} > (10)
          case hasLeftSpill && hasRightSpill:
          default: {
            pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
            break;
          }
        }

        return [1, ...pages, numberOFLinks];
      }
      return range(1, numberOFLinks);
    };

    const pages = fetchPageNumbers();

    const paginationTile = (index, page) => {
      const html = [];
      if (currentPage < 1) currentPage = 1;
      if (currentPage > numberOFLinks) currentPage = numberOFLinks;
      html.push(
        <li key={index}>
          <Link
            className={currentPage === page ? 'active' : ''}
            to={{
              pathname: `${url}`,
              search: `?page=${page}`,
            }}
          >
            {page}
          </Link>
        </li>,
      );

      return html;
    };

    const paginationButton = (index, buttonName, pageNoDisplay, pageNo) => {
      const html = [];
      html.push(
        <li
          key={index}
          className={currentPage === pageNoDisplay ? 'disabled' : ''}
        >
          <Link
            to={{
              pathname: `${url}`,
              search: `?page=${pageNo}`,
            }}
          >
            {buttonName}
          </Link>
        </li>,
      );
      return html;
    };

    return (
      <div className="row">
        <div className="col-md-12 text-center">
          <nav>
            <ul className="pagination pagination-lg bp-pagination">
              {pages.map((page, index) => {
                if (page === LEFT_PAGE)
                  return paginationButton(
                    index,
                    'Previous',
                    1,
                    currentPage - 1,
                  );
                if (page === RIGHT_PAGE)
                  return paginationButton(
                    index,
                    'Next',
                    numberOFLinks,
                    currentPage + 1,
                  );
                return paginationTile(index, page);
              })}
            </ul>
          </nav>
        </div>
      </div>
    );
  }
}
export default PaginationComponent;
