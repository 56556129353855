/* eslint-disable react/prefer-stateless-function */

import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';
import store from './store';
import Content from './containers/Content';

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <CookiesProvider>
          <Content />
        </CookiesProvider>
      </Provider>
    );
  }
}
export default App;
