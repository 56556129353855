import axios from 'axios';
import { API_URL } from '../constants';
import { GENERATE_PDF_SUCCESS } from '.';
import createEvent from './addLog';
import { showAlert } from './alert';

const generateSuccess = () => ({
  type: GENERATE_PDF_SUCCESS,
});

export default (data) => async (dispatch, getState) => {
  const state = getState();
  const {
    candidateUrl,
    userName,
    userEmail,
    companyName,
    phone,
    token,
    companyId,
  } = state.globalInfo;

  try {
    const response = await axios.post(
      `${API_URL}export/${candidateUrl}?companyId=${companyId}`,
      {
        ...data,
        userName,
        userEmail,
        companyName,
        phone,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );

    if (response.status === 200) {
      dispatch(generateSuccess());
      dispatch(createEvent());
      dispatch(
        showAlert({
          type: 'success',
          message:
            'Documents queued for creation. Download Link will be available on “Log” page shortly.',
        }),
      );
      window.scrollTo(0, 0);
    }
  } catch (e) {
    console.error(e);
    dispatch(
      showAlert({
        type: 'danger',
        message:
          'Oops! There was a problem. Please try again or contact support.',
      }),
    );
    window.scrollTo(0, 0);
  }
};
