/* eslint-disable no-nested-ternary */

import _ from 'lodash';

const getQuery = (url, variable) => {
  if (!url) {
    return 1;
  }
  const params = url.split('?');
  const pairs = params[1].split('&');
  for (let i = 0; i < pairs.length; i += 1) {
    const pair = pairs[i].split('=');
    if (pair[0] === variable) {
      return pair[1];
    }
  }
  return 1;
};

export const numberOfItems = (array) =>
  array.map((subArray) => subArray.length).reduce((a, b) => a + b, 0);

export const getCandidateData = (state) =>
  state.candidates[state.globalInfo.candidateUrl];

export const getPage = (state) =>
  parseInt(getQuery(state.routing.location.search, 'page'), 10);

export const getConnections = (state) =>
  state.globalInfo.query
    ? state.connections.search[state.globalInfo.query][getPage(state) - 1]
    : state.connections.candidates[getPage(state) - 1];

export const getConnectionsWithUpdates = (state) =>
  state.globalInfo.query
    ? state.connections.search[state.globalInfo.query][getPage(state) - 1]
    : state.connections.candidatesWithUpdates[getPage(state) - 1];

export const numberOfResults = (state) =>
  state.globalInfo.query
    ? state.connections.search[state.globalInfo.query].reduce(
        (count, row) => count + row.length,
        0,
      )
    : state.connections.candidates.reduce(
        (count, row) => count + row.length,
        0,
      );

export const numberOfResultsWithUpdates = (state) =>
  state.globalInfo.query
    ? state.connections.search[state.globalInfo.query].reduce(
        (count, row) => count + row.length,
        0,
      )
    : state.connections.candidates
        .map((element) =>
          element.filter((intElement) => intElement.updateData.key),
        )
        .reduce((count, row) => count + row.length, 0);

export const getSpecialties = (state) => {
  const candidate = getCandidateData(state);
  return state.specialties.specialties[candidate.getOccupationId()];
};

export const getLicensesAndCerts = (state) => {
  const initialOptions = {
    1: { items: [], bodies: [] },
    2: { items: [], bodies: [] },
    3: { items: [], bodies: [] },
  };

  return _.isEmpty(state.licenseCert.licenseCert)
    ? initialOptions
    : state.licenseCert.licenseCert;
};

export const getContents = (state) => state.contents;

export const getNotes = (state) => {
  const notes = state.notes[state.globalInfo.candidateUrl] || [[]];
  return notes[getPage(state) - 1];
};

export const getNumberOfNotes = (state) =>
  numberOfItems(state.notes[state.globalInfo.candidateUrl] || [[]]);

export const getLogs = (state) => {
  const logs = state.logs[state.globalInfo.candidateUrl] || [[]];
  return logs[getPage(state) - 1];
};

export const getNumberOfLogs = (state) =>
  numberOfItems(state.logs[state.globalInfo.candidateUrl] || [[]]);

export const getNumberOfUpdates = (state) =>
  _.has(state.updates, state.globalInfo.candidateUrl)
    ? state.updates[state.globalInfo.candidateUrl].length
    : 0;

export const getPdfSettings = (state) => state.settings;
