import axios from 'axios';
import { API_URL } from '../constants';
import {
  FETCH_PDF_SETTINGS,
  FETCH_PDF_SETTINGS_SUCCESS,
  FETCH_PDF_SETTINGS_ERROR,
} from '.';

const fetchPdf = (companyId) => ({
  type: FETCH_PDF_SETTINGS,
  companyId,
});

const fetchPdfSettingsSuccess = (data, companyId) => ({
  type: FETCH_PDF_SETTINGS_SUCCESS,
  data,
  companyId,
});

const fetchPdfSettingsError = (companyId) => ({
  type: FETCH_PDF_SETTINGS_ERROR,
  companyId,
});

export default () => async (dispatch, getState) => {
  const state = getState();
  const { companyId, token } = state.globalInfo;
  dispatch(fetchPdf);
  try {
    const response = await axios.get(
      `${API_URL}settings/${companyId}?companyId=${companyId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );

    if (response.status === 200) {
      dispatch(fetchPdfSettingsSuccess(response.data, companyId));
    }
  } catch (e) {
    console.error(e);
    dispatch(fetchPdfSettingsError(companyId));
  }
};
