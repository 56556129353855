import axios from 'axios';
import { API_URL, LOG_MESSAGES } from '../constants';
import {
  FETCH_UPDATES,
  FETCH_UPDATES_SUCCESS,
  FETCH_UPDATES_ERROR,
  PUT_UPDATE_SUCCESS,
  REFRESH_UPDATES,
} from '.';
import createEvent from './addLog';
import { showAlert } from './alert';
import fetchCandidate from './candidates';

const fetchUpdates = () => ({
  type: FETCH_UPDATES,
});

const fetchUpdatesSuccess = (data, url) => ({
  type: FETCH_UPDATES_SUCCESS,
  data,
  url,
});

const fetchUpdatesError = (url) => ({
  type: FETCH_UPDATES_ERROR,
  url,
});

const putUpdateSuccess = (candidateUrl, timestamp) => ({
  type: PUT_UPDATE_SUCCESS,
  candidateUrl,
  timestamp,
});

export const fetchUpdatesAgain = () => ({
  type: REFRESH_UPDATES,
});

export const fetchUpdatesForCandidate =
  (force = false) =>
  async (dispatch, getState) => {
    const state = getState();
    const { candidateUrl, token, companyId } = state.globalInfo;
    const { updates } = state;

    if (!force) {
      if (
        (updates.isFetching || updates[candidateUrl]) &&
        updates.updateAgain
      ) {
        return;
      }
    }

    dispatch(fetchUpdates());
    try {
      const response = await axios.get(
        `${API_URL}updates/${candidateUrl}?companyId=${companyId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      dispatch(fetchUpdatesSuccess(response.data.data, candidateUrl));
    } catch (e) {
      dispatch(fetchUpdatesError(candidateUrl));
    }
  };

export const putUpdateForCandidate =
  (timestamp, actionType) => async (dispatch, getState) => {
    const state = getState();
    const { candidateUrl, token, companyId } = state.globalInfo;

    try {
      const response = await axios.put(
        `${API_URL}updates/${candidateUrl}?companyId=${companyId}`,
        {
          timestamp,
          action_type: actionType,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      if (response.status === 200) {
        dispatch(putUpdateSuccess(candidateUrl, timestamp));
        switch (actionType) {
          case 'update': {
            dispatch(createEvent(LOG_MESSAGES.PROFILE_UPDATE_ACCEPTED));
            dispatch(fetchCandidate(true));
            break;
          }
          case 'cancel': {
            dispatch(createEvent(LOG_MESSAGES.PROFILE_UPDATE_DECLINED));
            break;
          }
          default:
            break;
        }
      }
    } catch (e) {
      dispatch(fetchUpdatesForCandidate(true));
      dispatch(
        showAlert({
          type: 'danger',
          message:
            'Something went wrong. Please try accepting the update again!',
        }),
      );
      window.scrollTo(0, 0);
    }
  };
