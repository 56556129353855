import React from 'react';
import Modal from '../Modal';
import AdvancedSearch from '.';
import { history } from '../../store';

const formToJSON = (elements) =>
  [].reduce.call(
    elements,
    (data, element) => {
      const a = data;
      a[element.name] = element.value;
      return a;
    },
    {},
  );

class AdvancedSearchModal extends Modal.Manager {
  render() {
    const {
      handleClose,
      handleShow,
      state: { showModal, visible },
      props: { onDelete, search },
    } = this;

    return (
      <span>
        <button
          className="btn btn-xs btn-primary btn-no-margin"
          onClick={handleShow}
        >
          Advanced
        </button>
        {showModal && (
          <ModalComponent
            visible={visible}
            handleClose={handleClose}
            onDelete={onDelete}
            search={search}
          />
        )}
      </span>
    );
  }
}

class ModalComponent extends Modal.Manager {
  onSubmit = (e) => {
    e.preventDefault();

    const { handleClose, search } = this.props;
    const object = formToJSON(e.target);
    search(object);
    handleClose();
    history.push('/');
  };

  render() {
    const {
      props: { visible, handleClose },
      onSubmit,
    } = this;

    return (
      <Modal visible={visible} hideComponent={handleClose}>
        <Modal.Header
          title="Advanced search options:"
          hideComponent={handleClose}
        />
        <Modal.Body>
          <AdvancedSearch onSubmit={(e) => onSubmit(e)} />
        </Modal.Body>
      </Modal>
    );
  }
}

export default AdvancedSearchModal;
