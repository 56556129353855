/* eslint-disable import/prefer-default-export */

import { SELECT_CANDIDATE, CANCEL_SEARCH } from '.';

export const selectCandidate = (data) => ({
  type: SELECT_CANDIDATE,
  data,
});

export const cancelSearch = (data) => ({
  type: CANCEL_SEARCH,
  data,
});
