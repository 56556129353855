import React from 'react';
import { AddNewAutosuggestField } from '.';

const AddOrChooseField = ({
  mainName,
  mainAcronym,
  workWithMains,
  editPage,
  title,
  value,
  showCustomForm,
  fieldLabel,
  fieldName,
  children,
  required,
  disabled = false,
  source = [],
  onSelect = () => {},
  onShowCustomForm = () => {},
  onShowAutocomplete = () => {},
  setValueForLeaveHandler = () => {},
}) => (
  <div className="row">
    {showCustomForm ? (
      <div className="col-xs-12 col-sm-9 col-md-9">
        <div className="form-group">
          <strong className="control-label">{fieldLabel}:</strong>
          <button
            className="btn-xs btn-primary ml-20"
            onClick={onShowAutocomplete}
          >
            Change
          </button>
        </div>
        <div className="panel panel-default bp-panel">
          <div className="panel-heading bp-panel-heading">
            <h3 className="panel-title">{title}:</h3>
          </div>
          <div className="panel-body">{children}</div>
        </div>
      </div>
    ) : (
      <div className="col-xs-12 col-sm-9 col-md-9">
        <AddNewAutosuggestField
          required={required}
          source={source}
          label={fieldLabel}
          name={fieldName}
          minLength={1}
          onShowCustomForm={onShowCustomForm}
          setValueForLeaveHandler={setValueForLeaveHandler}
          onSelect={onSelect}
          value={value}
          editPage={editPage}
          mainName={mainName}
          mainAcronym={mainAcronym}
          workWithMains={workWithMains}
          disabled={disabled}
        />
      </div>
    )}
  </div>
);

export default AddOrChooseField;
