import React from 'react';
import ReactAutosuggest from 'react-autosuggest';
import axios, { CancelToken } from 'axios';

const REQUEST_DELAY = 500;

const handleRequestError = (error) => {
  if (error instanceof axios.Cancel) {
    return; // ignore axion.Cancel errors
  }

  console.error(error);
};

class Autosuggest extends React.Component {
  static defaultProps = {
    onSelect: () => {},
    onChange: () => {},
    validate: () => {},
    hasError: false,
    minLength: 1,
    labelKey: 'label',
    queryParam: 'q',
  };

  constructor(props) {
    super(props);

    this.state = {
      value: props.value || '',
      suggestions: [],
    };
  }

  onChange = (event, { newValue }) => {
    const { onChange } = this.props;

    this.setState({
      value: newValue,
    });

    onChange(newValue);
  };

  onSuggestionsFetchRequested = ({ value = '' }) => {
    const { cancelTokenSource, minLength, source } = this.props;
    const delay = typeof source === 'string' ? REQUEST_DELAY : 0;

    clearInterval(this.timer);

    if (cancelTokenSource) {
      cancelTokenSource.cancel(); // cancel previous request
    }

    if (minLength > value.length) {
      return;
    }

    this.timer = setTimeout(() => {
      this.getSuggestions(value);
    }, delay);
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  onSuggestionSelected = (event, { suggestion, suggestionValue, method }) => {
    const { onSelect, validate, shouldClean = true } = this.props;

    if (method === 'enter') {
      event.preventDefault();
    }

    if (shouldClean) {
      this.setState({ value: '' });
    }

    validate();
    onSelect(suggestion, suggestionValue);
  };

  onKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  };

  getSuggestions(value = '') {
    const { source } = this.props;

    if (Array.isArray(source)) {
      // use provided items
      this.setState({ suggestions: this.filterSuggestions(source) });
    } else if (typeof source === 'string') {
      const { value: currentValue } = this.state;

      // request provided url
      this.request(value)
        .then((results) => {
          if (value === currentValue) {
            delete this.cancelTokenSource; // clear own cancelTokenSource
          }

          const suggestions = this.filterSuggestions(results);

          this.setState({ suggestions });
        })
        .catch(handleRequestError);
    }
  }

  getSuggestionValue = (suggestion) => suggestion[this.props.labelKey];

  filterSuggestions = (suggestions) => {
    const {
      state: { value },
      props: { labelKey },
    } = this;

    if (suggestions.length === 0 || !Array.isArray(suggestions)) {
      return [];
    }

    const items = suggestions.filter((suggestion) => {
      const mainParts = suggestion[labelKey]
        .split(' - ')
        .map((part) => part.trim());

      const acronym = mainParts[0] || '';
      const full = mainParts[1] || '';

      const parts = suggestion[labelKey]
        .split(/[\s-]+/)
        .map((part) => part.trim());
      return (
        parts.find(
          (part) => part.toLowerCase().indexOf(value.toLowerCase()) === 0,
        ) ||
        acronym.toLowerCase().indexOf(value.toLowerCase()) === 0 ||
        full.toLowerCase().indexOf(value.toLowerCase()) === 0
      );
    });

    return items;
  };

  async request(query) {
    const { source, queryParam } = this.props;

    this.cancelTokenSource = CancelToken.source();

    const response = await axios.get(`${source}?${queryParam}=${query}`, {
      cancelToken: this.cancelTokenSource.token,
    });

    return response.data;
  }

  renderSuggestion = (suggestion) => (
    <div>{suggestion[this.props.labelKey]}</div>
  );

  render() {
    const {
      onSuggestionSelected,
      onSuggestionsFetchRequested,
      onSuggestionsClearRequested,
      onKeyDown,
      renderSuggestion,
      getSuggestionValue,
      state: { value, suggestions },
      props: {
        htmlFor,
        label,
        placeholder,
        disabled,
        name,
        required,
        hasError,
      },
    } = this;

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      className: 'form-control',
      placeholder,
      value,
      required,
      name,
      onChange: this.onChange,
      onFocus: onSuggestionsFetchRequested,
      onKeyDown,
      onBlur: onSuggestionsClearRequested,
      disabled,
    };

    return (
      <div className={`form-group ${hasError ? 'has-error' : ''}`}>
        <label htmlFor={htmlFor}>
          {label}
          {required ? '*:' : ':'}
        </label>
        <ReactAutosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          onSuggestionSelected={onSuggestionSelected}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
          shouldRenderSuggestions={() => true}
        />
        {hasError && (
          <div className="autosuggest-error">
            Please select from the typeahead
          </div>
        )}
      </div>
    );
  }
}

export default Autosuggest;
