import React from 'react';
import TextField from './TextField';
import Autosuggest from '../Autosuggest/Autosuggest';
import { LOCATION_LOOKUP_URL } from '../../constants';

const LocationInformation = ({
  addressLine1,
  addressLine2,
  region,
  city,
  postalCode,
  onChange = () => {},
  onCityChange = () => {},
  validateCity = () => {},
  hasError,
  handleCityChange,
}) => (
  <div className="row">
    <div className="col-xs-12 col-sm-12 col-md-8 mt-5">
      <div className="panel panel-default bp-panel">
        <div className="panel-heading bp-panel-heading">
          <h3 className="panel-title">Location information:</h3>
        </div>
        <div className="panel-body">
          <TextField
            label="Address Line 1"
            name="address.addressLine1"
            id="addressLine1"
            value={addressLine1}
            onChange={onChange}
          />
          <TextField
            label="Address Line 2"
            name="address.addressLine2"
            id="addressLine2"
            value={addressLine2}
            onChange={onChange}
          />
          <Autosuggest
            onSelect={(e) => handleCityChange(e)}
            htmlFor="City-State-Province"
            value={region ? `${city}, ${region}` : city}
            label="City, State/Province"
            minLength={3}
            source={LOCATION_LOOKUP_URL}
            shouldClean={false}
            validate={validateCity}
            onChange={onCityChange}
            hasError={hasError}
          />
          <TextField
            label="Postal Code"
            name="address.postalCode"
            id="postalCode"
            value={postalCode}
            onChange={onChange}
          />
        </div>
      </div>
    </div>
  </div>
);

export default LocationInformation;
