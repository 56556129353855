import React from 'react';
import { Route } from 'react-router-dom';
import ProfileRoutes from './Profile/ProfileRoutes';
import Documents from '../../containers/Documents';
import Updates from '../../containers/Updates';
import Notes from '../../containers/Notes';
import Log from '../../containers/Log';
import Main from '../../containers/Candidate/Main';

const CandidatePageRoutes = ({ url }) => (
  <div>
    <Route exact path={`${url}/`} component={Main} />
    <Route path={`${url}/profile`} component={ProfileRoutes} />
    <Route path={`${url}/documents`} component={Documents} />
    <Route path={`${url}/updates`} component={Updates} />
    <Route path={`${url}/notes`} component={Notes} />
    <Route path={`${url}/log`} component={Log} />
  </div>
);

export default CandidatePageRoutes;
