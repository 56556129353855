import axios from 'axios';
import { DOMAIN } from '../constants';
import {
  REQUEST_UPDATE,
  REQUEST_UPDATE_SUCCESS,
  REQUEST_UPDATE_ERROR,
} from '.';
import log from './addLog';
import { showAlert } from './alert';

import { getCandidateData } from '../selectors';

const requestUpdate = () => ({
  type: REQUEST_UPDATE,
});

const requestUpdateSuccess = (data) => ({
  type: REQUEST_UPDATE_SUCCESS,
  data,
});

const requestUpdateError = () => ({
  type: REQUEST_UPDATE_ERROR,
});

export default (logType) => async (dispatch, getState) => {
  const state = getState();
  const { candidateUrl, companyId } = state.globalInfo;
  const candidate = getCandidateData(state);
  dispatch(requestUpdate());
  try {
    const response = await axios.get(
      `${DOMAIN}company/${companyId}/candidates/${candidate.id}/update`,
      {
        withCredentials: true,
      },
    );
    if (response.status === 200) {
      dispatch(requestUpdateSuccess(response));
      dispatch(showAlert({ type: 'success', message: 'Update requested.' }));
      window.scrollTo(0, 0);
      if (logType) {
        dispatch(log(logType));
      }
    }
  } catch (e) {
    dispatch(requestUpdateError(candidateUrl));
    window.scrollTo(0, 0);
    dispatch(
      showAlert({
        type: 'danger',
        message:
          'Oops, request not sent. This candidate disconnected their profile from your company.',
      }),
    );
  }
};
