/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */

import React from 'react';
import { withCookies } from 'react-cookie';
import { ChromePicker } from 'react-color';
import Modal from '../../../Modal';

const normalizeBoolean = (value) => {
  if (value === 'true') {
    return true;
  }

  if (value === 'false') {
    return false;
  }

  return value;
};

class EmailSelectedDocumentsModal extends Modal.Manager {
  state = {
    displayColorPicker: false,
    color: this.props.color,
    branding: this.props.branding,
    fontLight: this.props.fontLight,
  };

  componentWillReceiveProps(nextProps) {
    const { branding, color, fontLight } = nextProps;
    this.setState({ branding, color, fontLight });
  }

  togglePicker = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  onChangeComplete = (color) => {
    this.setState({ color: color.hex });
  };

  onInputFieldChange = ({ target: { name, type }, target }) => {
    const value = type === 'checkbox' ? target.checked : target.value;
    this.setState({ [name]: normalizeBoolean(value) });
  };

  onSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }

    const {
      selected: {
        files = [],
        checklists = [],
        referenceChecks = [],
        letters = [],
        statements = [],
        background = [],
        pdfFiles = [],
        type = 0,
      },
      generatePDF,
      cookies,
    } = this.props;

    const params = {
      files,
      referenceChecks,
      letters,
      checklists,
      statements,
      background,
      type,
      pdfFiles,
      color: this.state.color,
      branding: this.state.branding,
      fontLight: this.state.fontLight,
    };

    generatePDF(params, cookies);
    this.handleClose();
  };

  render() {
    const {
      handleClose,
      handleShow,
      state: {
        showModal,
        visible,
        displayColorPicker,
        branding,
        color,
        fontLight,
      },
      togglePicker,
      onChangeComplete,
      onInputFieldChange,
      onSubmit,
      props: {
        selected: {
          files = [],
          referenceChecks = [],
          letters = [],
          statements = [],
          background = [],
          checklists = [],
          pdfFiles = [],
          type = 0,
        },
      },
    } = this;

    const selectedDocuments =
      files.length +
        referenceChecks.length +
        letters.length +
        statements.length +
        checklists.length +
        background.length +
        pdfFiles.length +
        type ===
      0;

    return (
      <span>
        <button type="button" className="btn btn-success" onClick={handleShow}>
          Download Selected
        </button>
        {showModal && (
          <ModalComponent
            visible={visible}
            handleClose={handleClose}
            onInputFieldChange={onInputFieldChange}
            branding={branding}
            displayColorPicker={displayColorPicker}
            selectedDocuments={selectedDocuments}
            togglePicker={togglePicker}
            onChangeComplete={onChangeComplete}
            color={color}
            fontLight={fontLight}
            onSubmit={onSubmit}
          />
        )}
      </span>
    );
  }
}

const popover = {
  position: 'absolute',
  zIndex: '2',
};

const ModalComponent = ({
  visible,
  handleClose,
  branding,
  color,
  selectedDocuments,
  onInputFieldChange,
  onSubmit,
  displayColorPicker,
  togglePicker,
  onChangeComplete,
  fontLight,
}) => (
  <Modal visible={visible} hideComponent={handleClose}>
    <Modal.Header
      title="Download Selected Documents"
      hideComponent={handleClose}
    />
    <Modal.Body>
      <form onSubmit={onSubmit}>
        <div className="mg-btm-mid">
          <div className="row">
            <div className="mg-btm-xs checklist-item">
              <div className="modal-body">
                {selectedDocuments && (
                  <div className="alert alert-danger" role="alert">
                    You haven&#8217;t selected any documents!
                  </div>
                )}

                <div className="mg-btm-xs text-one checklist-item">
                  Document Branding:
                </div>
                <div className="text-muted">
                  Do you want to brand your documents with your company name?
                </div>
                <div className="form-group">
                  <label className="radio-inline">
                    <input
                      type="radio"
                      name="branding"
                      id="inlineRadio1"
                      value
                      checked={branding}
                      onChange={onInputFieldChange}
                    />
                    Yes
                  </label>
                  <label className="radio-inline">
                    <input
                      type="radio"
                      name="branding"
                      id="inlineRadio2"
                      value={false}
                      checked={branding === false}
                      onChange={onInputFieldChange}
                    />
                    No
                  </label>
                </div>

                <div className="reference-details mg-btm-sm checklist-item">
                  Add a custom color:
                </div>
                <div
                  className="form-control colorpicker-element z4"
                  type="text"
                  id="color"
                  onClick={() => togglePicker()}
                >
                  {color}
                </div>

                {displayColorPicker && (
                  <div style={popover}>
                    <ChromePicker
                      onChangeComplete={(color) => onChangeComplete(color)}
                      disableAlpha
                      color={color}
                    />
                  </div>
                )}
                <div className="reference-details mg-btm-sm checklist-item">
                  Select a font shade:
                </div>
                <div className="form-group">
                  <label className="radio-inline">
                    <input
                      type="radio"
                      name="fontLight"
                      id="inlineRadio1"
                      value
                      checked={fontLight}
                      onChange={onInputFieldChange}
                    />
                    Light Font
                  </label>
                  <label className="radio-inline">
                    <input
                      type="radio"
                      name="fontLight"
                      id="inlineRadio2"
                      value={false}
                      checked={fontLight === false}
                      onChange={onInputFieldChange}
                    />
                    Dark Font
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 text-center">
                <div className="form-group">
                  <input
                    type="submit"
                    value="Download"
                    className="btn btn-primary"
                    disabled={selectedDocuments}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Modal.Body>
  </Modal>
);

export default withCookies(EmailSelectedDocumentsModal);
