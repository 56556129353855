import axios from 'axios';
import { API_URL } from '../constants';
import { POST_LOG_SUCCESS } from '.';

const postLogSuccess = () => ({
  type: POST_LOG_SUCCESS,
});

export default (message) => async (dispatch, getState) => {
  const state = getState();
  const { candidateUrl, userName, token, companyId } = state.globalInfo;

  try {
    const response = await axios.post(
      `${API_URL}logs/${candidateUrl}?companyId=${companyId}`,
      {
        author: userName,
        note: message,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    if (response.status === 201) {
      dispatch(postLogSuccess());
    }
  } catch (e) {
    console.error(e);
  }
};
