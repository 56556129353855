/* eslint-disable max-len */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import Updates from '../../components/Candidate/Updates';
import {
  fetchUpdatesForCandidate,
  fetchUpdatesAgain,
} from '../../actions/updates';
import NotFound from '../../components/NotFound/NotFound';
import Loading from '../../components/Loading';
import { getCandidateData } from '../../selectors';

class UpdatesContainer extends Component {
  componentDidMount() {
    this.props.fetchUpdatesAgain();
    if (this.props.updateAgain) {
      this.props.fetchUpdatesForCandidate();
    }
  }

  render() {
    const { updates, isFetching, name, candidateUrl, access } = this.props;

    if (isFetching) {
      return <Loading />;
    }
    if (updates.length === 0) {
      return <NotFound name={name} page="Updates" />;
    }

    return (
      <Updates
        key={candidateUrl}
        updates={updates}
        name={name}
        access={access}
      />
    );
  }
}

UpdatesContainer.defaultProps = {
  updates: [],
};

const mapStateToProps = (state) => ({
  updates: state.updates[state.globalInfo.candidateUrl],
  isFetching: state.updates.isFetching,
  updateAgain: state.updates.updateAgain,
  name: getCandidateData(state).getName(),
  candidateUrl: state.globalInfo.candidateUrl,
  access: state.globalInfo.access,
});

export default connect(mapStateToProps, {
  fetchUpdatesForCandidate,
  fetchUpdatesAgain,
})(UpdatesContainer);
