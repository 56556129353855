import React from 'react';
import Modal from '../../../Modal';
import UploadModalContent from './UploadModalContent';
import ModalProvider from './ModalProvider';
import { ACCESS_LVL } from '../../../../constants';

class DocumentUploadModal extends Modal.Manager {
  render() {
    const {
      handleClose,
      handleShow,
      state: { showModal, visible },
      props: {
        onSubmit,
        content = [],
        source,
        putDocument,
        uploadDocument,
        log,
        access,
      },
    } = this;

    return (
      <span>
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleShow}
          disabled={access === ACCESS_LVL.EXPIRED}
        >
          Upload New
        </button>
        {showModal && (
          <ModalProvider>
            <UploadModalContent
              visible={visible}
              handleClose={handleClose}
              content={content}
              uploadDocument={uploadDocument}
              putDocument={putDocument}
              onSubmit={onSubmit}
              source={source}
              log={log}
            />
          </ModalProvider>
        )}
      </span>
    );
  }
}

export default DocumentUploadModal;
