import axios from 'axios';
import _ from 'lodash';
import { API_URL } from '../constants';
import {
  SEARCH_CANDIDATES,
  SEARCH_CANDIDATES_SUCCESS,
  SEARCH_CANDIDATES_ERROR,
  SEARCH_CANDIDATES_CACHE,
} from '.';

const searchCandidates = (data) => ({
  type: SEARCH_CANDIDATES,
  data,
});

const searchCandidatesSuccess = (data) => ({
  type: SEARCH_CANDIDATES_SUCCESS,
  data,
});

const searchCandidatesError = (name) => ({
  type: SEARCH_CANDIDATES_ERROR,
  name,
});

const searchCandidatesCache = (data) => ({
  type: SEARCH_CANDIDATES_CACHE,
  data,
});

export default (params) => async (dispatch, getState) => {
  const state = getState();
  const { companyId, token } = state.globalInfo;
  const nameSearch = _.omit(params, 'name');

  if (_.isEmpty(nameSearch)) {
    const results = state.connections.search[params.name];

    if (results) {
      dispatch(
        searchCandidatesCache({
          data: results,
          name: params.name,
        }),
      );
      return;
    }
  }

  const query = params;
  Object.keys(query).forEach((key) => query[key] === '' && delete query[key]);
  const queryString = Object.keys(query)
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(query[k])}`)
    .join('&');
  const frendlyQuery = Object.keys(query)
    .map((k) => `${k} = ${query[k]}`)
    .join(' ');

  dispatch(searchCandidates());
  try {
    const response = await axios.get(
      `${API_URL}companies/${companyId}/search?${queryString.toLowerCase()}&companyId=${companyId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );

    dispatch(
      searchCandidatesSuccess({
        data: response.data.data,
        name: frendlyQuery,
      }),
    );
  } catch (e) {
    dispatch(searchCandidatesError(frendlyQuery));
  }
};
