import React from 'react';
import { Helmet } from 'react-helmet';

const NoAccess = () => (
  <div className="contact-container">
    <Helmet>
      <title>Not authorized</title>
    </Helmet>
    <div className="container profile-page">
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12">
          <p className="center-block bp-call-text text-center">
            You are not authorized to see contents of this page.
          </p>
          <p className="center-block text-center">
            Please try logging in again.
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default NoAccess;
