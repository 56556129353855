import {
  FETCH_PDF_SETTINGS,
  FETCH_PDF_SETTINGS_ERROR,
  FETCH_PDF_SETTINGS_SUCCESS,
} from '../actions';

const initialState = {
  isFetching: false,
};

const notes = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PDF_SETTINGS:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_PDF_SETTINGS_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        ...action.data,
      };
    }
    case FETCH_PDF_SETTINGS_ERROR:
      return {
        ...state,
        isFetching: false,
        ...action.data,
      };
    default:
      return state;
  }
};

export default notes;
