/* eslint-disable max-len */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert } from 'react-bootstrap';
import SearchArea from '../../components/SearchArea';
import SubNav from '../../components/SubNav';
import Contact from '../../components/Contact';
import CandidateRoutes from '../../components/Candidate/CandidateRoutes';
import { selectCandidate } from '../../actions/navigationHelpers';
import fetchCandidates from '../../actions/candidates';
import { fetchUpdatesForCandidate } from '../../actions/updates';
import { getCandidateData, getNumberOfUpdates } from '../../selectors';
import NotFound from '../../components/NotFound/NotFound';
import Loading from '../../components/Loading';
import AlertDismissable from '../AlertDismisable/AlertDismisable';
import { ACCESS_LVL } from '../../constants';
import NoAccess from '../../components/NoAccess/NoAccess';

export class CandidateContainer extends Component {
  componentDidMount() {
    this.props.selectCandidate(this.props.match.params.id);
  }

  componentWillReceiveProps() {
    this.props.fetchCandidates();
    this.props.fetchUpdatesForCandidate();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.location.pathname.includes('documents') &&
      prevProps.location.key !== this.props.location.key
    ) {
      this.props.fetchCandidates(true);
    }
  }

  render() {
    const { match, candidate, isFetching, updates, access } = this.props;

    if (isFetching) {
      return <Loading />;
    }
    if (candidate === 404 || candidate === undefined) {
      return <NotFound />;
    }
    if (candidate === 401) {
      return <NoAccess />;
    }

    return (
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12">
          <div className="panel panel-default bp-panel">
            <div className="panel-body">
              <AlertDismissable />
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12">
                  <div className="row">
                    <div className="col-xs-12 col-sm-4 col-md-3">
                      <SearchArea />
                    </div>
                    <div className="col-xs-12 col-sm-8 col-md-9">
                      <SubNav
                        id={match.params.id}
                        updates={updates}
                        access={access}
                      />
                      {access === ACCESS_LVL.EXPIRED && (
                        <Alert bsStyle="danger">
                          <p className="text-center">
                            Your subscription expired. Some options are
                            disabled.
                          </p>
                        </Alert>
                      )}
                      <Contact
                        id={match.params.id}
                        isDetailed
                        firstName={candidate.getFirstName()}
                        lastName={candidate.getLastName()}
                        designations={candidate.getDesignations()}
                        phone={candidate.getPhone()}
                        licenseCerts={candidate.getLicensesNames()}
                        specialties={candidate.getAllSpecialties()}
                        photo={candidate.getPhoto()}
                        legacyUrl={candidate.getLegacyUrl()}
                        access={access}
                      />
                      <CandidateRoutes url={match.url} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  candidateUrl: state.globalInfo.candidateUrl,
  candidate: getCandidateData(state),
  isFetching: state.candidates.isFetching,
  updates: getNumberOfUpdates(state),
  access: state.globalInfo.access,
});

export default connect(mapStateToProps, {
  fetchCandidates,
  selectCandidate,
  fetchUpdatesForCandidate,
})(CandidateContainer);
