import React from 'react';
import { ACCESS_LVL } from '../../constants';

const FindUpdates = ({ showOnlyUpdates, toggleUpdates, access }) =>
  access === ACCESS_LVL.ACTIVE ? (
    <div className="text-center">
      <button className="btn btn-block btn-warning" onClick={toggleUpdates}>
        {showOnlyUpdates ? 'Find All' : 'Find Updates'}
      </button>
    </div>
  ) : (
    <div className="text-center alert alert-danger">
      You have Candidates with Updates available. Reactivate your subscription
      to discover them.
    </div>
  );

export default FindUpdates;
