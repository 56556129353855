/* eslint-disable consistent-return */

import axios from 'axios';
import { LICENSES_LOOKUP_URL } from '../constants';
import {
  FETCH_LIC_CERTS_OPTIONS,
  FETCH_LIC_CERTS_OPTIONS_SUCCESS,
  FETCH_LIC_CERTS_OPTIONS_ERROR,
} from '.';
import { getLicensesAndCerts } from '../selectors/index';

const fetchLicCertsOptions = () => ({
  type: FETCH_LIC_CERTS_OPTIONS,
});

const fetchLicCertsOptionsSuccess = (occupationId, data) => ({
  type: FETCH_LIC_CERTS_OPTIONS_SUCCESS,
  occupationId,
  data,
});

const fetchLicCertsOptionsError = () => ({
  type: FETCH_LIC_CERTS_OPTIONS_ERROR,
});

export default (occupationId) => async (dispatch, getState) => {
  const state = getState();

  if (
    getLicensesAndCerts(state)[1].items.length > 0 &&
    getLicensesAndCerts(state)[2].items.length > 0 &&
    getLicensesAndCerts(state)[3].items.length > 0
  ) {
    return Promise.resolve();
  }

  dispatch(fetchLicCertsOptions());
  try {
    const response = await axios.get(`${LICENSES_LOOKUP_URL}${occupationId}`, {
      withCredentials: true,
    });

    dispatch(fetchLicCertsOptionsSuccess(occupationId, response.data));
  } catch (e) {
    console.error(e);
    dispatch(fetchLicCertsOptionsError());
  }
};
