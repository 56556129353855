import React from 'react';
import moment from 'moment';
import Modal from '../../../Modal';
import CovertLetterContent from './CoverLetterContent';
import { LOG_MESSAGES, ACCESS_LVL } from '../../../../constants';

class AddCoverLetterModal extends Modal.Manager {
  render() {
    const {
      handleClose,
      handleShow,
      state: { showModal, visible, modalData },
      props: { onSubmit, documents, update, user, access },
    } = this;

    return (
      <span>
        <button
          type="button"
          className="btn btn-warning"
          disabled={access === ACCESS_LVL.EXPIRED}
          onClick={handleShow}
        >
          Add New Cover Letter
        </button>
        {showModal && (
          <ModalComponent
            visible={visible}
            handleClose={handleClose}
            onSubmit={onSubmit}
            documents={documents}
            update={update}
            user={user}
            modalData={modalData}
          />
        )}
      </span>
    );
  }
}

class ModalComponent extends CovertLetterContent {
  onSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }
    const {
      props: { handleClose, documents, update, user },
      state,
    } = this;

    const newLetter = {
      ...state,
      lastEditedBy: user,
      id: moment().valueOf(),
    };

    documents.addLetter(newLetter);
    update(LOG_MESSAGES.NEW_COVER_LETTER_ADDED);
    handleClose();
  };
}

export default AddCoverLetterModal;
