import React from 'react';

const ResultsNumber = ({ currentPage, totalResultsCount, resultsPerPage }) => (
  <div className="contact-container">
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-12">
        <span className="text-large">
          Showing:{' '}
          <span className="text-small">
            {currentPage * resultsPerPage - resultsPerPage + 1}-
            {totalResultsCount > currentPage * resultsPerPage
              ? currentPage * resultsPerPage
              : totalResultsCount}{' '}
            of {totalResultsCount}
          </span>
        </span>
      </div>
    </div>
  </div>
);

export default ResultsNumber;
