import Autosuggest from '../Autosuggest';

class AutosuggestField extends Autosuggest {
  onSuggestionSelected = (event, { suggestion, method }) => {
    const { onSelect } = this.props;

    if (method === 'enter') {
      event.preventDefault();
    }

    onSelect(suggestion);
  };
}

export default AutosuggestField;
