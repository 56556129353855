import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {
  DatePickerField,
  TextField,
  CheckboxField,
  AddOrChooseField,
} from '../../../../FormFields';
import FormWrapper from '../../../../FormWrapper/FormWrapper';
import regions from './regions';
import { LicenseCert } from '../../../../../models/Candidate';
import { MOMENTS_DATES_FORMATS } from '../../../../../constants';

const initialState = new LicenseCert();

const occupationOptions = [
  { value: 1, label: 'Nurse' },
  { value: 2, label: 'Allied' },
  { value: 3, label: 'Physician' },
];

class LicenseOrCertificationForm extends FormWrapper {
  constructor() {
    super();
    this.state = {
      ...initialState,
      showLicCertCustomForm: false,
      showLicCertBodyCustomForm: false,
      workWithMains: true,
    };
  }

  onSelect = ({ name, acronym, id }, type = '') => {
    this.setState({
      [`licenseCert${type}`]: {
        name,
        acronym,
      },
      [`licenseCert${type}Id`]: id,
    });
  };

  onShowCustomForm = (value = '', type = '') => {
    const {
      props: { edit },
    } = this;
    if (edit && typeof value === 'string') {
      this.setState({
        [`licenseCert${type}`]: {
          ...initialState.licenseCert,
          name: value,
        },
        [`showLicCert${type}CustomForm`]: true,
      });
    } else if (edit) {
      this.setState({
        [`licenseCert${type}`]: {
          ...initialState.licenseCert,
          name: value.name,
          acronym: value.acronym,
        },
        [`showLicCert${type}CustomForm`]: true,
      });
    } else {
      this.setState({
        [`licenseCert${type}`]: {
          ...initialState.licenseCert,
          name: value,
        },
        [`showLicCert${type}CustomForm`]: true,
      });
    }
  };

  onShowAutosuggest = (event, type = '') => {
    event.preventDefault();
    this.setState({
      [`licenseCert${type}`]: {
        ...this.state[`licenseCert${type}`],
        name: '',
        acronym: '',
      },
      [`showLicCert${type}CustomForm`]: false,
    });
  };

  onFieldChange = ({ target: { name, value } }, type = '') => {
    const namespace = `licenseCert${type}`;

    this.setState({
      [namespace]: {
        ...this.state[namespace],
        [name]: value,
      },
    });
  };

  onOccupationChange = ({ target: { name, value } }) => {
    this.setState({ [name]: parseInt(value, 10) });
  };

  render() {
    const {
      onShowCustomForm,
      onShowAutosuggest = () => {},
      onSelect = () => {},
      onFieldChange = () => {},
      onInputFieldChange = () => {},
      onOccupationChange = () => {},
      onDatePickerFieldChange = () => {},
      onSubmit = () => {},
      onRemove = () => {},
      props: { title, edit = false, autosuggestSource, candidateUrl },
      state: {
        licenseCert: { name: licenseCertName, acronym: licenseCertAcronym },
        licenseCertBody: {
          name: licenseCertBodyName,
          acronym: licenseCertBodyAcronym,
        },
        occupationId = 1,
        isCompact,
        number,
        expiration,
        region,
        showLicCertCustomForm,
        showLicCertBodyCustomForm,
        workWithMains = true,
      },
    } = this;

    return (
      <div className="panel panel-default">
        <div className="panel-heading">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12">
              <div className="text-large">{title}</div>
            </div>
          </div>
        </div>
        <div className="panel-body">
          <form onSubmit={onSubmit}>
            <div className="row">
              <div className="col-xs-12 col-sm-9 col-md-9">
                <div className="form-group">
                  <div>
                    <label htmlFor="inlineRadioOptions">Occupation:</label>
                  </div>
                  {occupationOptions.map(({ value, label }) => (
                    <label key={value} className="radio-inline">
                      <input
                        type="radio"
                        name="occupationId"
                        id={`occupation-${value}`}
                        value={value}
                        checked={value === occupationId}
                        onChange={onOccupationChange}
                      />
                      {label}
                    </label>
                  ))}
                </div>
              </div>
            </div>
            {occupationId > 0 && (
              <div>
                <AddOrChooseField
                  title="Additional License/Certification Information"
                  value={`${licenseCertAcronym}${
                    licenseCertAcronym ? ' - ' : ''
                  }${licenseCertName}`}
                  mainName={licenseCertName}
                  mainAcronym={licenseCertAcronym}
                  workWithMains={workWithMains}
                  editPage={edit}
                  fieldLabel="License/Certification"
                  fieldName="lic-cerf"
                  required
                  source={autosuggestSource[`${occupationId}`].items}
                  showCustomForm={showLicCertCustomForm}
                  onSelect={onSelect}
                  onShowCustomForm={onShowCustomForm}
                  onShowAutocomplete={onShowAutosuggest}
                >
                  <TextField
                    id="lic-cerf-acronym"
                    name="acronym"
                    label="Acronym"
                    value={licenseCertAcronym}
                    onChange={onFieldChange}
                  />
                  <TextField
                    id="lic-cerf-name"
                    name="name"
                    label="Name"
                    value={licenseCertName}
                    onChange={onFieldChange}
                  />
                </AddOrChooseField>
                <AddOrChooseField
                  title="Additional License/Certification Body Information:"
                  value={`${licenseCertBodyAcronym}${
                    licenseCertBodyAcronym ? ' - ' : ''
                  }${licenseCertBodyName}`}
                  fieldLabel="License/Certifying Body"
                  fieldName="lic-cerf-body"
                  mainName={licenseCertBodyName}
                  mainAcronym={licenseCertBodyAcronym}
                  workWithMains={workWithMains}
                  editPage={edit}
                  source={autosuggestSource[`${occupationId}`].bodies}
                  showCustomForm={showLicCertBodyCustomForm}
                  onSelect={(data) => onSelect(data, 'Body')}
                  onShowCustomForm={(value) => onShowCustomForm(value, 'Body')}
                  onShowAutocomplete={(e) => onShowAutosuggest(e, 'Body')}
                >
                  <TextField
                    id="lic-cerf-body-acronym"
                    name="acronym"
                    label="Acronym"
                    value={licenseCertBodyAcronym}
                    onChange={(e) => onFieldChange(e, 'Body')}
                  />
                  <TextField
                    id="lic-cerf-body-name"
                    name="name"
                    label="Name"
                    value={licenseCertBodyName}
                    onChange={(e) => onFieldChange(e, 'Body')}
                  />
                </AddOrChooseField>
              </div>
            )}
            {occupationId > 0 && (
              <div>
                <div className="row">
                  <div className="col-xs-12 col-sm-9 col-md-9">
                    <div className="form-group">
                      <label htmlFor="state">State:</label>
                      <select
                        className="form-control"
                        id="region"
                        name="region"
                        value={region}
                        onChange={onInputFieldChange}
                      >
                        {regions.map(({ value, label }) => (
                          <option key={`region-${value}`} value={value}>
                            {label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-xs-12 col-sm-9 col-md-6">
                    <label htmlFor="test" className="control-label">
                      Nurse Licensure Compact License?
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12 col-sm-9 col-md-6">
                    <label htmlFor="test" className="control-label">
                      <CheckboxField
                        label="Yes"
                        name="isCompact"
                        value={isCompact}
                        checked={isCompact}
                        onChange={onInputFieldChange}
                      />
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12 col-sm-9 col-md-9">
                    <TextField
                      label="License/Certification #"
                      name="number"
                      value={number}
                      onChange={onInputFieldChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12 col-sm-9 col-md-9">
                    <div className="form-group">
                      <label htmlFor="date">Expires:</label>
                      <DatePickerField
                        id="expiration"
                        name="expiration"
                        selected={
                          expiration
                            ? moment(expiration, MOMENTS_DATES_FORMATS)
                            : null
                        }
                        onChange={onDatePickerFieldChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="add-edit-btn">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 text-center">
                  <div className="form-group">
                    <input
                      type="submit"
                      value="Save"
                      className="btn btn-primary mt-5 mb-3"
                    />
                    <Link
                      to={`/${candidateUrl}/profile`}
                      className="btn btn-warning mt-5 mb-3"
                    >
                      Cancel
                    </Link>
                    {edit && (
                      <input
                        type="button"
                        className="btn btn-danger mt-5 mb-3"
                        onClick={onRemove}
                        value="Remove"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default LicenseOrCertificationForm;
