import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { getCandidateData, getSpecialties } from '../../../../selectors';
import fetchSpecialties from '../../../../actions/specialtiesOptions';
import { update } from '../../../../actions/candidates';
import { history } from '../../../../store';
import SpecialtiesForm from '../../../../components/Candidate/Profile/Specialties/SpecialtiesForm';
import { LOG_MESSAGES } from '../../../../constants';

const AddSpecialty = ({
  candidateUrl,
  candidate,
  specialties,
  update,
  fetchSpecialties,
}) => (
  <SpecialtiesFormComponent
    key={candidateUrl}
    title="Add Specialty"
    candidateUrl={candidateUrl}
    autosuggestSource={specialties}
    candidate={candidate}
    update={update}
    fetchSpecialties={fetchSpecialties}
  />
);

class SpecialtiesFormComponent extends SpecialtiesForm {
  onSubmit = (e) => {
    const {
      state,
      props: { candidate, candidateUrl },
    } = this;
    e.preventDefault();
    const specialty = _.omit(state, 'showCustomForm');

    candidate.addSpecialty(specialty);
    this.props.update(LOG_MESSAGES.SPECIALTY_INFORMATION_MODIFIED);
    history.push(`/${candidateUrl}/profile`);
  };

  componentDidMount() {
    this.props.fetchSpecialties(this.props.candidate.getOccupationId());
  }
}

const mapStateToProps = (state) => ({
  candidateUrl: state.globalInfo.candidateUrl,
  candidate: getCandidateData(state),
  specialties: getSpecialties(state),
});

export default connect(mapStateToProps, { update, fetchSpecialties })(
  AddSpecialty,
);
