import axios from 'axios';
import { API_URL } from '../constants';
import {
  FETCH_CANDIDATES,
  FETCH_CANDIDATES_SUCCESS,
  FETCH_CANDIDATES_ERROR,
  PUT_CANDIDATES_SUCCESS,
} from '.';
import { showAlert } from './alert';
import { getCandidateData } from '../selectors';
import log from './addLog';

const fetchCandidates = (data) => ({
  type: FETCH_CANDIDATES,
  data,
});

const fetchCandidatesSuccess = (data) => ({
  type: FETCH_CANDIDATES_SUCCESS,
  data,
});

const fetchCandidatesError = (url, statusCode) => ({
  type: FETCH_CANDIDATES_ERROR,
  url,
  statusCode,
});

const putCandidatesSuccess = (data) => ({
  type: PUT_CANDIDATES_SUCCESS,
  data,
});

export default (force = false) =>
  async (dispatch, getState) => {
    const state = getState();
    const { isFetching } = state.candidates;
    const candidate = getCandidateData(state);
    const { candidateUrl, token, companyId } = state.globalInfo;
    if (!force) {
      if (isFetching || candidate || !candidateUrl) {
        return;
      }
    }

    dispatch(fetchCandidates());
    try {
      const response = await axios.get(
        `${API_URL}candidates/${candidateUrl}?companyId=${companyId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      dispatch(fetchCandidatesSuccess(response.data.data));
    } catch (e) {
      console.error(e);
      dispatch(fetchCandidatesError(candidateUrl, e.response.status));
    }
  };

export const update = (logType) => async (dispatch, getState) => {
  const state = getState();
  const { candidateUrl, token, companyId } = state.globalInfo;
  const data = getCandidateData(state);

  try {
    const response = await axios.put(
      `${API_URL}candidates/${candidateUrl}?companyId=${companyId}`,
      {
        ...data,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    if (response.status === 200) {
      dispatch(putCandidatesSuccess(data));
      dispatch(
        showAlert({ type: 'success', message: 'Changes successfully saved.' }),
      );
      window.scrollTo(0, 0);
      if (logType) {
        dispatch(log(logType));
      }
    }
  } catch (e) {
    dispatch(
      showAlert({
        type: 'danger',
        message: 'Error occurred, changes were not saved.',
      }),
    );
    window.scrollTo(0, 0);
    console.error(e);
  }
};
