import React from 'react';

class SkillLevelCheckbox extends React.Component {
  static defaultProps = {
    onLevelChange: () => {},
  };

  onChange = () => {
    const { id, levelId } = this.props;

    this.props.onLevelChange(id, levelId);
  };

  render() {
    const {
      onChange,
      props: { id, currentLevelId, levelId },
    } = this;

    return (
      <input
        onChange={onChange}
        type="checkbox"
        name={`skill-level-checkbox-${id}[]`}
        checked={currentLevelId === levelId}
      />
    );
  }
}

export default SkillLevelCheckbox;
