import React from 'react';

const ModalHeader = ({ title = 'Title', hideComponent = () => {} }) => (
  <div className="modal-header general-modal-header">
    <button type="button" className="close" onClick={hideComponent}>
      <span aria-hidden="true">×</span>
      <span className="sr-only">Close</span>
    </button>
    <div className="text-large">{title}</div>
  </div>
);

export default ModalHeader;
