/* eslint-disable consistent-return */

import axios from 'axios';
import { history } from '../store';
import { API_URL } from '../constants';
import {
  FETCH_CONNECTIONS,
  FETCH_CONNECTIONS_SUCCESS,
  FETCH_CONNECTIONS_ERROR,
  HOME_FETCH_AGAIN,
} from '.';
import { getConnections, getPage } from '../selectors';

const fetchConnections = (data) => ({
  type: FETCH_CONNECTIONS,
  data,
});

const fetchConnectionsSuccess = (data) => ({
  type: FETCH_CONNECTIONS_SUCCESS,
  data,
});

const fetchConnectionsError = (data) => ({
  type: FETCH_CONNECTIONS_ERROR,
  data,
});

export const homeFetchAgain = () => ({
  type: HOME_FETCH_AGAIN,
});

export const startFetchConnections = () => async (dispatch, getState) => {
  const state = getState();
  const connections = getConnections(state);
  const page = getPage(state);
  const { companyId, query, token } = state.globalInfo;

  if (connections || !companyId || query || state.connections.isFetching) {
    return Promise.resolve();
  }

  dispatch(fetchConnections());
  try {
    const response = await axios.get(
      `${API_URL}companies/${companyId}/candidates?companyId=${companyId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );

    if (response.data.data.length / 10 + 1 < page) {
      history.push('/');
    }

    dispatch(fetchConnectionsSuccess(response.data.data));
  } catch (e) {
    console.error(e);
    dispatch(fetchConnectionsError(e));
  }
};
