import React from 'react';
import Modal from '../../../../Modal';
import FormWrapper from '../../../../FormWrapper';
import { TextField, RichEditorField } from '../../../../FormFields';

class CoverLetterContent extends FormWrapper {
  constructor(props) {
    super(props);
    let modalHandler = {
      coverLetterData: {},
    };
    if (props) {
      modalHandler = {
        ...props.modalData.coverLetterData,
      };
    }
    this.state = {
      stateChecker: 'cover-letter',
      ...modalHandler,
    };
  }

  render() {
    const {
      onSubmit,
      onInputFieldChange,
      onRichEditorChange,
      props: { visible, handleClose },
      state: {
        title,
        clientName,
        clientAddres,
        clientCityState,
        clientZip,
        jobId,
        jobTitle,
        jobShift,
        jobUnit,
        letter,
      },
    } = this;

    return (
      <Modal
        visible={visible}
        hideComponent={() => handleClose(this.state, this.state.stateChecker)}
      >
        <Modal.Header
          title="Add/Edit Cover Letter"
          hideComponent={() => handleClose(this.state, this.state.stateChecker)}
        />
        <Modal.Body>
          <form id="coverLetter" onSubmit={onSubmit}>
            <TextField
              label="Title"
              id="title"
              name="title"
              value={title}
              required
              onChange={onInputFieldChange}
            />
            <div className="mg-top-lg mg-btm-mid text-large">
              Client Information
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-6">
                <TextField
                  label="Client Name"
                  id="clientName"
                  name="clientName"
                  value={clientName}
                  onChange={onInputFieldChange}
                />
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6">
                <TextField
                  label="Address"
                  id="clientAddres"
                  name="clientAddres"
                  value={clientAddres}
                  onChange={onInputFieldChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-6">
                <TextField
                  label="City/State"
                  id="clientCityState"
                  name="clientCityState"
                  value={clientCityState}
                  onChange={onInputFieldChange}
                />
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6">
                <TextField
                  label="Zip"
                  id="clientZip"
                  name="clientZip"
                  value={clientZip}
                  onChange={onInputFieldChange}
                />
              </div>
            </div>
            <div className="mg-top-lg mg-btm-mid text-large">Job Details</div>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-6">
                <TextField
                  label="Job ID"
                  id="jobId"
                  name="jobId"
                  value={jobId}
                  onChange={onInputFieldChange}
                />
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6">
                <TextField
                  label="Job Title"
                  id="jobTitle"
                  name="jobTitle"
                  value={jobTitle}
                  onChange={onInputFieldChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-6">
                <TextField
                  label="Shift"
                  id="jobShift"
                  name="jobShift"
                  value={jobShift}
                  onChange={onInputFieldChange}
                />
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6">
                <TextField
                  label="Unit"
                  id="jobUnit"
                  name="jobUnit"
                  value={jobUnit}
                  onChange={onInputFieldChange}
                />
              </div>
            </div>
            <div className="mg-top-lg mg-btm-mid text-large">Letter</div>
            <RichEditorField
              id="letter"
              name="letter"
              label="Content"
              content={letter}
              onChange={onRichEditorChange}
            />
          </form>
        </Modal.Body>
        <Modal.Footer>
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 text-center">
              <button
                id="message-submit"
                type="submit"
                className="btn btn-primary"
                form="coverLetter"
                onClick={() => handleClose()}
              >
                Save
              </button>
              <button
                data-dismiss="modal"
                type="button"
                className="btn btn-default"
                onClick={() => handleClose(this.state, this.state.stateChecker)}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default CoverLetterContent;
