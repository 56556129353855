import React from 'react';

const Table = ({ columns, children }) => (
  <div className="table-responsive">
    <table className="table table-hover subTable">
      <thead>
        <tr>
          <th>
            <input type="checkbox" name="selectAll" value="1" />
          </th>
          {columns && columns.map((name) => <th key={name}>{name}</th>)}
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </table>
  </div>
);

export default Table;
