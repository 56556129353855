import moment from 'moment';
import _ from 'lodash';
import Documents from './Documents';
import { DOMAIN, MOMENTS_DATES_FORMATS } from '../constants';

const capitalize = (string) =>
  !string ? '' : string.replace(/(^|\s)\S/g, (l) => l.toUpperCase());

const generateId = () =>
  parseInt(`${moment().valueOf()}${Math.floor(Math.random() * 100000)}`, 10);

const decodeHtml = (string) => (!string ? '' : string.replace(/&amp;/g, '&'));
const match = /(<([^>]+)>)/gi;

const removeHtml = (string = '') => string.replace(match, '');

const createDate = (year, month, day) =>
  year && month && day ? `${month}-${day}-${year}` : '';

export class Address {
  constructor(address = {}) {
    this.phone = address.phone || '';
    this.phone2 = address.phone2 || '';
    this.fax = address.fax || '';
    this.addressLine1 = address.addressLine1 || '';
    this.addressLine2 = address.addressLine2 || '';
    this.city = address.city || '';
    this.region = address.region || '';
    this.country = address.country || '';
    this.postalCode = address.postalCode || '';
  }

  setProperty(p, v) {
    if (p === 'city') {
      const props = v.split(',');

      this.city = props[0] || '';
      this.region = props[1] || '';

      return this;
    }
    this[p] = v;
    return this;
  }

  getAddressLine1() {
    return this.addressLine1;
  }

  getAddressLine2() {
    return this.addressLine2;
  }

  getCityState() {
    return this.city && this.region
      ? `${this.city}, ${this.region}`
      : this.city;
  }

  getCode() {
    return this.postalCode;
  }

  getPhone2() {
    return this.phone2;
  }

  getFax() {
    return this.fax;
  }
}

class LicenseCertContent {
  constructor(licenseCert = {}) {
    this.name = licenseCert.name || '';
    this.acronym = licenseCert.acronym || '';
  }
}

export class LicenseCert {
  constructor(license = {}) {
    this.isCompact = license.isCompact || false;
    this.occupationId = license.occupationId || 1;
    this.expiration = license.expiration;
    this.number = license.number || '';
    this.region = license.region || '';
    this.licenseCertId = license.licenseCertId || generateId();
    this.licenseCert = new LicenseCertContent(license.licenseCert);
    this.licenseCertBodyId = parseInt(license.licenseCertBodyId, 10) || 0;
    this.licenseCertBody = new LicenseCertContent(license.licenseCertBody);
  }

  getId() {
    return this.licenseCertId;
  }

  getName() {
    return this.licenseCert.name;
  }

  getBodyShortName() {
    return this.licenseCertBody.name;
  }

  getExpiration() {
    return this.expiration
      ? moment(this.expiration, MOMENTS_DATES_FORMATS).format('MMM, YYYY')
      : '';
  }

  getRegion() {
    return this.region;
  }
}

export class Specialty {
  constructor(specialty = {}) {
    this.specialtyId = specialty.specialtyId || generateId();
    this.acronym = specialty.acronym || '';
    this.name = specialty.name || '';
    this.yearsExperience = _.get(specialty, 'yearsExperience', -1);
  }

  getAcronym() {
    return this.acronym;
  }

  getName() {
    return this.name;
  }

  getId() {
    return this.specialtyId;
  }
}

class EmergencyContact {
  constructor(contact = {}) {
    this.firstName = contact.firstName;
    this.lastName = contact.lastName;
    this.relationship = contact.relationship || '';
    this.phone = contact.phone || '';
  }

  setProperty(p, v) {
    this[p] = v;
    return this;
  }

  getFirstName() {
    return capitalize(this.firstName);
  }

  getLastName() {
    return capitalize(this.lastName);
  }

  getRelationship() {
    return this.relationship;
  }

  getPhone() {
    return this.phone;
  }
}

class Availability {
  constructor(availability = {}) {
    this.availabilityDate = availability.availabilityDate || '';
    this.specialties = availability.specialties || [];
    this.available = availability.available || '';
    this.locations = availability.locations || [];
    this.jobSearchStatus = availability.jobSearchStatus || '';
    this.employmentTypes = availability.employmentTypes || [];
    this.shifts = availability.shifts || [];
  }

  setProperty(p, v) {
    this[p] = v;
    return this;
  }

  getDate() {
    return this.availabilityDate
      ? moment(this.availabilityDate, MOMENTS_DATES_FORMATS)
      : '';
  }

  getJobSearchStatus() {
    return this.jobSearchStatus;
  }

  getAvailable() {
    return this.available;
  }

  getLocations() {
    return this.locations;
  }

  getSpecialties() {
    return this.specialties;
  }

  getShifts() {
    return this.shifts;
  }

  getEmploymentTypes() {
    return this.employmentTypes;
  }

  addLocations(v) {
    const response = v.label.split(',');
    const region = response[1].split(' ');
    const location = {
      name: response[0],
      region: region[1],
      country: region[2],
      nameAccented: capitalize(response[0]),
    };

    const isDuplicated = this.locations.filter(
      (loc) => loc.name === location.name && loc.region === location.region,
    );
    return isDuplicated.length === 0 ? this.locations.push(location) : null;
  }

  addSpecialties(v) {
    const string = v.target.value;
    if (string.includes('-')) {
      const response = string.split('-');
      const specialty = {
        name: response[1].trim(),
        acronym: response[0].trim(),
      };

      const isDuplicated = this.specialties.filter(
        (spec) => spec.name === specialty.name,
      );

      return isDuplicated.length === 0
        ? this.specialties.push(specialty)
        : null;
    }

    const specialty = {
      name: string.trim(),
      acronym: '',
    };
    const isDuplicated = this.specialties.filter(
      (spec) => spec.name === specialty.name,
    );
    return isDuplicated.length === 0 ? this.specialties.push(specialty) : null;
  }

  removeLocations(v) {
    this.locations.splice(v, 1);
  }

  removeSpecialties(v) {
    this.specialties.splice(v, 1);
  }

  switchDesiredShifts(name, isSelected) {
    return isSelected
      ? this.shifts.push(name)
      : this.shifts.splice(this.shifts.indexOf(name), 1);
  }

  switchEmploymentTypes(name, isSelected) {
    return isSelected
      ? this.employmentTypes.push(name)
      : this.employmentTypes.splice(this.employmentTypes.indexOf(name), 1);
  }
}

export class Education {
  constructor(education = {}) {
    this.schoolId = education.schoolId || generateId();
    this.name = decodeHtml(education.name) || '';
    this.startYear = parseInt(education.startYear, 10) || null;
    this.endYear = parseInt(education.endYear, 10) || null;
    this.major = decodeHtml(education.major) || '';
    this.phone = education.phone || '';
    this.phone2 = education.phone2 || '';
    this.degree = education.degree || '';
    this.description = decodeHtml(education.description) || '';
    this.address = education.address || {};
  }
}

export class Reference {
  constructor(reference = {}) {
    this.id = reference.id || generateId();
    this.notes = removeHtml(reference.notes) || '';
    this.phone = reference.phone || '';
    this.phone2 = reference.phone2 || '';
    this.unitId = parseInt(reference.unitId, 10) || 0;
    this.title = decodeHtml(reference.title) || '';
    this.email = reference.email || '';
    this.referenceName = reference.referenceName || '';
  }
}

export class Supervisor {
  constructor(supervisor = {}) {
    this.notes = removeHtml(supervisor.name) || '';
    this.phone = supervisor.phone || '';
    this.title = decodeHtml(supervisor.title) || '';
    this.email = supervisor.email || '';
  }
}

export class Subposition {
  constructor(position = {}) {
    this.positionId = position.positionId || generateId();
    this.parentId = position.parentId || generateId();
    this.facilityNumBeds = parseInt(position.facilityNumBeds, 10) || 0;
    this.address = position.address || {};
    this.facilityUnitLevel = parseInt(position.facilityUnitLevel, 10) || 0;
    this.references = position.references
      ? position.references.map((r) => new Reference(r))
      : [];
    this.employmentType = position.employmentType || 1;
    this.endDate = position.endDate || '0';
    this.facilityType = position.facilityType || null;
    this.assignmentType = position.assignmentType || null;
    this.facilityIsTeaching = position.facilityIsTeaching || false;
    this.requireFacilityInfo = position.requireFacilityInfo || false;
    this.caseload = parseInt(position.caseload, 10) || 0;
    this.phone2 = position.phone2 || '';
    this.description = decodeHtml(position.description) || '';
    this.title = decodeHtml(position.title) || '';
    this.canContactEmployer = position.canContactEmployer || false;
    this.reasonForLeaving = position.reasonForLeaving || '';
    this.isCurrent = position.isCurrent || false;
    this.facilityTraumaLevel = parseInt(position.facilityTraumaLevel, 10) || 0;
    this.phone = position.phone || '';
    this.facilityUnitId = parseInt(position.facilityUnitId, 10) || 0;
    this.employer = decodeHtml(position.employer) || '';
    this.facilityUnitBeds = parseInt(position.facilityUnitBeds, 10) || 0;
    this.chartingSystem = position.chartingSystem || '';
    this.startDate = position.startDate || '';
    this.shiftsPerMonth = parseInt(position.shiftsPerMonth, 10) || 0;
    this.supervisor = position.supervisor || {};
  }

  getAddress() {
    return {
      addressLine1: this.address.addressLine1 || '',
      country: this.address.country || '',
      addressLine2: this.address.addressLine2 || '',
      region: this.address.region || '',
      city: this.address.city || '',
      postalCode: this.address.postalCode || '',
    };
  }
}

export class Position {
  constructor(position = {}) {
    this.positionId = position.positionId || generateId();
    this.facilityNumBeds = parseInt(position.facilityNumBeds, 10) || 0;
    this.address = position.address || {};
    this.facilityUnitLevel = parseInt(position.facilityUnitLevel, 10) || 0;
    this.references = position.references
      ? position.references.map((r) => new Reference(r))
      : [];
    this.employmentType = position.employmentType || '';
    this.endDate = position.endDate || '0';
    this.facilityType = position.facilityType || null;
    this.facilityIsTeaching = position.facilityIsTeaching || false;
    this.requireFacilityInfo = position.requireFacilityInfo || false;
    this.caseload = parseInt(position.caseload, 10) || 0;
    this.phone2 = position.phone2 || '';
    this.description = decodeHtml(position.description) || '';
    this.title = decodeHtml(position.title) || '';
    this.canContactEmployer = position.canContactEmployer || false;
    this.reasonForLeaving = position.reasonForLeaving || '';
    this.isCurrent = position.isCurrent || false;
    this.facilityTraumaLevel = parseInt(position.facilityTraumaLevel, 10) || 0;
    this.phone = position.phone || '';
    this.facilityUnitId = parseInt(position.facilityUnitId, 10) || 0;
    this.employer = decodeHtml(position.employer) || '';
    this.facilityUnitBeds = parseInt(position.facilityUnitBeds, 10) || 0;
    this.chartingSystem = position.chartingSystem || '';
    this.startDate = position.startDate || '';
    this.supervisor = position.supervisor || {};
    this.shiftsPerMonth = parseInt(position.shiftsPerMonth, 10) || 0;
    if (position.subpositions) {
      this.subpositions = position.subpositions
        .sort((a, b) => {
          if (a.isCurrent && b.isCurrent) {
            return (
              new Date(b.startDate).getTime() - new Date(a.startDate).getTime()
            );
          }
          if (a.isCurrent) {
            return -1;
          }
          if (b.isCurrent) {
            return 1;
          }
          if (a.endDate === b.endDate) {
            return (
              new Date(b.startDate).getTime() - new Date(a.startDate).getTime()
            );
          }
          return new Date(b.endDate).getTime() - new Date(a.endDate).getTime();
        })
        .map((s) => new Subposition(s));
    }
  }

  setProperty(p, v) {
    this[p] = v;
    return this;
  }

  getEndDate() {
    return this.endDate
      ? moment(this.endDate, MOMENTS_DATES_FORMATS).format('MM-DD-YYYY')
      : '';
  }

  getStartDate() {
    return this.startDate
      ? moment(this.startDate, MOMENTS_DATES_FORMATS).format('MM-DD-YYYY')
      : '';
  }
}

export class TimeOff {
  constructor(time = {}) {
    this.from = time.from || '';
    this.to = time.to || '';
  }
}

export class Checklist {
  constructor(check = {}) {
    this.data = {};
    this.userInfo = {};
    this.data.name = check.data.name;
    this.userInfo.checklistId = check.userInfo.checklistId;
    this.userInfo.signedDate = check.userInfo.signedDate;
  }

  getDate() {
    return moment(this.userInfo.signedDate, MOMENTS_DATES_FORMATS).format(
      'MM/DD/YYYY',
    );
  }
}

export default class Candidate {
  constructor(candidate = {}) {
    this.url = candidate.url;
    this.legacyUrl = candidate.legacyUrl;
    this.name = candidate.name;
    this.firstName = candidate.firstName;
    this.lastName = capitalize(candidate.lastName);
    this.mainPhone = candidate.mainPhone || '';
    this.altPhone = candidate.altPhone || '';
    this.fax = candidate.fax || '';
    this.designations = candidate.designations || '';
    this.photo = candidate.photo;
    this.ssn = candidate.ssn || '';
    this.dob = candidate.dob || '';
    this.postalCode = candidate.postalCode || '';
    this.connected = candidate.connected;
    this.addresses = candidate.addresses
      ? {
          primary: new Address(candidate.addresses.primary),
          temporary: new Address(candidate.addresses.temporary),
        }
      : {
          primary: new Address(),
          temporary: new Address(),
        };
    this.city = capitalize(candidate.city);
    this.region = candidate.region || '';
    this.country = candidate.country || '';
    this.licenseCerts = candidate.licenseCerts
      ? candidate.licenseCerts
          .sort(
            (a, b) =>
              new Date(b.expiration).getTime() -
              new Date(a.expiration).getTime(),
          )
          .map((l) => new LicenseCert(l))
      : [{}].map(() => new LicenseCert());
    this.specialties = candidate.specialties
      ? candidate.specialties
          .sort((a, b) => b.yearsExperience - a.yearsExperience)
          .map((s) => new Specialty(s))
      : [{}].map(() => new Specialty());
    this.emergencyContact = candidate.emergencyContact
      ? new EmergencyContact(candidate.emergencyContact)
      : new EmergencyContact();
    this.availability = candidate.availability
      ? new Availability(candidate.availability)
      : new Availability();
    this.positions = candidate.positions
      ? candidate.positions
          .sort((a, b) => {
            if (a.isCurrent && b.isCurrent) {
              return (
                new Date(b.startDate).getTime() -
                new Date(a.startDate).getTime()
              );
            }
            if (a.isCurrent) {
              return -1;
            }
            if (b.isCurrent) {
              return 1;
            }
            if (a.endDate === b.endDate) {
              return (
                new Date(b.startDate).getTime() -
                new Date(a.startDate).getTime()
              );
            }
            return (
              new Date(b.endDate).getTime() - new Date(a.endDate).getTime()
            );
          })
          .map((p) => new Position(p))
      : [{}].map(() => new Position());
    this.educations = candidate.educations
      ? candidate.educations
          .sort((a, b) => {
            if (a.endYear === b.endYear) {
              return parseInt(b.startYear, 10) - parseInt(a.startYear, 10);
            }
            return b.endYear - a.endYear;
          })
          .map((e) => new Education(e))
      : [{}].map(() => new Education());
    this.computerSkills = candidate.computerSkills
      ? candidate.computerSkills.sort((a, b) => b.experience - a.experience)
      : [];
    this.summary = candidate.summary || '';
    this.primaryEmail = candidate.primaryEmail || '';
    this.secondaryEmail = candidate.secondaryEmail || '';
    this.id = candidate.id;
    this.company_id = candidate.company_id;
    this.addedDate = candidate.addedDate;
    this.occupationId = candidate.ocupationId;
    this.timeOff = candidate.timeOff
      ? candidate.timeOff.map((t) => new TimeOff(t))
      : [{}].map(() => new TimeOff());
    this.documents = candidate.documents
      ? new Documents(candidate.documents)
      : {};
    this.checklists = candidate.checklists
      ? candidate.checklists.map((c) => new Checklist(c))
      : [];
    this.deleteChecklist = this.deleteChecklist.bind(this);
    this.updateData = candidate.updateData || {};
  }

  addTimeOff(from, to) {
    const time = {
      from: from.format('MM-DD-YYYY'),
      to: to.format('MM-DD-YYYY'),
    };

    this.timeOff.push(new TimeOff(time));
    return this;
  }

  getTimeOff() {
    return this.timeOff.filter((time) => !_.isEmpty(time.from));
  }

  deleteTimeOff(time, update) {
    this.timeOff = this.timeOff.filter((t) => t.from !== time.from);
    update(this.getTimeOff());
  }

  setProperty(p, v) {
    this[p] = v;
    return this;
  }

  setPrimaryAddress(p, v) {
    this.addresses.primary.setProperty(p, v);
    return this;
  }

  setTemporaryAddress(p, v) {
    this.addresses.temporary.setProperty(p, v);
    return this;
  }

  setFirstName(firstName) {
    this.firstName = firstName;
    this.name = `${firstName} ${this.getLastName()}`;
    return this;
  }

  setLastName(lastName) {
    this.lastName = lastName;
    this.name = `${this.getFirstName()} ${lastName}`;
    return this;
  }

  addPosition(position) {
    const newPosition = position;
    const { yearTo, monthTo, dayTo, yearFrom, monthFrom, dayFrom } = position;

    newPosition.endDate = createDate(yearTo, monthTo, dayTo);
    newPosition.startDate = createDate(yearFrom, monthFrom, dayFrom);

    const generatedNew = new Position(newPosition);

    this.positions.push(generatedNew);
    return generatedNew.positionId;
  }

  addSubposition(position, positionId) {
    const newSubPosition = position;
    const { yearTo, monthTo, dayTo, yearFrom, monthFrom, dayFrom } = position;

    newSubPosition.endDate = createDate(yearTo, monthTo, dayTo);
    newSubPosition.startDate = createDate(yearFrom, monthFrom, dayFrom);

    const sub = new Subposition(newSubPosition);

    this.positions = this.positions.map((pos) => {
      if (pos.positionId === parseInt(positionId, 10)) {
        const allSubpositions = pos.subpositions ? pos.subpositions : [];
        allSubpositions.push(sub);
        return pos.setProperty('subpositions', allSubpositions);
      }
      return pos;
    });

    return sub.positionId;
  }

  editSubposition(position, { positionId, assignmentId }) {
    const newSubPosition = position;
    const { yearTo, monthTo, dayTo, yearFrom, monthFrom, dayFrom } = position;

    newSubPosition.endDate = createDate(yearTo, monthTo, dayTo);
    newSubPosition.startDate = createDate(yearFrom, monthFrom, dayFrom);

    const { subpositions } = this.positions.find(
      (pos) => pos.positionId === parseInt(positionId, 10),
    );

    const a = [];

    subpositions.map((sub) => {
      if (sub.positionId === parseInt(assignmentId, 10)) {
        return a.push(new Subposition(newSubPosition));
      }
      return a.push(sub);
    });

    this.positions.map((pos) =>
      pos.positionId === parseInt(positionId, 10)
        ? pos.setProperty('subpositions', a)
        : pos,
    );

    return this;
  }

  setPosition(position) {
    const newPosition = position;
    const { yearTo, monthTo, dayTo, yearFrom, monthFrom, dayFrom } = position;

    newPosition.endDate = createDate(yearTo, monthTo, dayTo);
    newPosition.startDate = createDate(yearFrom, monthFrom, dayFrom);

    this.positions = this.positions.map((pos) =>
      pos.positionId === position.positionId ? new Position(newPosition) : pos,
    );
    return this;
  }

  removePosition(position) {
    this.positions = this.positions.filter(
      (item) => item.positionId !== position.positionId,
    );
    return this;
  }

  removeSubPosition({ positionId, assignmentId }) {
    const { subpositions } = this.positions.find(
      (pos) => pos.positionId === parseInt(positionId, 10),
    );

    let a = [];

    a = subpositions.filter(
      (sub) => sub.positionId !== parseInt(assignmentId, 10),
    );
    this.positions.map((pos) =>
      pos.positionId === parseInt(positionId, 10)
        ? pos.setProperty('subpositions', a)
        : pos,
    );
    return this;
  }

  setEducation(education) {
    const newEdu = education;

    this.educations = this.educations.map((edu) =>
      edu.schoolId === education.schoolId ? newEdu : edu,
    );
    return this;
  }

  addEducation(education) {
    const newEdu = {
      ...education,
      schoolId: generateId(),
    };

    this.educations.push(newEdu);
    return this;
  }

  removeEducation(e) {
    this.educations = this.educations.filter(
      (item) => item.schoolId !== e.schoolId,
    );
    return this;
  }

  editComputerSkill(editedSkill) {
    this.computerSkills = this.computerSkills.map((skill) =>
      skill.computerSkillId === editedSkill.computerSkillId
        ? editedSkill
        : skill,
    );

    return this;
  }

  addComputerSkill(skill) {
    const newSkill = {
      ...skill,
      computerSkillId: generateId(),
    };

    this.computerSkills.push(newSkill);
  }

  removeComputerSkill(skill) {
    this.computerSkills = this.computerSkills.filter((item) => item !== skill);
    return this;
  }

  getLegacyUrl() {
    return this.legacyUrl ? `profile/u/${this.legacyUrl}` : '';
  }

  getId() {
    return this.url;
  }

  getName() {
    return capitalize(this.name);
  }

  getFirstName() {
    return capitalize(this.firstName);
  }

  getLastName() {
    return this.lastName;
  }

  getPhone() {
    return this.mainPhone;
  }

  getUpdates() {
    return this.updateData;
  }

  getFax() {
    return this.fax;
  }

  getPhoto() {
    return this.photo || `${DOMAIN}images/bp-profile-placeholder.png`;
  }

  getSSN() {
    return this.ssn;
  }

  getDOB() {
    return this.dob ? moment(this.dob, MOMENTS_DATES_FORMATS) : '';
  }

  getPrimaryAddress() {
    return this.addresses.primary;
  }

  getTemporaryAddress() {
    return this.addresses.temporary;
  }

  getDesignations() {
    return this.designations;
  }

  getEmergencyContact() {
    return this.emergencyContact;
  }

  getAvailability() {
    return this.availability;
  }

  getLicenseCerts() {
    return this.licenseCerts;
  }

  editLicenseCerts(license, id) {
    this.licenseCerts = this.licenseCerts.map((lic) =>
      lic.licenseCertId === id ? new LicenseCert(license) : lic,
    );
  }

  addLicenseCerts(lic) {
    const newLicenseCert = {
      ...lic,
      licenseCertId: generateId(),
    };

    this.licenseCerts.push(new LicenseCert(newLicenseCert));
  }

  removeLicenseCerts(lic) {
    this.licenseCerts = this.licenseCerts.filter(
      (item) => item.licenseCertId !== lic.licenseCertId,
    );
    return this;
  }

  getLicensesNames() {
    return this.licenseCerts
      .map((c) => c.getName())
      .filter((c) => c.length)
      .join(', ');
  }

  getAllSpecialties() {
    return this.specialties
      .map((s) =>
        [s.getAcronym(), s.getName()].filter((c) => c.length).join(' - '),
      )
      .join(', ');
  }

  editSpecialty(specialty) {
    this.specialties = this.specialties.map((spec) =>
      spec.specialtyId === specialty.specialtyId
        ? new Specialty(specialty)
        : spec,
    );
  }

  addSpecialty(specialty) {
    const newSpecialty = {
      ...specialty,
      specialtyId: generateId(),
    };

    this.specialties.push(new Specialty(newSpecialty));
  }

  removeSpecialty(specialty) {
    this.specialties = this.specialties.filter(
      (item) => item.specialtyId !== specialty.specialtyId,
    );
    return this;
  }

  getPositions() {
    return this.positions;
  }

  getEducations() {
    return this.educations;
  }

  getComputerSkills() {
    return this.computerSkills;
  }

  getSummary() {
    return this.summary;
  }

  getPrimaryEmail() {
    return this.primaryEmail;
  }

  getSecondaryEmail() {
    return this.secondaryEmail;
  }

  getOccupationId() {
    return this.occupationId || 1;
  }

  getSubtitleForAgencyAssignment(posId) {
    const pos = this.positions.filter(
      (pos) => pos.positionId === parseInt(posId, 10),
    );

    const motherPosition = pos[0];

    if (motherPosition) {
      const name = motherPosition.employer;
      const start = motherPosition.startDate ? motherPosition.startDate : '';
      const end = motherPosition.endDate ? motherPosition.endDate : 'Present';

      return name ? `${name} : ${start} - ${end}` : '';
    }
    return '';
  }

  deleteChecklist(checklistId) {
    this.checklists = this.checklists.filter(
      (t) => t.userInfo.checklistId !== checklistId,
    );
  }
}
